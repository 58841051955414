// @flow

import React, { PureComponent, type Node } from "react";
import type { NullaryFn } from "@tvg/types/Functional";
import { noop } from "lodash";
import buildColor from "../../_static/ColorPalette";
import { TabAtom, PillAtom, Text } from "./styled-components";

export type TabTypeEnum = "TAB" | "PILL";

export type TabProps = {
  /**
   * The tab title / text
   */
  title: string,
  /**
   * An optional tab sub title
   */
  subTitle?: Node,
  /**
   * If the tab is active
   */
  isActive: boolean,
  /**
   * A counter to show above the text on the tab (notification style)
   */
  isDisabled: boolean,
  /**
   * If the tab is disabled
   */
  counter: number,
  /**
   * If the tab is disabled
   */
  displayZero: boolean,
  /**
   * Callback to be executed when there's a tab click
   */
  onClick: NullaryFn<mixed>,
  /**
   * If the tab title / text is uppercase
   */
  isTitleUppercase?: boolean,
  /**
   * The tab type
   */
  type?: TabTypeEnum,
  /**
   * className to style the component
   */
  className: string,
  /**
   * Element's QA label
   */
  qaLabel: string
};

export default class Tab extends PureComponent<TabProps> {
  static defaultProps = {
    title: "",
    subTitle: "",
    isSubtitleHighlighted: false,
    counter: 0,
    displayZero: false,
    isActive: false,
    isDisabled: false,
    onClick: noop,
    isTitleUppercase: true,
    type: "TAB",
    className: "",
    qaLabel: "tab"
  };

  render() {
    let textColor;

    if (this.props.isDisabled && this.props.type === "TAB") {
      textColor = buildColor("grey", "500");
    } else {
      textColor = this.props.isActive
        ? buildColor("blue_accent", "500")
        : buildColor("grey", "900");
    }

    const Element = this.props.type === "TAB" ? TabAtom : PillAtom;

    return (
      <Element
        className={
          this.props.isActive
            ? `${this.props.className} active`.trim()
            : this.props.className
        }
        counter={this.props.counter}
        isActive={this.props.isActive}
        displayZero={this.props.displayZero}
        onClick={this.props.isDisabled ? noop : this.props.onClick}
        qaLabel={this.props.qaLabel}
        data-testid="tabItem"
      >
        <Text color={textColor} isTitleUppercase={this.props.isTitleUppercase}>
          {this.props.title}
        </Text>
        {this.props.subTitle ? this.props.subTitle : undefined}
      </Element>
    );
  }
}
