// @flow
import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const InfoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${buildColor("white", "100")};
  padding: 0 12px 12px 12px;

  .infoStepsTitle {
    margin: 16px 0;
  }

  @media only screen and (min-width: 1024px) {
    padding: 0 20px 20px 20px;
  }

  @media only screen and (max-width: 1023px) and (min-width: 768px) {
    padding: 0 24px 24px 24px;
  }

  ${({ isModule }) =>
    isModule &&
    css`
      border-radius: 4px;
      width: 325px;
    `}
`;

const modulizedStyling = css`
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 16px !important;
  }
`;

const nonModulizedStyling = css`
  @media only screen and (min-width: 1024px) {
    > div:not(:last-child) {
      padding-right: 20px;
    }

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 16px;
  }

  @media only screen and (min-width: 800px) {
    flex-direction: row;
    margin: 8px 0 24px;

    > div:not(:last-child) {
      border-right: ${buildColor("blue", "000")} solid 2px;
    }
  }
`;

export const StepsContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 800px) {
    ${modulizedStyling}
  }

  ${({ isModule }) => (isModule ? modulizedStyling : nonModulizedStyling)}
`;
