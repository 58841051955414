// @flow
import React from "react";
import { type RouterHistory } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import { get } from "lodash";

import mediator from "@tvg/mediator";
import ButtonDefault from "@tvg/atomic-ui/_atom/Buttons/default";

import Icon from "../../_static/Icons";
import { arrowBack } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";

import { BackButtonContainer, BackButtonText } from "./styled-compnents";

type Props = {
  history: RouterHistory,
  qaLabel: string
};

export const BackButtonComponent = ({ history, qaLabel }: Props) => (
  <BackButtonContainer data-qa-label={qaLabel}>
    <ButtonDefault
      onClick={() => {
        let slug = get(history, "location.state.pageOrigin", "promos");
        const isRnGoBack = get(history, "location.search", "").includes(
          "showBackBtn"
        );

        if (slug === "promos/") {
          slug = "promos";
        }

        if (isRnGoBack) {
          history.push("/promos/");
        } else {
          history.goBack();
        }

        mediator.base.dispatch({
          type: "UPDATE_PROMOS_CONTENT",
          payload: { slug }
        });
      }}
      qaLabel={`${qaLabel}Action`}
    >
      <Icon
        icon={arrowBack}
        size={16}
        color={buildColor("grey", "900")}
        qaLabel={`${qaLabel}Icon`}
      />
      <BackButtonText data-qa-label={`${qaLabel}Text`}>Back</BackButtonText>
    </ButtonDefault>
  </BackButtonContainer>
);

BackButtonComponent.defaultProps = {
  qaLabel: "backButton"
};

export default withRouter(BackButtonComponent);
