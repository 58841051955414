// @flow
import type { OperationComponent } from "@apollo/client";
import tvgConf from "@tvg/conf";

import { get, map } from "lodash";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import type { WagerProfile } from "@tvg/types/User";
import type { Product, Device, Brand } from "@tvg/conf/src/types";

type ApolloProps = {
  wagerProfile: WagerProfile,
  product: Product,
  device: Device,
  brand: Brand,
  slug: string,
  graphClient: mixed
};

const conf = tvgConf();

const POLL_INTERVAL = 60000;

export default {
  options: (props: ApolloProps) => {
    const historySearch = get(props, "history.location.search", "");
    const historyPathname = get(props, "history.location.pathname", "");

    const currentSlug = historySearch
      ? historySearch.replace("?promo=", "")
      : historyPathname.substring(1);

    const variables = {
      wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
      promoId: get(
        props,
        `promos[${currentSlug}].content.promo_type.promo_id`,
        0
      ),
      ...conf.graphContext()
    };

    return {
      client: get(props, "graphClient", null),
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result: OperationComponent<Response>) => ({
    comingUpRaces: map(get(result, "data.tracks", []), "races"),
    comingUpRacesLoading: result.data.loading,
    refetch: (variables: mixed) => result.data.refetch(variables)
  })
};
