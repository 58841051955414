// @flow

import { get, set } from "lodash/fp";
import BaseEventEmitter from "./Base";
import type { FSA, EventName, EventListener } from "../Types.js.flow";

type Options = {
  element?: HTMLElement
};

type WrapperSubscriber = (Event) => mixed & {
  originalHandler: (FSA) => mixed
};

export default class DOMEventEmitter extends BaseEventEmitter {
  element: HTMLElement;

  subscribers: WrapperSubscriber[];

  constructor(options: Options = {}) {
    super();

    const element = options.element || document.body;

    if (!element) {
      throw new Error("DOMEventEmitter requires document.body to exist");
    }

    this.element = element;
    this.subscribers = [];
  }

  dispatch(payload: FSA) {
    const event = get(payload.type, this.events);

    if (event) {
      const customEvent = new CustomEvent(payload.type, {
        detail: payload,
        bubbles: false
      });

      this.element.dispatchEvent(customEvent);

      event.payload = payload;
      return this;
    }

    this.events = set(
      payload.type,
      {
        payload,
        subscribers: []
      },
      this.events
    );

    return this;
  }

  subscribe(name: string, subscriber: EventListener) {
    // $FlowFixMe https://github.com/facebook/flow/issues/3472
    const handler: WrapperSubscriber = (evt) => subscriber(evt.detail);

    handler.originalHandler = subscriber;
    this.element.addEventListener(name, handler);

    this.subscribers = this.subscribers.concat(handler);

    return super.subscribe(name, subscriber);
  }

  unsubscribe(name: EventName, subscriber: EventListener) {
    const event = get(name, this.events);

    if (event) {
      const handler = this.subscribers.filter(
        (fn) => fn.originalHandler === subscriber
      )[0];
      event.subscribers = event.subscribers.filter((fn) => fn !== handler);

      // $FlowFixMe https://github.com/facebook/flow/issues/3472
      this.element.removeEventListener(name, handler);

      return super.unsubscribe(name, handler.originalHandler);
    }

    return this;
  }
}
