// @flow
import React from "react";

export type Promo = {
  promoType: "acq" | "ret" | "all"
};

const DEFAULT_PROMO: Promo = {
  promoType: "all"
};

const promoContext = React.createContext(DEFAULT_PROMO);

export default promoContext;
