// @flow
import React from "react";
import { type OptinTypeContext } from "@tvg-mar/promos-types/Context";

const DEFAULT_OPTIN: OptinTypeContext = {
  isOpted: false,
  optedIn: false,
  error: "",
  handleOptinClick: () => {},
  sentOptinEvents: false
};

const optinContext = React.createContext(DEFAULT_OPTIN);

export default optinContext;
