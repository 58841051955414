// @flow
import * as React from "react";
import { Link } from "./styled-components";

type Props = {
  id: string,
  anchorLink: string,
  target: string,
  children: React.Node,
  onClick: () => mixed,
  qaLabel: string
};

export default class SocialLink extends React.PureComponent<Props> {
  static defaultProps = {
    qaLabel: "socialLink"
  };

  render() {
    return (
      <React.Fragment>
        <Link
          id={`refer-${this.props.id}`}
          href={this.props.anchorLink}
          target={this.props.target}
          onClick={this.props.onClick}
          data-qa-label={this.props.qaLabel}
        >
          {this.props.children}
        </Link>
      </React.Fragment>
    );
  }
}
