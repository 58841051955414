// @flow
import * as React from "react";
import { Button } from "./styled-components";

type Props = {
  id: string,
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void,
  children: React.Node,
  qaLabel: string
};

export default class SocialButton extends React.PureComponent<Props> {
  static defaultProps = {
    qaLabel: "socialButton"
  };

  render() {
    return (
      <React.Fragment>
        <Button
          qaLabel={this.props.qaLabel}
          id={`refer-${this.props.id}`}
          onClick={(event) => this.props.onClick(event)}
        >
          {this.props.children}
        </Button>
      </React.Fragment>
    );
  }
}
