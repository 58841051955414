// @flow
import OptinContext from "./state/optin.context";
import PathContext from "./state/path.context";
import PromoContext from "./state/promo.context";
import UserContext from "./state/user.context";
import DeviceContext from "./state/device.context";

export default {
  UserContext,
  PathContext,
  OptinContext,
  PromoContext,
  DeviceContext
};
