import React, { cloneElement } from "react";
import { useQaLabel } from "../../../hooks/useQaLabel";
import {
  StyledCardList,
  getListElemDividerStyles,
  borderStyles
} from "./styled-components";
import { LinkCardListProps } from "../types";

const resetBorderStyles = {
  borderWidth: 0,
  borderRadius: 0
};

export const LinkCardList = ({
  children,
  qaLabel,
  ...rest
}: LinkCardListProps) => {
  const listElements = React.Children.toArray(children);
  const listLength = listElements.length;
  return (
    <StyledCardList
      accessibilityRole="menu"
      {...borderStyles}
      {...useQaLabel(qaLabel)}
      {...rest}
    >
      {listElements.map(
        (elem, index) =>
          React.isValidElement(elem) &&
          cloneElement(elem, {
            key: `list-${index}`,
            accessibilityRole: "menuitem",
            ...resetBorderStyles,
            ...getListElemDividerStyles(index, listLength)
          })
      )}
    </StyledCardList>
  );
};
