// @flow
import React, { Component, createRef } from "react";
import { isEqual, sum } from "lodash";

import type { TabItem } from "@tvg/types/Common";
import Tab from "../../_atom/Tab";
import ScrollWrapper from "../ScrollWrapper";
import TabGroupWrapper from "./styled-components";
import type { TabTypeEnum } from "../../_atom/Tab";

type Props = {
  /**
   * An array of TabItems, representing each tab and it's text
   */
  tabs: Array<TabItem>,
  stretch: boolean,
  type?: TabTypeEnum,
  className?: string,
  borderColor?: string,
  borderColorVariant?: string
};

export default class TabGroup extends Component<Props> {
  static defaultProps = {
    tabs: [],
    stretch: false,
    type: "TAB",
    className: "",
    borderColor: "grey",
    borderColorVariant: "300"
  };

  constructor(props: Props) {
    super(props);

    this.scrollableElement = createRef();
  }

  shouldComponentUpdate(nextProps: Props) {
    return (
      this.props.tabs.length !== nextProps.tabs.length ||
      !isEqual(nextProps, this.props)
    );
  }

  sendTabToView(index: number) {
    const tabMarginRight = this.props.type === "PILL" ? 8 : 0;
    const scrollablePaddingLeft = this.props.type === "PILL" ? 12 : 0;
    const scrollablePaddingRight = this.props.type === "PILL" ? 12 : 0;
    const { clientWidth } = this.scrollableElement.current;
    const { scrollLeft } = this.scrollableElement.current;
    const tabsWidth = [...this.scrollableElement.current.children]
      .slice(0, index + 1)
      .map((tab) => tab.offsetWidth);

    const spaceBetweenTabs = tabMarginRight * (tabsWidth.length - 1);

    const selectedTabRightPosition = sum(tabsWidth);
    tabsWidth.pop();
    const selectedTabLeftPosition = sum(tabsWidth);

    if (selectedTabLeftPosition < scrollLeft) {
      const leftPosition =
        selectedTabLeftPosition + spaceBetweenTabs - scrollablePaddingLeft;
      this.scrollToLeft(leftPosition);
    } else if (selectedTabRightPosition > clientWidth + scrollLeft) {
      const leftPosition =
        selectedTabRightPosition +
        spaceBetweenTabs +
        scrollablePaddingRight -
        clientWidth;
      this.scrollToLeft(leftPosition);
    }
  }

  scrollToLeft(left: number) {
    this.scrollableElement.current.scrollTo({
      top: 0,
      left,
      behavior: "smooth"
    });
  }

  // $FlowBug
  scrollableElement: Element<*>;

  render() {
    const Tabs = this.props.tabs.map((tab, index) => (
      <li key={`tab-${index.toString()}`}>
        <Tab
          title={tab.title}
          subTitle={tab.subTitle}
          isActive={tab.isActive}
          isDisabled={tab.isDisabled}
          counter={tab.counter}
          displayZero={tab.displayZero}
          onClick={() => {
            if (this.props.stretch) {
              this.sendTabToView(index);
            }
            tab.onClick();
          }}
          isTitleUppercase={tab.isTitleUppercase}
          type={this.props.type}
          className={tab.className}
          qaLabel={tab.qaLabel}
        />
      </li>
    ));

    return (
      <ScrollWrapper stretch={this.props.stretch}>
        <TabGroupWrapper
          stretch={this.props.stretch}
          type={this.props.type}
          ref={this.scrollableElement}
          data-qa-label="tabGroup"
          className={this.props.className}
          borderColor={this.props.borderColor}
          borderColorVariant={this.props.borderColorVariant}
        >
          {Tabs}
        </TabGroupWrapper>
      </ScrollWrapper>
    );
  }
}
