// @flow

import { get } from "lodash";

export const isBrowser = () =>
  typeof window !== "undefined" &&
  Object.prototype.toString.call(window) === "[object Window]";

export const isNativeApp = () =>
  isBrowser() &&
  (get("__TVG_GLOBALS__.PRODUCT", window) === "iosnative" ||
    get("__TVG_GLOBALS__.PRODUCT", window) === "androidnative");
