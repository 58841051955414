// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";
import type { LocationShape } from "react-router-dom";
import type { ComponentType } from "react";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";
import buildColor from "../../_static/ColorPalette";

type LinkAttrs = {
  as: string | typeof Link,
  to: string | typeof undefined | LocationShape
};

export const LinkTag: ComponentType<LinkAttrs> = styled.div.attrs({})`
  text-decoration: none;
`;

export const PromosIconLink = styled.div`
  margin-right: 72px;

  &:last-of-type {
    margin-right: 0;
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (min-width: 768px) {
      flex-direction: column;
    }
  }

  p {
    font-family: ${fontNormal};
    font-weight: normal;
    text-align: center;
    font-size: 18px;
    color: ${buildColor("grey", "800")};
    margin-left: 19px;

    @media only screen and (min-width: 768px) {
      margin-left: 0;
      margin-top: 12px;
    }
  }

  @media only screen and (max-width: 599px) {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
    margin-top: 16px;

    &:first-of-type {
      margin-top: 18px;
    }
  }
`;
