// @flow
module.exports = {
  domain: {
    desktop: "www-qa.tvg.com",
    mobile: "www-qa.tvg.com",
    talentPicks: "www-qa.tvg.com/talent-picks/",
    accountWallet: "account.racing.qa.fndl.dev",
    equibase: "equibase-store-qa.tvg.com"
  }
};
