// @flow
import React from "react";
import { RouteComponentProps } from "react-router";
import PromosOnboarding from "@tvg/promos-onboarding";

interface MatchParams {
  contentId: string;
}

export interface Props extends RouteComponentProps<MatchParams> {}

export const PromosOnboardingPreview = (props: Props) => {
  const { contentId } = props.match.params;

  return (
    <PromosOnboarding
      isInternalComponent
      componentName="preview"
      previewContentId={contentId}
    />
  );
};

export default PromosOnboardingPreview;
