import React, { isValidElement } from "react";
import { Animated, StyleSheet } from "react-native";
import { easeSinInOut } from "d3-ease";

import { getAccessibilityRole } from "../../utils/accessibility";
import { useQaLabel } from "../../hooks/useQaLabel";
import { useTheme } from "../../theming";
import { SCPillProps } from "./types";
import { SegmentTabContent, SegmentText } from "./styled-components";

const sliderMargin = 4;

export const SegmentedControlPill = ({
  segmentWidth,
  selectedIndex,
  children,
  end,
  qaLabel = "segmented-control-pill"
}: SCPillProps) => {
  const animation = React.useRef(
    new Animated.Value(segmentWidth * selectedIndex)
  ).current;
  const { colors } = useTheme();

  React.useEffect(() => {
    if (animation && segmentWidth) {
      Animated.timing(animation, {
        toValue: segmentWidth * selectedIndex,
        duration: 300,
        easing: easeSinInOut,
        useNativeDriver: true
      }).start();
    }
  }, [animation, segmentWidth, selectedIndex]);

  return selectedIndex != null && segmentWidth ? (
    <Animated.View
      {...useQaLabel(qaLabel)}
      style={[
        styles.slider,
        {
          transform: [{ translateX: animation }],
          width: segmentWidth - 2 * sliderMargin,
          backgroundColor: colors.white[900],
          shadowColor: colors.blue[900]
        }
      ]}
    >
      <SegmentTabContent>
        {isValidElement(children) ? (
          children
        ) : (
          <SegmentText
            accessibilityRole={getAccessibilityRole("span")}
            isSelected
          >
            {children}
          </SegmentText>
        )}
        {end}
      </SegmentTabContent>
    </Animated.View>
  ) : null;
};

const styles = StyleSheet.create({
  slider: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 68,
    top: sliderMargin,
    bottom: sliderMargin,
    right: sliderMargin,
    left: sliderMargin,
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.12,
    shadowRadius: 3,
    elevation: 3
  }
});
