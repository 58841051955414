// @flow
import styled, { css } from "styled-components";
import { fontMedium, fontNormal } from "../../_static/Typography";

export const TableRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 702px;
  height: 48px;
  font-family: ${fontNormal};
  box-shadow: inset 0 -1px 0 0 rgb(214, 227, 240);
  ${({ isUppercase }) =>
    isUppercase &&
    css`
      text-transform: uppercase;
    `}

  @media only screen and (max-width: 499px) {
    display: flex;
    width: ${(props) =>
      props.length === 1 ? "100%" : `calc(${props.length} * 250px)`};
    flex-wrap: nowrap;
  }
`;

export const TableCell = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-overflow: ellipsis;
  font-family: ${fontNormal};
  font-size: 14px;
  ${(props) => css`
    color: ${props.color};
  `}

  ${(props) =>
    props.length === 4 &&
    css`
      width: 164px;
      margin-right: 16px;
      text-align: left;
    `}

  ${(props) =>
    props.length === 3 &&
    css`
      width: 223px;
      margin-right: 16px;
      text-align: left;
    `}

  ${(props) =>
    props.length === 2 &&
    props.index === 0 &&
    css`
      width: 50%;
      text-align: left;
    `}

  ${(props) =>
    props.length === 2 &&
    props.index === 1 &&
    css`
      width: 50%;
      text-align: right;
    `}

  @media only screen and (max-width: 499px) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    ${(props) =>
      props.length === 4 &&
      css`
        width: 150px;
      `}

    ${(props) =>
      props.length === 3 &&
      css`
        width: calc(100% - 8px);
      `}
    
    ${(props) =>
      props.length === 2 &&
      css`
        width: calc(50% - 8px);
      `}
  }

  ${({ isBold }) =>
    isBold &&
    css`
      font-family: ${fontMedium};
      font-weight: 700;
    `}
`;
