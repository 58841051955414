// @flow

import type { ReduxStory, Story } from "./types";

export const getSeoFromCurrentSlug = (pathname: string, stories: [Story]) => {
  let title = "";
  let description = "";

  if (stories.length > 0) {
    stories.forEach((story) => {
      const storySlug = story.full_slug.replace(/\/$/g, "");
      const currentSlug = pathname.replace(/^\/|\/?$/g, "");

      if (storySlug === currentSlug) {
        const metaData = story.content.seo_meta_data;
        title = metaData ? metaData.title : "";
        description = metaData ? metaData.description : "";
      }
    });
  } else {
    return null;
  }

  return { title, description };
};

export const parsePromosToRedux = (stories: [Story]): ReduxStory =>
  stories.reduce((accumulator, currentValue) => {
    if (currentValue.slug === "promos") {
      return {
        ...accumulator,
        [currentValue.slug]: currentValue
      };
    }

    return {
      ...accumulator,
      [currentValue.full_slug]: currentValue
    };
  }, {});

export const isPromoAlreadyLoaded = (
  slug: string,
  stories: ReduxStory
): boolean => {
  let result;
  try {
    result = Object.keys(stories[slug]).length > 0;
  } catch (e) {
    result = false;
  }
  return result;
};

export const shouldUpdatePromos = (
  stories: [Story],
  reduxStories: ReduxStory
): boolean =>
  stories.some((story) => {
    // If the slug doesnt exist lets update
    if (!reduxStories[story.full_slug] && !reduxStories[story.slug]) {
      return true;
    }

    // If slug is promos (homepage) or another check if published date is different
    if (story.slug === "promos") {
      return (
        new Date(reduxStories[story.slug].published_at).getTime() !==
        new Date(story.published_at).getTime()
      );
    }

    return (
      new Date(reduxStories[story.full_slug].published_at).getTime() !==
      new Date(story.published_at).getTime()
    );
  });
