// @flow
import React, { type Node } from "react";
import { Link, type Location } from "react-router-dom";
import { isTvg5 } from "@tvg/utils/generalUtils";
import withRouter from "@tvg/utils/withCustomRouter";
// $FlowFixMe
import type { RouterHistory } from "react-router-dom";
import { v4 as generateUUID } from "uuid";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import { setInternalPath } from "@tvg-mar/utils/pathHandlingUtils";
import { handleNavigationDataLayerUpdate } from "@tvg-mar/utils/gtmEventHandlers";

import type {
  LinkType,
  Target,
  Path,
  InternalRoute
} from "@tvg-mar/promos-types/PathHandling";
import type { Origin } from "@tvg-mar/promos-types/GTMHandlers";

type Props = {
  pathName: string,
  linkType: LinkType,
  text?: string,
  target: Target,
  location: Location,
  itemProp?: string,
  children?: Node | null,
  qaLabel: string,
  history: RouterHistory
};

const handleClick = (
  event: Event,
  props: Props,
  linkProps: InternalRoute
): void => {
  const { linkType, pathName, text = "", history } = props;
  const { pathname = "/", hash = "", search = "" } = linkProps.to;
  const pageOrigin: Origin = "promo offer page";
  const navigationPath = `${pathname}${search}${hash}`;
  const path = pathName.charAt(0) === "/" ? pathName : `/${pathName}`;

  if (isTvg5()) {
    event.preventDefault();
    event.stopPropagation();
    history.push({
      pathname: path.split("?")[0],
      search: `${path.split("?")[1]}`
    });
  }

  if (tvgConf().product === "tvg4" && !isTvg5()) {
    event.preventDefault();
    event.stopPropagation();
    mediator.base.dispatch({
      type: "TVG4_NAVIGATION",
      payload: {
        route: path
      }
    });
  }

  if (linkType === "story" && tvgConf().product !== "tvg4") {
    mediator.base.dispatch({
      type: "UPDATE_PROMOS_CONTENT",
      payload: { slug: pathName }
    });
  }

  if (linkProps.isInternalButExternal) {
    if (tvgConf().product === "iosnative") {
      window.handleNativeMessages("NAVIGATE_FROM_WEBVIEW", {
        url: pathName
      });
    } else {
      mediator.base.dispatch({
        type: "CLOSE_PROMOS_MODAL"
      });
    }
  }

  handleNavigationDataLayerUpdate(text, pageOrigin, navigationPath);
};

const InternalLink = (props: Props) => {
  const { linkType, pathName, text, location, itemProp, children, qaLabel } =
    props;
  const uniqueId = generateUUID();
  const path: Path = {
    id: uniqueId,
    url: "",
    cached_url: "",
    linktype: linkType
  };

  // RichText Editor aggregates a "/" which requires being removed to set correct path within setInternalPath method
  if (linkType === "story") {
    path.cached_url =
      pathName.charAt(0) === "/" ? pathName.replace("/", "") : pathName;
  } else {
    path.url = pathName;
  }

  const linkProps: InternalRoute = setInternalPath(path, location);

  return (
    <Link
      data-qa-label={qaLabel}
      key={uniqueId}
      to={linkProps.to}
      onClick={(event) => handleClick(event, props, linkProps)}
      itemProp={itemProp}
    >
      {text}
      {children}
    </Link>
  );
};

InternalLink.defaultProps = {
  text: "",
  itemProp: "",
  children: null,
  qaLabel: "internalLink"
};

export default withRouter(InternalLink);
