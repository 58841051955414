// @flow
import type { AppActions } from "../../actions/app";

export type State = {
  promosList: Array<{ [string]: string }>,
  isBeta: boolean,
  optinInfo: {
    promoId?: string,
    state?: string,
    error?: string
  }
};

export const initialState = {
  promosList: [],
  isBeta: false,
  optinInfo: {}
};

const appReducer = (state: State = initialState, action: AppActions) => {
  switch (action.type) {
    case "PROMOS_LIST_SET": {
      return { ...state, promosList: action.payload };
    }
    case "SET_BETA_OPTIN": {
      return { ...state, isBeta: action.payload };
    }
    case "SET_OPTIN_INFO": {
      return { ...state, optinInfo: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
