import tvgConf from "@tvg/conf";

const isFDR = () => tvgConf().brand === "fdr";

// TODO: temporary approach until we get to the point where the entire product is responsive
const hasMobileViewCookie =
  typeof document !== "undefined" &&
  document.cookie.includes("hasMobileView=true");

const breakpoints = {
  desktop: {
    min: {
      sm: "(min-width: 920px)",
      md: "(min-width: 1024px)",
      lg: "(min-width: 1152px)",
      xl: "(min-width: 1255px)",
      xxl: "(min-width: 1364px)"
    },
    max: {
      sm: "(max-width: 919.98px)",
      md: "(max-width: 1023.98px)",
      lg: "(max-width: 1151.98px)",
      xl: "(max-width: 1254.98px)",
      xxl: "(max-width: 1363.98px)"
    }
  },
  tablet: {
    min: {
      sm:
        hasMobileViewCookie || isFDR()
          ? "(min-width: 768px)"
          : "(min-width: 1px)",
      md: "(min-width: 1194px)",
      lg: "(min-width: 1366px)"
    },
    max: {
      sm:
        hasMobileViewCookie || isFDR()
          ? "(max-width: 767.98px)"
          : "(max-width: 1px)",
      md: "(max-width: 1193.98px)",
      lg: "(max-width: 1365.98px)"
    }
  }
};

export default breakpoints;
