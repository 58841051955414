// @flow
import React, { PureComponent } from "react";
import Parser from "html-react-parser";
import type { RichTextDoc } from "@tvg-mar/promos-types/RichText";

import RichTextWrapper from "./styled-components";
import { RenderRichText } from "../../_static/richTextEditorResolver";

export type Content = {
  isModule?: boolean,
  markdown: string | RichTextDoc,
  textCentered?: boolean
};

type Props = {
  content: Content,
  qaLabel: string
};

class RichText extends PureComponent<Props> {
  static defaultProps = {
    content: {
      isModule: false,
      markdown: "",
      textCentered: false
    },
    qaLabel: "richText"
  };

  render() {
    const { qaLabel, content } = this.props;
    const { isModule, markdown, textCentered } = content;

    const listOfContents = [];
    if (typeof markdown !== "string") {
      markdown.content.forEach((blok) => listOfContents.push(blok));
    } else {
      return (
        <RichTextWrapper
          isModule={isModule}
          textCentered={textCentered}
          data-qa-label={qaLabel}
        >
          {Parser(markdown)}
        </RichTextWrapper>
      );
    }

    return (
      <RichTextWrapper
        data-qa-label={qaLabel}
        isModule={isModule}
        textCentered={textCentered}
      >
        {listOfContents.map((blok) => RenderRichText(blok))}
      </RichTextWrapper>
    );
  }
}

export default RichText;
