// @flow
import styled from "styled-components";
import { fontBold } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const OfferItemsContainer = styled.div`
  background-color: ${buildColor("white", "900")};

  @media only screen and (min-width: 1024px) {
    width: 325px;
    border-radius: 4px;
  }

  @media only screen and (min-width: 651px) and (max-width: 1023px) {
    border-bottom: 1px solid ${buildColor("blue", "100")};
  }

  @media only screen and (max-width: 414px) {
    width: 100%;
  }
`;

export const OfferItemsTitle = styled.div`
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid ${buildColor("blue", "100")};
  border-radius: 4px 4px 0 0;

  > h2 {
    font-family: ${fontBold};
    line-height: 18.75px;
    width: 100%;
    text-transform: none;
  }

  @media only screen and (min-width: 678px) and (max-width: 1023px) {
    padding: 12px 12px 12px 24px;
  }
`;

export const OfferItemsWrapper = styled.div`
  div:last-child {
    border-bottom: none;
  }

  @media only screen and (min-width: 1024px) {
    border-radius: 0 0 4px 4px;

    div:last-child {
      border-radius: 0 0 4px;
    }
  }
`;
