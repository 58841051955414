import React from "react";
import styled from "styled-components";
import Svg, { Rect, Path } from "react-native-svg";
import { Icon, LoadingMaskAnimation, buildColor } from "@tvg/design-system";

const MaskContainer = styled.div<{
  isClickable?: boolean;
}>`
  display: grid;
  align-items: center;
  min-height: 55px;
  grid-template-columns: 129px auto ${({ isClickable }) =>
      isClickable ? "44px" : "12px"};
  background-color: ${buildColor("white", "900")};

  &:not(:last-of-type) {
    box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
  }

  @media only screen and (hover: hover) and (pointer: fine) and (min-width: 1024px) {
    &:last-of-type {
      box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
    }
  }
`;

const ProgressBarLoadingMaskContainer = styled.div`
  height: 4px;
`;

const InfoContainer = styled.div``;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
`;

interface Props {
  size?: number;
  isClickable?: boolean;
}

const RefereeRowMask = ({ size = 3, isClickable }: Props) => {
  const renderContent = () => {
    const rowArray = [...Array(size)].map((_value, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <MaskContainer key={`RefereeRowMask-${index}`} isClickable={isClickable}>
        <InfoContainer>
          <LoadingMaskAnimation ml="space-4">
            <Svg width="72" height="32" viewBox="0 0 72 32" fill="none">
              <Rect
                width="49"
                height="12"
                rx="2"
                fill={buildColor("blue", "000")}
              />
              <Rect
                width="72"
                height="15"
                y="16"
                rx="2"
                fill={buildColor("blue", "000")}
              />
            </Svg>
          </LoadingMaskAnimation>
        </InfoContainer>
        <ProgressBarLoadingMaskContainer>
          <LoadingMaskAnimation>
            <Svg viewBox="0 0 226 4" fill="none">
              <Path
                d="M2,0 h72 v4 h-72 a2,2 0 0 1 -2,-2 v0 a2,2 0 0 1 2,-2 z"
                fill={buildColor("blue", "000")}
              />
              <Rect
                width="74"
                height="4"
                x="76"
                fill={buildColor("blue", "000")}
              />
              <Path
                d="M152,0 h72 a2,2 0 0 1 2,2 v0 a2,2 0 0 1 -2,2 h-72 z"
                fill={buildColor("blue", "000")}
              />
            </Svg>
          </LoadingMaskAnimation>
        </ProgressBarLoadingMaskContainer>
        {isClickable && (
          <IconContainer>
            <Icon name="chevronRight" size="m" />
          </IconContainer>
        )}
      </MaskContainer>
    ));

    return rowArray;
  };

  return <>{renderContent()}</>;
};

export default RefereeRowMask;
