// @flow
// $FlowFixMe
import React from "react";
import { get } from "lodash";
import tvgConf from "@tvg/conf";

import {
  setEmailLink,
  setEmailReferralLink,
  setFaceBookLink,
  setTextLink,
  setTwitterLink,
  copyToClipboard
} from "@tvg-mar/utils/socialLinksUtils";
import type { ShareLinkType } from "@tvg-mar/promos-types/Component";

import ShareLinksContainer from "./styled-components";
import ShareLink from "../../_molecules/ShareLink";
import Icon from "../../_static/Icons";
import {
  copyLink,
  textIcon,
  faceBook,
  twitter,
  email
} from "../../_static/Icons/icons";

type Props = {
  links: Array<ShareLinkType>,
  userEmail: string,
  isLogged: boolean,
  referCode: string,
  referPath: string,
  isEligible: boolean,
  promoId: number,
  qaLabel: string
};

let SOCIAL_ICONS_DICTIONARY = Object.create(null);
let SOCIAL_LINK_SETTER = Object.create(null);
let SOCIAL_LINK_TARGET = Object.create(null);

SOCIAL_ICONS_DICTIONARY = {
  copyLink,
  text: textIcon,
  faceBook,
  twitter,
  email,
  invite: email
};

SOCIAL_LINK_SETTER = {
  text: setTextLink,
  faceBook: setFaceBookLink,
  twitter: setTwitterLink,
  email: setEmailLink,
  invite: setEmailReferralLink
};

SOCIAL_LINK_TARGET = {
  copyLink: "",
  text: "_self",
  faceBook: "_blank",
  twitter: "_blank",
  email: "_blank",
  invite: ""
};

const disableSocialLink = (component: string, userEmail: string): boolean => {
  let disable;

  switch (component) {
    case "text":
      disable =
        tvgConf().device === "computer" &&
        typeof window !== "undefined" &&
        !(navigator.userAgent.indexOf("Mac OS X") !== -1);
      break;
    case "email":
      disable =
        (tvgConf().product === "ios2" || tvgConf().product === "iosnative") &&
        userEmail === "";
      break;
    default:
      disable = false;
  }

  return disable;
};

const ShareLinks = ({
  links,
  userEmail,
  isLogged,
  referCode,
  referPath,
  isEligible,
  promoId,
  qaLabel
}: Props) => (
  <ShareLinksContainer data-qa-label={qaLabel}>
    {links
      .filter((link) => !disableSocialLink(link.config.id, userEmail))
      .map((link) => {
        let shareLink = "";
        const linkId = get(link, "config.id", "");

        if (linkId !== "copyLink") {
          const linkSetter = get(SOCIAL_LINK_SETTER, linkId, () => {});

          shareLink = linkSetter({
            ...link.config,
            email: userEmail,
            code: referCode,
            path: referPath
          });
        }

        return (
          <ShareLink
            key={link._uid}
            {...link.config}
            isLogged={isLogged}
            isEligible={isEligible}
            target={get(SOCIAL_LINK_TARGET, linkId, null)}
            shareLink={shareLink}
            copyLink={copyToClipboard}
            promoId={promoId}
            qaLabel={`${linkId}-${qaLabel}Item`}
          >
            <Icon
              icon={get(SOCIAL_ICONS_DICTIONARY, linkId, copyLink)}
              size={20}
              view="0 0 20 20"
              qaLabel={`${qaLabel}ItemIcon`}
            />
          </ShareLink>
        );
      })}
  </ShareLinksContainer>
);

ShareLinks.defaultProps = {
  qaLabel: "shareLinks"
};

export default ShareLinks;
