// @flow
import type { OptinError } from "@tvg-mar/promos-types/Promos";

const errorTypes = (error: OptinError): string => {
  let errorMsg;

  switch (error) {
    case "PromotionNotFoundException":
      errorMsg = "This offer is currently not available";
      break;
    case "NotEligibleException":
      errorMsg = "Sorry, you are not eligible for this offer";
      break;
    case "UserOnBlackListException":
      errorMsg = "Sorry, you are not eligible to participate in this promotion";
      break;
    case "NotInValidOptinRangeException":
      errorMsg = "This offer is currently not available";
      break;
    default:
      errorMsg = "Sorry, an error has occurred";
  }

  return errorMsg;
};

export default errorTypes;
