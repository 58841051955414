// @flow
import React from "react";
import type { Element } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fal } from "@fortawesome/pro-light-svg-icons";
import type { Icon } from "@tvg-mar/promos-types/Component";

import PromosIconContainer from "./styled-components";

// Font Awesome library initialization
library.add(fal);

type Props = {
  icon: Icon,
  qaLabel: string
};

const getIconName = (name: string): string => name.slice(3);

const renderIcon = (name: string): Element<typeof FontAwesomeIcon> => {
  const iconName = getIconName(name);
  return <FontAwesomeIcon icon={["fal", iconName]} size="3x" />;
};

const PromosIcon = (props: Props) => (
  <PromosIconContainer data-qa-label={props.qaLabel}>
    {renderIcon(props.icon.icon)}
  </PromosIconContainer>
);

PromosIcon.defaultProps = {
  qaLabel: "promosIcon"
};

export default PromosIcon;
