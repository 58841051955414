import styled, { css } from "styled-components/native";
import { StyledProps } from "./types";

export const BetTypeTouchable = styled.TouchableHighlight<StyledProps>`
  height: 100%;
  border-bottom-color: ${({ theme }) => theme.colors.blue["100"]};
  border-bottom-width: 1px;
  padding-left: ${({ theme }) => `${theme.space["space-4"]}`};
  padding-top: ${({ theme }) => `${theme.space["space-5"]}`};
  padding-bottom: ${({ theme }) => `${theme.space["space-5"]}`};
  ${({ isHovered, theme }) =>
    isHovered &&
    css`
      background-color: ${theme.colors.blue_accent["100"]};
    `}

  ${({ isPressed, theme }) =>
    isPressed &&
    css`
      background-color: ${theme.colors.blue_accent["200"]};
      border-bottom-color: ${theme.colors.blue_accent["500"]};
      border-bottom-width: 1px;
    `}
  
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border-bottom-color: ${theme.colors.blue_accent["500"]};
      border-bottom-width: 1px;
    `}

  ${({ isHovered, isSelected, isParentHovered, theme }) =>
    !isHovered &&
    isSelected &&
    !isParentHovered &&
    css`
      background-color: ${theme.colors.blue_accent["100"]};
    `}
`;

export const BetTypeText = styled.Text<StyledProps>`
  color: ${({ theme }) => theme.colors.grey["900"]};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${({ theme }) => theme.fontSizes.s};
  ${({ isSelected, isPressed, theme }) =>
    (isSelected || isPressed) &&
    css`
      color: ${theme.colors.blue_accent["500"]};
    `};
`;
