// @flow
import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";

import pushToDataLayer from "../gtmUtils";

type PageViewType = {
  type: "PAGE_VIEW",
  payload: {
    siteVersion: string,
    productVersion: string,
    accountId: string,
    state: string,
    logged: string,
    registrationStatus: string,
    page: string,
    sport: string,
    privateBrowser: "Yes" | "No",
    graphVersion: "v1" | "v2",
    balance: number,
    promoCode?: string,
    isReturningUser?: boolean
  }
};

type SiteClickType = {
  type: "SITE_CLICK",
  payload: {
    event: string,
    gaEventCategory: string,
    gaEventAction: string,
    gaEventLabel: string,
    tag: ?string,
    module: string,
    microApp: string
  }
};

type TrackView = {
  type: "SITE_CLICK",
  payload: {
    trackName: string,
    raceNumber: string
  }
};

type InboxOpenClose = {
  type: "OPEN_CLOSE_INBOX",
  payload: {
    open: boolean,
    unviewedContentCardsCount: number
  }
};

type IOSTrackingPermission = {
  type: "IOS_TRACKING_PERMISSION",
  payload: {
    label: string,
    device: string,
    osVersion: string,
    appVersion: string
  }
};

export default () => {
  mediatorChannels.base.subscribe("PAGE_VIEW", (data: PageViewType) => {
    const page: string = get(data, "payload.page");
    let isContentCardsInboxShown = false;
    let pageViewName;
    let pageViewSectionName;

    if (
      page &&
      (page.indexOf("/preferences") >= 0 || page.indexOf("/subscribe") >= 0)
    ) {
      pageViewName = "Preferences";
      pageViewSectionName = "Preferences";
    }

    if (page && page.indexOf("/unsubscribe") >= 0) {
      return;
    }

    if (page && page.indexOf("/wallet") >= 0) {
      pageViewName = "Wallet";
      pageViewSectionName = "PAWS";
    }

    if (page && page.indexOf("/referral#invite") >= 0) {
      pageViewName = "Email Referral";
      pageViewSectionName = "Referral";
    }

    if (page) {
      isContentCardsInboxShown =
        page.indexOf("/racetracks") !== 0 &&
        page.indexOf("/live") !== 0 &&
        !page.includes("#");
    }

    const balance = get(data, "payload.balance");
    const fixedBalance =
      typeof balance === "number" ? balance.toFixed(2) : undefined;

    pushToDataLayer({
      event: "ga_pageload",
      customerStatus: get(data, "payload.isReturningUser")
        ? "Returning"
        : "New",
      siteVersion: get(data, "payload.siteVersion"),
      productVersion: get(data, "payload.productVersion"),
      racingId: get(data, "payload.racingId")
        ? get(data, "payload.racingId").toString()
        : "",
      accountId: get(data, "payload.accountId"),
      residenceState: get(data, "payload.state"),
      loginStatus: get(data, "payload.logged"),
      registrationStatus: get(data, "payload.registrationStatus"),
      page,
      sport: get(data, "payload.sport"),
      privateBrowser: get(data, "payload.privateBrowser"),
      graphVersion: get(data, "payload.graphVersion"),
      appVersion: get(data, "payload.appVersion"),
      balance: fixedBalance,
      ...(isContentCardsInboxShown
        ? {
            promoCode: get(data, "payload.promoCode", undefined)
          }
        : null),
      ...(pageViewName
        ? { screenName: pageViewName, sectionName: pageViewSectionName }
        : null)
    });
  });

  mediatorChannels.base.subscribe("TRACK_VIEW", (data: TrackView) => {
    pushToDataLayer({
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber")
    });
  });

  mediatorChannels.base.subscribe(
    "OPEN_CLOSE_INBOX",
    (data: InboxOpenClose) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data.payload, "open", false) ? "Open" : "Close",
        gaEventLabel: "TVG Inbox Notifications",
        menu: undefined,
        module: "Header",
        microApp: "non-Microapp",
        tag: get(data.payload, "unviewedContentCardsCount", 0),
        destinationUrl: "/home"
      });
    }
  );

  mediatorChannels.base.subscribe("LOGO:HOME_NAVIGATION", () => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: "Home Page",
      menu: undefined,
      module: "Header",
      microApp: "non-Microapp",
      tag: undefined,
      destinationUrl: "/home"
    });
  });

  mediatorChannels.base.subscribe("SITE_CLICK", (data: SiteClickType) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: get(data, "payload.action"),
      gaEventLabel: get(data, "payload.label"),
      tag: undefined,
      module: get(data, "payload.module"),
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe(
    "IOS_TRACKING_PERMISSION",
    (data: IOSTrackingPermission) => {
      pushToDataLayer({
        gaEventCategory: "Permission",
        gaEventAction: "Appsflyer modal",
        gaEventLabel: get(data, "payload.label"),
        device: get(data, "payload.device"),
        osVersion: get(data, "payload.osVersion"),
        appVersion: get(data, "payload.appVersion")
      });
    }
  );
};
