// @flow
import React from "react";

import OptinButton, { type Content } from "../OptinButton";
import NextRaceButton from "../NextRaceButton";
import OptinFooterBtnWrapper from "./styled-components";

type Props = {
  content: Content,
  showNextRaceBtn?: boolean,
  isOpted?: boolean,
  raceNumber?: number,
  trackCode?: string,
  trackName?: string,
  qaLabel: string
};

const OptinFooterBtn = ({
  content,
  showNextRaceBtn,
  isOpted,
  raceNumber = 0,
  trackCode = "",
  trackName = "",
  qaLabel = "optinFooterButton"
}: Props) => {
  const { isMobilePersistent } = content;

  return (
    <OptinFooterBtnWrapper data-qa-label={qaLabel}>
      {showNextRaceBtn && isOpted ? (
        <NextRaceButton
          trackCode={trackCode}
          trackName={trackName}
          raceNumber={raceNumber}
          isMobilePersistent={isMobilePersistent}
          qaLabel={`${qaLabel}NextRace`}
        />
      ) : (
        <OptinButton
          content={{ ...content, isMobilePersistent: true }}
          qaLabel={`${qaLabel}OptinButton`}
        />
      )}
    </OptinFooterBtnWrapper>
  );
};

OptinFooterBtn.defaultProps = {
  showNextRaceBtn: false,
  isOpted: false,
  qaLabel: "optinFooterButton"
};

export default OptinFooterBtn;
