// @flow
import StoryblokClient from "storyblok-js-client";
import tvgConf from "@tvg/conf";
import type { TvgConf } from "@tvg/conf/src/types";
import { type RichTextContent } from "@tvg-mar/promos-types/RichText";

type StoryParams = {
  token?: string,
  find_by?: "uuid",
  version?: "draft" | "published",
  resolve_links?: "url" | "story" | "0" | "1",
  resolve_relations?: string,
  cv?: number,
  from_release?: string
};

export const startInput = (event: any, reactComponent: Object) => {
  // this will alter the state and replaces the current story with a current raw story object (no resolved relations or links)
  if (event.story.content._uid === reactComponent.state.content._uid) {
    reactComponent.setState({
      content: window.storyblok.addComments(event.story.content, event.story.id)
    });
  }
};

export default class StoryblokService {
  devMode: boolean;

  token: string;

  client: StoryblokClient;

  state = {};

  query = {};

  constructor() {
    this.tvgConf = tvgConf();
    this.devMode = this.tvgConf.environment !== "production";
    this.token = this.tvgConf.config("storyblokToken");
    this.client = new StoryblokClient({
      accessToken: this.token,
      cache: {
        clear: "auto",
        type: "memory"
      }
    });

    this.query = {};
  }

  tvgConf: TvgConf;

  getCacheVersion(): string {
    return this.client.cacheVersion;
  }

  get(slug: string, params?: StoryParams): Object {
    const storyParams = params || {};

    if (this.getQuery("_storyblok") || this.devMode) {
      storyParams.version = "draft";
    }

    if (
      typeof window !== "undefined" &&
      typeof window.StoryblokCacheVersion !== "undefined"
    ) {
      storyParams.cv = window.StoryblokCacheVersion;
    }

    return this.client.get(slug, storyParams);
  }

  initEditor(reactComponent: Object): void {
    if (window.storyblok) {
      window.storyblok.init({
        accessToken: this.token
      });

      window.storyblok.on(["change", "published"], () => {
        location.reload(true);
      });

      // this will alter the state and replaces the current story with a current raw story object (no resolved relations or links)
      window.storyblok.on("input", (event) => {
        startInput(event, reactComponent);
      });
    }
  }

  setQuery(query: any): void {
    this.query = query;
  }

  getQuery(param: any): string {
    return this.query[param];
  }

  bridge(): string {
    if (!this.getQuery("_storyblok") && !this.devMode) {
      return "";
    }
    return `//app.storyblok.com/f/storyblok-latest.js?t=${this.token}`;
  }

  richText(blok: RichTextContent): string {
    return this.client.richTextResolver.renderNode(blok);
  }
}
