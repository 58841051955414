// @flow
import React, { createElement } from "react";
import { get } from "lodash";

import type { Blok } from "@tvg-mar/promos-types/Template";
import type { OptinError } from "@tvg-mar/promos-types/Promos";

import ContentText from "../../_atoms/ContentText";
import ContentTitle from "../../_atoms/ContentTitle";
import ContentNote from "../../_atoms/ContentNote";
import InfoContent from "../InfoContent";
import LegalsContent from "../../_static/LegalsContent";
import OptinButton from "../../_molecules/OptinButton";
import RichText from "../../_molecules/RichText";
import StatusNotifications from "../StatusNotifications";
import ImageGrid from "../ImageGrid";
import TableComponent from "../TableComponent";
import TableContent from "../TableContent";
import TextAndQuote from "../TextAndQuote";
import BorderBottom from "../../_atoms/BorderBottom";
import EligibleRaces from "../EligibleRaces";
import { BodyContentContainer, BodyContentWrapper } from "./styled-components";

import Footer from "../../_atoms/Footer";

type Content = {
  bloks: Blok[],
  isLogged?: boolean,
  isOpted?: boolean,
  isEligible?: boolean,
  referCode?: string,
  referPath?: string,
  isOptinError?: boolean,
  optinTypeError?: OptinError,
  userEmail?: string,
  isModule?: boolean
};

type ModulizedComponent = {
  isModule: boolean
};

type Props = {
  content: Content,
  qaLabel: string
};

let COMPONENTS_DICTIONARY = Object.create(null);

COMPONENTS_DICTIONARY = {
  border_bottom: BorderBottom,
  content_title: ContentTitle,
  content_text: ContentText,
  content_note: ContentNote,
  info_section_v2_0: InfoContent,
  legal_content_v2_0: LegalsContent,
  optin_button: OptinButton,
  rich_text: RichText,
  image_grid: ImageGrid,
  table_content: TableComponent,
  table_content_v2_0: TableContent,
  text_and_quote: TextAndQuote,
  footer: Footer,
  eligible_races: EligibleRaces
};

export const renderChildrenComponents = (
  bloks: Array<Blok & ModulizedComponent>,
  content: Content
): createElement =>
  bloks.map((blok) => {
    if (typeof COMPONENTS_DICTIONARY[blok.component] !== "undefined") {
      const { isModule = false } = content;

      return React.createElement(COMPONENTS_DICTIONARY[blok.component], {
        key: blok._uid,
        content: Object.assign(blok, { isModule })
      });
    }

    return React.createElement(
      "div",
      { key: blok._uid },
      `The component ${blok.component} has not been created yet.`
    );
  });

const BodyContent = (props: Props) => {
  const { content, qaLabel } = props;
  const isOptinError = get(content, "isOptinError", false);
  const optinTypeError = get(content, "optinTypeError", "");
  const isOpted = get(content, "isOpted", false);
  const bloks = get(content, "bloks");
  const isEligible = get(content, "isEligible", true);
  const { isModule = false } = content;

  return (
    <BodyContentContainer data-qa-label={qaLabel}>
      {isOptinError || !isEligible ? (
        <StatusNotifications
          type="error"
          errorType={!isEligible ? "NotEligibleException" : optinTypeError}
          qaLabel={`${qaLabel}StatusNotificationError`}
        />
      ) : (
        <StatusNotifications
          type="success"
          status={isOpted}
          qaLabel={`${qaLabel}StatusNotificationSuccess`}
        />
      )}
      <BodyContentWrapper
        isModule={isModule}
        data-qa-label={`${qaLabel}ContentWrapper`}
      >
        {bloks && renderChildrenComponents(bloks, props.content)}
      </BodyContentWrapper>
    </BodyContentContainer>
  );
};

BodyContent.defaultProps = {
  content: {
    bloks: [],
    isLogged: false,
    isOpted: false,
    isEligible: true,
    referCode: "",
    referPath: "",
    isOptinError: false,
    optinTypeError: "",
    userEmail: "",
    isModule: false
  },
  qaLabel: "bodyContent"
};

export default BodyContent;
