import React, { useContext } from "react";
import { ThemeProvider } from "styled-components/native";

import { TvgConfContext } from "../../utils/tvgConfProvider";
import { Theme } from "../types";
import { DEFAULT_THEME_KEY, THEME_MAP } from "./constants";
import { TVGThemeProviderProps } from "./types";

export const TVGThemeProvider: React.FC<TVGThemeProviderProps> = ({
  children,
  device
}) => {
  const { device: deviceInContext } = useContext(TvgConfContext);
  const theme =
    (device && THEME_MAP[device]) ||
    (deviceInContext && THEME_MAP[deviceInContext]) ||
    THEME_MAP[DEFAULT_THEME_KEY];

  return <ThemeProvider theme={theme as Theme}>{children}</ThemeProvider>;
};

export default TVGThemeProvider;
