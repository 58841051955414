// @flow
// $FlowFixMe
import React from "react";
import { get } from "lodash";
import moment from "moment";
import errorTypes from "@tvg-mar/utils/errorMessaging";
import type { OptinError, StandingsDates } from "@tvg-mar/promos-types/Promos";

import ErrorNotification from "../../_molecules/MessageTypes/errorNotification";
import InfoNotification from "../../_molecules/MessageTypes/infoNotication";
import SuccessNotification from "../../_molecules/MessageTypes/successNotification";
import useCountDownTimer from "./useCountDownTimer";

type Props = {
  type: "success" | "error" | "info",
  isOpted: boolean,
  optinTriggered?: boolean,
  errorType?: string,
  successText?: string,
  infoText?: string,
  contestStandingsDates: StandingsDates,
  showCountdown?: boolean,
  showNextRace?: boolean,
  nextRaceTiming?: string,
  addBottomMargin?: boolean,
  borderTopRadius?: boolean,
  qaLabel: string
};

export const renderSuccessMsg = (props: Props) => {
  const {
    showCountdown = false,
    showNextRace = false,
    optinTriggered = false,
    contestStandingsDates,
    qaLabel
  } = props;

  const contestStartDate = get(contestStandingsDates, "beginDate");
  const contestEndDate = get(contestStandingsDates, "endDate");
  const nextRaceTiming = get(props, "nextRaceTiming");
  const addBottomMargin = get(props, "addBottomMargin", false);
  const borderTopRadius = get(props, "borderTopRadius", false);
  let text = get(props, "successText");

  if (
    contestStartDate &&
    moment().isBefore(contestStartDate) &&
    showCountdown
  ) {
    const { countDownText } = useCountDownTimer(contestStartDate);
    text = `Contest starts ${countDownText}`;
  }

  if (
    showNextRace &&
    nextRaceTiming &&
    (!showCountdown ||
      (showCountdown && contestStartDate && moment().isAfter(contestStartDate)))
  ) {
    text = nextRaceTiming;
  }

  if (contestEndDate && moment().isAfter(contestEndDate) && showCountdown) {
    text = "Contest Closed";

    return (
      <InfoNotification
        text={text}
        addBottomMargin={addBottomMargin}
        borderTopRadius={borderTopRadius}
        qaLabel={`${qaLabel}Info`}
      />
    );
  }

  return (
    <SuccessNotification
      optinTriggered={optinTriggered}
      text={text}
      addBottomMargin={addBottomMargin}
      borderTopRadius={borderTopRadius}
      qaLabel={`${qaLabel}Success`}
    />
  );
};

const renderErrorMsg = (
  type: OptinError,
  addBottomMargin: boolean,
  borderTopRadius: boolean,
  qaLabel: string
) => {
  const text = errorTypes(type);

  return (
    <ErrorNotification
      text={text}
      addBottomMargin={addBottomMargin}
      borderTopRadius={borderTopRadius}
      qaLabel={`${qaLabel}Error`}
    />
  );
};

const renderInfoMessage = (props: Props) => {
  const { contestStandingsDates, qaLabel } = props;

  const contestStartDate = get(contestStandingsDates, "beginDate");
  const contestEndDate = get(contestStandingsDates, "endDate");
  const optinEndDate = get(contestStandingsDates, "optInEndDate");
  const showCountdown = get(props, "showCountdown", false);
  const addBottomMargin = get(props, "addBottomMargin", false);
  const borderTopRadius = get(props, "borderTopRadius", false);
  let text = get(props, "infoText");

  if (
    contestStartDate &&
    moment().isBefore(contestStartDate) &&
    showCountdown
  ) {
    const { countDownText } = useCountDownTimer(contestStartDate);
    text = `Contest starts ${countDownText}`;
  }

  if (
    optinEndDate &&
    moment().isAfter(contestStartDate) &&
    moment().isBefore(optinEndDate) &&
    showCountdown
  ) {
    const { countDownText } = useCountDownTimer(optinEndDate);
    text = `Contest entries closes ${countDownText}`;
  }

  if (
    optinEndDate &&
    moment().isAfter(optinEndDate) &&
    moment().isBefore(contestEndDate) &&
    showCountdown
  ) {
    text = "Entries for this contest are now closed";

    return (
      <ErrorNotification
        text={text}
        addBottomMargin={addBottomMargin}
        borderTopRadius={borderTopRadius}
        qaLabel={`${qaLabel}Error`}
      />
    );
  }

  if (contestEndDate && moment().isAfter(contestEndDate) && showCountdown) {
    text = "Contest Closed";
  }

  return (
    <InfoNotification
      text={text}
      addBottomMargin={addBottomMargin}
      borderTopRadius={borderTopRadius}
      qaLabel={`${qaLabel}Info`}
    />
  );
};

const ContestNotifications = (props: Props) => {
  switch (props.type) {
    case "success":
      return props.isOpted && renderSuccessMsg(props);
    case "error":
      return renderErrorMsg(
        get(props, "errorType"),
        get(props, "addBottomMargin", false),
        get(props, "borderTopRadius", false),
        get(props, "qaLabel", "contestNotifications")
      );
    default:
      return !props.isOpted && renderInfoMessage(props);
  }
};

ContestNotifications.defaultProps = {
  type: "error",
  isOpted: false,
  optinTriggered: false,
  errorType: "An error has occurred",
  successText: "",
  infoText: "",
  showCountdown: false,
  addBottomMargin: false,
  borderTopRadius: false,
  qaLabel: "contestNotifications"
};

export default ContestNotifications;
