// @flow
// $FlowFixMe
import React, { Fragment, useContext } from "react";
import { get, find } from "lodash";

import ProcessingOverlay from "@tvg/atomic-ui/_static/ProcessingOverlay";
import Context from "@tvg-mar/promos-context";

import type { ContentProps } from "@tvg-mar/promos-types/Template";
import type { User } from "@tvg-mar/promos-types/Promos";

import Components from "../../_dictionary";
import MenuBox from "../../_organisms/MenuBox";
import OfferItems from "../../_organisms/OfferItems";
import AcquisitionOffer from "../../_organisms/AcquisitionOffer";
import Banner from "../../_organisms/Banner";
import OptinButton from "../../_molecules/OptinButton";
import LegalsContent from "../../_static/LegalsContent";
import {
  ProcessingContainer,
  SeoPageContainer,
  SeoContentGrid,
  SeoBodyGrid,
  SeoGlobalGrid,
  SeoMobileFooter,
  SeoContentStacked
} from "./styled-components";

type Props = {
  isLoading: boolean,
  isMobile: boolean,
  user: User,
  content: ContentProps,
  qaLabel?: string
};

const { PathContext } = Context;

const SeoPage = ({
  isLoading,
  isMobile,
  user,
  content,
  qaLabel = "pageContainer"
}: Props) => {
  const pathContext = useContext(PathContext);
  const { returningUser, isLogged } = user;
  const slug = pathContext.currentSlug.endsWith("/")
    ? pathContext.currentSlug
    : `${pathContext.currentSlug}/`;
  const bannerBlok = get(content, "banner");
  const bannerProps = find(bannerBlok, { component: "banner_v2_0" });
  const globalContent = get(content, "global_elements");
  const globalSeoRef = find(globalContent, { component: "global_ref" });
  const reference = get(globalSeoRef, "reference");
  const seoContent = find(reference, "content");
  const seoGlobalBloks = get(seoContent, "content.global");

  let menu;
  let acqOffer;
  let offers;
  let ctaButton;
  let legalContent;

  if (seoGlobalBloks && seoGlobalBloks.length > 0) {
    seoGlobalBloks.map((blok) => {
      switch (blok.component) {
        case "menu":
          menu = blok;
          break;
        case "acquisition_offer":
          acqOffer = blok;
          break;
        case "offers":
          offers = blok;
          break;
        case "optin_button":
          ctaButton = blok;
          break;
        case "legal_content_v2_0":
          legalContent = blok;
          break;
        default:
          break;
      }

      return null;
    });
  }

  const renderGrid = () => (
    <SeoContentGrid data-qa-label={`${qaLabel}SeoContentGrid`}>
      <SeoBodyGrid data-qa-label={`${qaLabel}SeoBodyGrid`}>
        {content.body.map((blok) => Components({ ...blok, isModule: true }))}
        {!returningUser && !isLogged && legalContent && (
          <LegalsContent
            content={{ ...legalContent, isModule: true }}
            qaLabel={`${qaLabel}LegalsContentGrid`}
          />
        )}
      </SeoBodyGrid>
      <SeoGlobalGrid data-qa-label={`${qaLabel}SeoGlobalGrid`}>
        {menu && (
          <MenuBox {...menu} slug={slug} qaLabel={`${qaLabel}MenuBoxGrid`} />
        )}
        {!returningUser && !isLogged && acqOffer && (
          <AcquisitionOffer
            {...acqOffer}
            qaLabel={`${qaLabel}AcquisitionOfferGrid`}
          />
        )}
        {offers && (
          <OfferItems {...offers} qaLabel={`${qaLabel}OfferItemsGrid`} />
        )}
      </SeoGlobalGrid>
    </SeoContentGrid>
  );

  const renderFooter = () => (
    <Fragment>
      {menu && (
        <MenuBox
          {...menu}
          isMobile={isMobile}
          slug={slug}
          qaLabel={`${qaLabel}MenuBoxFooter`}
        />
      )}
      {(returningUser || isLogged) && ctaButton && (
        <OptinButton
          content={{
            ...ctaButton,
            isMobilePersistent: true,
            isUpperCase: false
          }}
          qaLabel={`${qaLabel}OptinButtonFooter`}
        />
      )}
    </Fragment>
  );

  const renderStacked = () => (
    <SeoContentStacked data-qa-label={`${qaLabel}Stacked`}>
      {!returningUser && !isLogged && acqOffer && (
        <AcquisitionOffer
          {...acqOffer}
          qaLabel={`${qaLabel}AcquisitionOfferStacked`}
        />
      )}
      {content.body.map((blok) => Components({ ...blok, isModule: false }))}
      {offers && (
        <OfferItems {...offers} qaLabel={`${qaLabel}OfferItemsStacked`} />
      )}
      {!returningUser && !isLogged && legalContent && (
        <LegalsContent
          content={legalContent}
          qaLabel={`${qaLabel}LegalsContentStacked`}
        />
      )}
      <SeoMobileFooter data-qa-label={`${qaLabel}MobileFooterStacked`}>
        {renderFooter()}
      </SeoMobileFooter>
    </SeoContentStacked>
  );

  return (
    <SeoPageContainer
      data-qa-label={qaLabel}
      addBottomMargin={returningUser || isLogged}
    >
      {isLoading && (
        <ProcessingContainer data-qa-label={`${qaLabel}Processing`}>
          <ProcessingOverlay qaLabel={`${qaLabel}ProcessingOverlay`} />
        </ProcessingContainer>
      )}
      {bannerProps && (
        <Banner
          content={{
            ...bannerProps,
            isForReturningCustomers: true,
            isLogged,
            returningUser
          }}
          qaLabel={`${qaLabel}Banner`}
        />
      )}
      {!isLoading && renderGrid()}
      {!isLoading && renderStacked()}
    </SeoPageContainer>
  );
};

export default SeoPage;
