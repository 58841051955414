// @flow
import React from "react";
import ScrollWrapper from "@tvg/atomic-ui/_molecule/ScrollWrapper";
import type { TableProps } from "@tvg-mar/promos-types/Component";

import TableSlot from "../../_molecules/TableSlot";
import { TableComponentWrapper, Title, SubTitle } from "./styled-components";

type Props = {
  content: TableProps,
  qaLabel: string
};

const TableContent = ({ content, qaLabel }: Props) => (
  <ScrollWrapper qaLabel={qaLabel}>
    <TableComponentWrapper data-qa-label={`${qaLabel}Wrapper`}>
      <SubTitle data-qa-label={`${qaLabel}Title`}>{content.title}</SubTitle>
      <Title data-qa-label={`${qaLabel}Description`}>
        {content.description}
      </Title>
      {content.tableContent.map((row) => (
        <TableSlot key={row._uid} content={row} qaLabel={`${qaLabel}Slot`} />
      ))}
    </TableComponentWrapper>
  </ScrollWrapper>
);

TableContent.defaultProps = {
  content: {
    title: "",
    description: "",
    tableContent: []
  },
  qaLabel: "tableContent"
};

export default TableContent;
