// @flow
import styled from "styled-components";
import { fontNormal, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const OfferItemLink = styled.div`
  a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-family: ${fontNormal};
    color: ${buildColor("blue", "500")};
    font-size: 14px;
    padding: 16px 12px 16px 0;
  }

  @media only screen and (max-width: 767px) {
    margin-left: 12px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    margin-left: 24px;

    a {
      padding: 16px 24px 16px 0;
    }
  }

  @media only screen and (min-width: 1024px) {
    margin-left: 12px;
  }
`;

export const OfferItemContainer = styled.div`
  background-color: ${buildColor("white", "900")};

  @media only screen and (max-width: 1023px) {
    border-bottom: 1px solid ${buildColor("blue", "100")};
  }

  @media only screen and (min-width: 1024px) {
    &:not(:last-child) {
      > div {
        border-bottom: 1px solid ${buildColor("blue", "100")};
      }
    }

    &:last-child {
      > div {
        border-bottom: none;
      }
    }
  }

  &:hover {
    background-color: ${buildColor("blue_accent", "000")};
  }

  &:active {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const OfferItemContent = styled.div`
  display: flex;
  flex-direction: column;

  span:first-child {
    font-family: ${fontMedium};
    line-height: 19px;
  }

  span:last-child {
    margin-top: 8px;
    font-family: ${fontNormal};
    line-height: 18px;
    max-height: 36px;
    max-width: 232px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const OfferItemText = styled.span`
  font-size: 14px;
  color: ${buildColor("grey", "900")};
`;

export const EventTime = styled.time`
  display: none;
`;

export const EventPlace = styled.div`
  display: none;
`;

export const EventLocation = styled.div`
  display: none;
`;

export const EventText = styled.span`
  display: none;
`;
