//  @flow
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import type { LocationShape } from "react-router-dom";
import type { ComponentType } from "react";
import { fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

const buttonColors = css`
  .marketingTVG {
    background-color: ${buildColor("yellow", "500")};
    color: ${buildColor("grey", "900")};

    &:hover {
      background-color: ${buildColor("yellow", "600")};
    }

    &:active {
      background-color: ${buildColor("yellow", "700")};
    }
  }

  .betTVG {
    color: ${buildColor("white", "100")};
    background-color: ${buildColor("blue_accent", "500")};

    &:hover {
      background-color: ${buildColor("blue_accent", "600")};
    }

    &:active {
      background-color: ${buildColor("blue_accent", "700")};
    }
  }
`;

export const OptinButtonContainer = styled.div`
  @media only screen and (max-width: 600px) {
    ${({ isMobilePersistent }) =>
      !isMobilePersistent &&
      `
      display: none;
    `}
  }

  span {
    font-family: ${fontNormal};
  }

  button span,
  a span {
    text-transform: ${({ isUpperCase }) =>
      isUpperCase === true ? "uppercase" : "capitalize"};
  }

  button,
  a {
    font-size: 14px;
    padding: 14px 16px;
    height: 40px;
    border-radius: 2px;
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }

  &.promosOptInButton {
    @media only screen and (min-width: 600px) {
      button,
      a {
        width: 200px;
      }
    }

    ${buttonColors}
  }

  &.promosBannerBtn {
    a,
    button {
      font-size: 14px;
      line-height: 16px;

      @media only screen and (min-width: 920px) {
        max-width: 190px;
      }
    }

    ${buttonColors}
  }

  @media only screen and (max-width: 599px) {
    [data-qa-label="promosBodyContentWrapper"] &.promosOptInButton {
      button,
      a {
        display: none;
      }
    }
  }

  @media only screen and (min-width: 689px) {
    &.promosOptInButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 24px 0;
    }
  }
`;

type LinkAttrs = {
  as: string | typeof Link,
  to: string | typeof undefined | LocationShape
};

export const ButtonLink: ComponentType<LinkAttrs> = styled.div.attrs({})`
  text-decoration: none;
`;
