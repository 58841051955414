// @flow
import React from "react";

import type { RichTextDoc } from "@tvg-mar/promos-types/RichText";

import RichText from "../../_molecules/RichText";
import Icon from "../../_static/Icons";
import { quoteSymbol } from "../../_static/Icons/icons";

import { Wrapper, QuoteWrapper, Quote, QuoteAuthor } from "./styled-components";

type Props = {
  content: {
    leftText: string | RichTextDoc,
    quoteText: string | RichTextDoc,
    quoteAuthor: string
  },
  qaLabel: string
};

const TextAndQuote = (props: Props) => {
  const { content, qaLabel = "textAndQuote" } = props;
  const { leftText, quoteText, quoteAuthor } = content;

  return (
    <Wrapper data-qa-label={qaLabel}>
      <RichText
        content={{ markdown: leftText }}
        qaLabel={`${qaLabel}LeftText`}
      />
      <QuoteWrapper data-qa-label={`${qaLabel}Wrapper`}>
        <Icon
          icon={quoteSymbol}
          size={12}
          view=""
          qaLabel={`${qaLabel}QuoteSymbol`}
        />
        <Quote data-qa-label={`${qaLabel}Quote`}>
          <RichText
            content={{ markdown: quoteText }}
            qaLabel={`${qaLabel}QuoteText`}
          />
          {quoteAuthor && (
            <QuoteAuthor data-qa-label={`${qaLabel}QuoteAuthor`}>
              {quoteAuthor}
            </QuoteAuthor>
          )}
        </Quote>
      </QuoteWrapper>
    </Wrapper>
  );
};

TextAndQuote.defaultProps = {
  content: {
    leftText: "",
    quoteText: "",
    quoteAuthor: ""
  },
  qaLabel: "textAndQuote"
};

export default TextAndQuote;
