// @flow

import styled, { css } from "styled-components";

const Grid = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 8px 8px 0;
  ${(props) =>
    props.isFixed
      ? css`
          position: fixed;
          padding-bottom: 48px;
          padding-top: 64px;
        `
      : ""}
`;

const Column = styled.div`
  flex-grow: 1;
  position: relative;
  ${(props) =>
    props.side === "left"
      ? css`
          @media (min-width: 720px) {
            min-width: 320px;
          }

          max-width: 60%;
          overflow: hidden;
          border-right: 8px solid transparent;
          display: flex;
          flex-direction: column;

          & > section {
            margin-top: 8px;
          }
        `
      : css`
          flex-shrink: 0;
          min-width: 320px;
          max-width: 40%;
          border-left: 8px solid transparent;
          display: flex;
          flex-direction: column;
          overflow: auto;
        `};
`;

const ColumnContentContainer = styled.div`
  width: 100%;
  height: auto;
  overflow: scroll;
  margin-top: 8px;
`;

export default {
  Grid,
  Column,
  ColumnContentContainer
};
