// @flow
import React from "react";
import { noop } from "lodash";
import Button from "@tvg/atomic-ui/_atom/Buttons/button";
import {
  Container,
  IconWrapper,
  Title,
  Description
} from "./styled-components";
import Icon from "../../_static/Icons";
import { errorOutline } from "../../_static/Icons/icons";

type Props = {
  title: string,
  description: string,
  buttonText: string,
  onClick: () => mixed,
  qaLabel: string
};

const Error = ({ title, description, buttonText, onClick, qaLabel }: Props) => (
  <Container data-qa-label={qaLabel}>
    <IconWrapper data-qa-label={`${qaLabel}IconWrapper`}>
      <Icon
        icon={errorOutline}
        size={40}
        view="0 0 16 16"
        qaLabel={`${qaLabel}Icon`}
      />
    </IconWrapper>
    <Title data-qa-label={`${qaLabel}Title`}>{title}</Title>
    <Description data-qa-label={`${qaLabel}Description`}>
      {description}
    </Description>
    <Button
      type="secondary"
      size="big"
      qaLabel={`${qaLabel}Button`}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  </Container>
);

Error.defaultProps = {
  title: "",
  description: "",
  buttonText: "",
  onClick: noop,
  qaLabel: "error"
};

export default Error;
