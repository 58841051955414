import React, { forwardRef, memo, useEffect } from "react";
import { Animated, View } from "react-native";
import { Svg, Circle } from "react-native-svg";
import { easeExpInOut } from "d3-ease";
import { get } from "lodash";

import { useTheme } from "../../theming";
import { useIconSize, useQaLabel } from "../../hooks";
import { Spinner } from "./components";
import { Container } from "./styled-components";
import { LoadingProps } from "./types";

const LoadingSpinner = forwardRef<View, LoadingProps>(
  (
    {
      size = "l",
      color = "blue_accent.500",
      bgColor = "blue_accent.200",
      qaLabel = "loading-spinner"
    }: LoadingProps,
    ref
  ) => {
    const testProps = useQaLabel(qaLabel);
    const { colors } = useTheme();
    const { iconSize, strokeWidth } = useIconSize(size);
    const rotationAnim = new Animated.Value(0);
    const interpolateRotation = rotationAnim.interpolate({
      inputRange: [0, 1],
      outputRange: ["0deg", "360deg"]
    });

    useEffect(() => {
      requestAnimationFrame(() => {
        Animated.loop(
          Animated.timing(rotationAnim, {
            useNativeDriver: false,
            toValue: 1,
            duration: 1800,
            easing: easeExpInOut
          }),
          {
            iterations: -1
          }
        ).start();
      });
    }, []);

    return (
      <Container {...testProps} iconSize={iconSize} ref={ref}>
        <Animated.View style={{ transform: [{ rotate: interpolateRotation }] }}>
          <Svg viewBox="0 0 24 24" height={iconSize} width={iconSize}>
            <Circle
              fill="transparent"
              cx="12"
              cy="12"
              r="11"
              stroke={get(colors, bgColor, bgColor)}
              strokeWidth="2"
              strokeDasharray="68.7"
            />
            <Spinner
              color={get(colors, color, color)}
              strokeWidth={strokeWidth}
            />
          </Svg>
        </Animated.View>
      </Container>
    );
  }
);

export const Loading = LoadingSpinner;
export { LoadingProps };
export default memo(LoadingSpinner);
