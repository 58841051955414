// @flow
import React, { PureComponent } from "react";
import buildColor from "../../_static/ColorPalette";

import BorderLine from "./styled-components";

type Props = {
  content: {
    color: string
  },
  qaLabel: string
};

export default class BorderBottom extends PureComponent<Props> {
  static defaultProps = {
    content: {
      color: buildColor("blue", "000")
    },
    qaLabel: "borderBottom"
  };

  render() {
    const { content, qaLabel } = this.props;
    return <BorderLine data-qa-label={qaLabel} color={content.color} />;
  }
}
