//  @flow
import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const FeaturedSlotsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  max-width: 1024px;
  min-width: 10em;

  @media only screen and (min-width: 768px) {
    margin-top: 1%;
  }
`;

export const PromoSlotsWrapper = styled.div`
  display: block;
  padding: 16px 5px 0;
  background-color: ${buildColor("white", "100")};
  border-radius: 4px 4px 0 0;

  .promoTopSlotsTitle {
    color: ${buildColor("grey", "900")};
    margin: 0 0 24px 6px;
  }

  @media only screen and (max-width: 599px) {
    padding: 16px 6px 0;

    .promoTopSlotsTitle {
      margin: 0 6px 24px;
    }
  }
`;
