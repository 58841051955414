import { ForwardedRef } from "react";
import styled, { css } from "styled-components/native";
import { View, TouchableHighlight, Text } from "react-native";
import { StyledProps } from "./types";

export const Container = styled(View)<{
  ref: ForwardedRef<View & HTMLDivElement>;
}>`
  position: relative;
`;

export const ButtonContent = styled(View)`
  display: flex;
  flex-direction: row;
`;

export const BetTypesTouchable = styled(TouchableHighlight)<StyledProps>`
  height: 100%;
  padding: ${({ theme }) =>
    `${theme.space["space-6"]} ${theme.space["space-4"]}`};
  border-top-color: ${({ theme }) => theme.colors.white["900"]};
  border-top-width: 2px;
  ${({ isHovered, theme }) =>
    isHovered &&
    css`
      background-color: ${theme.colors.blue_accent["100"]};
      border-top-color: ${theme.colors.blue_accent["100"]};
    `}

  ${({ isPressed, theme }) =>
    isPressed &&
    css`
      background-color: ${theme.colors.blue_accent["200"]};
      border-bottom-color: ${theme.colors.blue_accent["500"]};
      border-bottom-width: 1px;
      border-top-color: ${theme.colors.blue_accent["200"]};
    `}

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border-bottom-color: ${theme.colors.blue_accent["500"]};
      border-bottom-width: 1px;
    `};
`;

export const BetTypesText = styled(Text)<StyledProps>`
  color: ${({ theme }) => theme.colors.grey["900"]};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${({ theme }) => theme.fontSizes.s};
  text-align: center;
  ${({ isSelected, isPressed, theme }) =>
    (isSelected || isPressed) &&
    css`
      color: ${theme.colors.blue_accent["500"]};
    `};
  margin-right: 4px;
`;
