import React from "react";
import { get } from "lodash";
import { Icon, ProgressBar } from "@tvg/design-system";
import { ReferralStatus } from "@tvg/ts-types/ReferFriend";
import { getRefereeCurrentStep } from "@tvg-mar/utils/referralCenterUtils";
import RefereeStatus from "../../_molecules/RefereeStatus";
import { RefereeRowContainer, IconContainer } from "./styled-components";

export interface Props {
  referralStatus: ReferralStatus;
  isClickable?: boolean;
  qaLabel?: string;
}

const RefereeRow = ({
  referralStatus,
  isClickable,
  qaLabel = "refereeRow"
}: Props) => {
  const { accountId } = get(referralStatus, "refereeReferralData");
  return (
    <RefereeRowContainer
      isClickable={isClickable}
      data-qa-label={`${accountId}-${qaLabel}`}
    >
      <RefereeStatus referralStatus={referralStatus} />
      <ProgressBar
        qaLabel={`${qaLabel}Progress`}
        completedSteps={getRefereeCurrentStep(referralStatus)}
      >
        <ProgressBar.Step qaLabel={`${qaLabel}SignUp`} />
        <ProgressBar.Step qaLabel={`${qaLabel}Deposit`} />
        <ProgressBar.Step qaLabel={`${qaLabel}Bonus`} />
      </ProgressBar>
      {isClickable && (
        <IconContainer data-qa-label={`${qaLabel}InfoContainer`}>
          <Icon name="chevronRight" size="m" qaLabel={`${qaLabel}Icon`} />
        </IconContainer>
      )}
    </RefereeRowContainer>
  );
};

export default RefereeRow;
