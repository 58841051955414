// @flow
import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontBold } from "../../_static/Typography";

export const Wrapper = styled.div`
  background-color: ${buildColor("white", "900")};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & > div:first-child {
    margin-right: 12px;
  }

  & > div:last-child {
    width: 313px;
    flex: 1 0 auto;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;

    & > div:last-child {
      width: 100%;
    }
  }
`;

export const QuoteWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  & > svg {
    margin: 12px 4px 0 0;
    width: 40px;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    & > svg {
      margin-top: 24px;
    }
  }

  @media screen and (max-width: 767px) {
    padding-left: 12px;
    padding-right: 12px;

    & > svg {
      margin-right: 0;
    }
  }
`;

export const Quote = styled.div`
  & > div {
    padding-bottom: 0;
  }

  & p {
    padding-left: 0;
    font-family: ${fontBold};
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: ${buildColor("grey", "800")};
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin-right: 12px;

    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const QuoteAuthor = styled.div`
  color: ${buildColor("grey", "600")};
  font-size: 12px;
  margin-top: 4px;

  &::before {
    content: "-";
    margin-right: 4px;
  }
`;
