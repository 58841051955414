// @flow
import React, { PureComponent } from "react";
import Parser from "html-react-parser";
import type { RichTextDoc } from "@tvg-mar/promos-types/RichText";

import { noop } from "lodash";
import BodyText from "./styled-components";
import { RenderRichText } from "../../_static/richTextEditorResolver";

export type Content = {
  markdown: string | RichTextDoc
};

type Props = {
  content: Content,
  qaLabel: string
};

class ContentText extends PureComponent<Props> {
  static defaultProps = {
    content: {
      qaLabel: "",
      className: undefined,
      fontSize: undefined,
      lineHeight: undefined
    },
    qaLabel: "contentText"
  };

  render() {
    const { content, qaLabel } = this.props;

    const listOfContents = [];
    if (typeof content.markdown !== "string") {
      content.markdown.content.forEach((blok) => listOfContents.push(blok));
    } else {
      return (
        <BodyText data-qa-label={qaLabel}>{Parser(content.markdown)}</BodyText>
      );
    }

    return (
      <BodyText data-qa-label={qaLabel}>
        {listOfContents.map((blok) => RenderRichText(blok))}
      </BodyText>
    );
  }
}

export default ContentText;
