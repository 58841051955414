// @flow

import styled, { css } from "styled-components";
import { fontMedium, defaultSize } from "../../_static/Typography";
import { buildText } from "../Text";
import ButtonDefault from "../Buttons/default";
import buildColor from "../../_static/ColorPalette";
import { easeInOut, generateTransition } from "../../_static/Transitions";

export const TabAtom = styled(ButtonDefault)`
  background: none;
  text-align: center;
  transition: ${generateTransition(easeInOut, "color")};
  color: ${(props) =>
    props.isActive
      ? buildColor("blue_accent", "500")
      : buildColor("grey", "900")};
  border-radius: 0;
  white-space: nowrap;
  border: none;
  height: 48px;
  width: 100%;
  padding: 0 12px;
  position: relative;
  overflow: hidden;
  will-change: color;

  &:active:not(:disabled) {
    background-color: ${buildColor("blue", "000")};
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: ${(props) => (props.isActive ? "0" : "-2px")};
    height: 2px;
    will-change: bottom;
    transition: ${generateTransition(easeInOut, "bottom")};
    background-color: ${buildColor("blue_accent", "500")};
  }

  > strong,
  p {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      ${(props) =>
        (props.displayZero || (props.counter && props.counter > 0)) &&
        css`
          content: "${props.counter.toString()}";
          ${props.displayZero && props.counter === 0
            ? css`
                background-color: ${buildColor("blue", "100")};
                color: ${buildColor("grey", "900")};
                opacity: 0.5;
              `
            : css`
                background-color: ${buildColor("red", "500")};
                color: ${buildColor("white", "100")};
              `};
        `};
      border-radius: 8px;
      margin-left: 4px;
      display: inline-flex;
      min-width: 12px;
      height: 16px;
      padding: 0 2px;
      text-align: center;
      font-weight: bold;
      font-size: 11px;
      line-height: 11px;
      justify-content: center;
      align-items: center;
      margin-top: 2px;
    }
  }
`;

export const PillAtom = styled(ButtonDefault)`
  background: ${(props) =>
    props.isActive
      ? buildColor("blue_accent", "100")
      : buildColor("blue_accent", "000")};
  text-align: center;
  transition: ${generateTransition(easeInOut, "color")};
  color: ${(props) =>
    props.isActive
      ? buildColor("blue_accent", "500")
      : buildColor("grey", "900")};
  border-radius: 100px;
  white-space: nowrap;
  border: ${(props) =>
    props.isActive
      ? `1px solid ${buildColor("blue_accent", "500")}`
      : `1px solid ${buildColor("blue", "100")}`};
  height: 44px;
  width: 100%;
  padding: 14px 16px;
  margin: 13px 0 11px;
  position: relative;
  overflow: hidden;
  will-change: color;
  ${(props) =>
    !props.isActive &&
    "box-shadow: 0px 2px 4px rgba(17, 43, 68, 0.06), 0px 1px 2px rgba(17, 43, 68, 0.08);"}

  &:active:not(:disabled) {
    background-color: ${buildColor("blue_accent", "200")};
    box-shadow: initial;
  }
`;

export const Text = styled(
  buildText({
    tag: "p",
    fontSize: 14,
    qaLabel: "tab-text"
  })
)`
  color: ${(props) => props.color};
  ${({ isTitleUppercase }) =>
    isTitleUppercase
      ? css`
          text-transform: uppercase;
          font-family: ${fontMedium};
        `
      : css`
          font-family: ${fontMedium};
          font-size: ${defaultSize};
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0;
          text-align: center;
        `};
`;
