// @flow
import React from "react";
import { Link, type Location, type RouterHistory } from "react-router-dom";
import { isTvg5 } from "@tvg/utils/generalUtils";
import withRouter from "@tvg/utils/withCustomRouter";
import { get } from "lodash";
import gtmUtils from "@tvg-mar/utils/gtmEventHandlers";
import pathUtils from "@tvg-mar/utils/pathHandlingUtils";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";

import type { StepLink } from "@tvg-mar/promos-types/Component";
import type { Route, LinkType } from "@tvg-mar/promos-types/PathHandling";

import { PromosIconLink, LinkTag } from "./styled-components";
import PromosIcon from "../PromosIcon";

type Props = {
  content: StepLink,
  location: Location,
  qaLabel: string,
  history: RouterHistory
};

const handleGTMEvents = (
  event: Event,
  linkProps: Route,
  content: StepLink
): void => {
  const { text } = content;
  const linkLabel = text;
  const pageOrigin = "promo hub landing";
  let urlLink;

  if (linkProps.as === Link) {
    const linkPath = get(linkProps, "to", "");
    const pathName = get(linkPath, "pathname", "/");
    const search = get(linkPath, "search", "");
    const hash = get(linkPath, "hash", "");
    urlLink = `${pathName}${search}${hash}`;
  } else {
    urlLink = get(linkProps, "href", "");
  }

  gtmUtils.handleSiteClickDataLayerUpdate({
    linkLabel,
    urlLink
  });
  gtmUtils.handleNavigationDataLayerUpdate(linkLabel, pageOrigin, urlLink);
};

const handleDesktopPath = (event: Event, urlPath: string): void => {
  event.preventDefault();
  event.stopPropagation();
  mediator.base.dispatch({
    type: "TVG4_NAVIGATION",
    payload: {
      route: urlPath.charAt(0) === "/" ? urlPath : `/${urlPath}`
    }
  });
};

const handleMobileAppPath = (
  event: Event,
  linkType: LinkType,
  urlPath: string
): void => {
  event.preventDefault();
  event.stopPropagation();
  pathUtils.handleExternalIOSAppPath(event, linkType, urlPath);
};

const IconLink = ({
  content,
  location,
  qaLabel = "iconLink",
  history
}: Props) => {
  const { text, link, icon } = content;
  const linkType = link.linktype;
  const urlPath = linkType === "story" ? link.cached_url : link.url;
  const isFullPath: boolean = /http(s)?/.test(urlPath);
  let componentProps: Route;

  if (linkType === "story" || !isFullPath) {
    componentProps = pathUtils.setInternalPath(link, location);
  } else {
    componentProps = pathUtils.setExternalPath(link);
  }

  // $FlowFixMe - Flow is not recognising the prop
  const { isInternalButExternal, ...componentPropsRest } = componentProps;

  return (
    <PromosIconLink data-qa-label={qaLabel}>
      <LinkTag
        data-qa-label={`${qaLabel}LinkTag`}
        {...componentPropsRest}
        // eslint-disable-next-line consistent-return
        onClick={(event) => {
          if (isTvg5()) {
            if (urlPath.charAt(0) === "/" || urlPath.startsWith("promos")) {
              return history.push(urlPath);
            }
            window.open(urlPath, "_blank");
            event.preventDefault();
          } else if (
            (linkType === "story" || isInternalButExternal) &&
            tvgConf().product === "tvg4"
          ) {
            handleDesktopPath(event, urlPath);
          } else {
            if (linkType === "story") {
              mediator.base.dispatch({
                type: "UPDATE_PROMOS_CONTENT",
                payload: { slug: urlPath }
              });
            }

            // Manages relative paths
            // $FlowFixMe - Flow is not recognising the prop
            if (isInternalButExternal) {
              if (tvgConf().product === "iosnative") {
                window.handleNativeMessages("NAVIGATE_FROM_WEBVIEW", {
                  url: urlPath,
                  isLogged: true
                });
              } else {
                mediator.base.dispatch({
                  type: "CLOSE_PROMOS_MODAL"
                });
              }
            }

            // Redirect for iOS app
            const isExternal = isFullPath && linkType === "url";
            if (isExternal && tvgConf().product === "ios2") {
              handleMobileAppPath(event, linkType, urlPath);
            } else if (isExternal && tvgConf().product === "iosnative") {
              pathUtils.handleExternalIOSNativeAppPath(urlPath, event);
            }
          }

          handleGTMEvents(event, componentProps, content);
        }}
      >
        <PromosIcon icon={icon} qaLabel={`${qaLabel}PromosIcon`} />
        <p data-qa-label={`${qaLabel}Text`}>{text}</p>
      </LinkTag>
    </PromosIconLink>
  );
};

const locationChange = (prevProps, nextProps): boolean =>
  prevProps.location === nextProps.location;

// $FlowFixMe
export default withRouter(React.memo(IconLink, locationChange));
