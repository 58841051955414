import styled, { css } from "styled-components/native";
import { useQaLabel, useQaLabelActive } from "../../../../hooks";
import { space } from "../../../../theming/styledSystem";
import { StyledButtonProps, ButtonContainerProps } from "./types";
import { Theme } from "../../../../theming/types";

export const ButtonContainer = styled.View<ButtonContainerProps>`
  ${space};
  position: relative;
  z-index: ${({ isActive, isHovering }) =>
    isActive || isHovering ? "1" : "0"};
  flex-direction: row;
  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;
    `}
`;

export const StyledButton = styled.TouchableHighlight.attrs<{
  qaLabel: string;
  isActive: boolean;
  theme: Theme;
}>((props) => ({
  accessibilityRole: "button",
  underlayColor: props.theme.colors.blue_accent[100],
  ...useQaLabelActive(props.qaLabel, props.isActive ? "selected" : "")
}))<StyledButtonProps>`
  ${space};
  align-self: flex-start;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  height: 42px;
  padding: 0 ${({ theme }) => theme.space["space-5"]};
  ${({ hasShadow }) =>
    hasShadow &&
    css`
      box-shadow: ${({ theme }) => theme.elevations.low};
    `}
  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;
      padding: 0;
    `}
  ${({ isHovering }) =>
    isHovering &&
    css`
      background-color: ${({ theme }) => theme.colors.blue_accent["000"]};
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.blue_accent[100]};
    `}
`;

export const ButtonText = styled.Text.attrs<{ qaLabel: string }>((props) => ({
  ...useQaLabel(props.qaLabel)
}))<{
  isActive: boolean;
  isHovering: boolean;
  qaLabel: string;
  theme: Theme;
}>`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.grey[900]};
  ${({ isActive, isHovering }) =>
    (isActive || isHovering) &&
    css`
      color: ${({ theme }) => theme.colors.blue_accent[700]};
    `}
  ${({ isActive, isHovering }) =>
    isActive &&
    isHovering &&
    css`
      color: ${({ theme }) => theme.colors.blue_accent[800]};
    `}
`;

export const ButtonRing = styled.View<{
  isLastChild?: boolean;
  isFirstChild?: boolean;
  isActive: boolean;
  isHovering: boolean;
  theme: Theme;
}>`
  position: absolute;
  top: 0;
  right: ${({ isLastChild }) => (isLastChild ? "0" : "-1px")};
  bottom: 0;
  left: 0;
  border: 1px solid ${({ theme }) => theme.colors.blue[100]};
  ${({ isFirstChild }) =>
    isFirstChild &&
    css`
      border-bottom-left-radius: ${({ theme }) => theme.radii.xs};
      border-top-left-radius: ${({ theme }) => theme.radii.xs};
    `}
  ${({ isLastChild }) =>
    isLastChild &&
    css`
      border-bottom-right-radius: ${({ theme }) => theme.radii.xs};
      border-top-right-radius: ${({ theme }) => theme.radii.xs};
    `}
  ${({ isHovering }) =>
    isHovering &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.blue_accent[300]};
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.blue_accent[500]};
    `}
`;

export const TextContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;
