// @flow
// $FlowFixMe
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { get } from "lodash";
import moment from "moment";

import type { TabLabel } from "@tvg-mar/promos-types/Component";
import Spinner from "@tvg/atomic-ui/_static/Spinners";
import TabGroup from "@tvg/atomic-ui/_molecule/TabGroup";
import type {
  RankingMethod,
  Standing,
  StandingsDates,
  StandingsData
} from "@tvg-mar/promos-types/Promos";

import Icon from "../../_static/Icons";
import { cup } from "../../_static/Icons/icons";
import EmptyStandingsLeaderboard from "../../_molecules/EmptyStandingsLeader";
import InfoNotification from "../../_molecules/MessageTypes/infoNotication";
import Paginator from "../../_molecules/Paginator";
import {
  LeaderBoardsStandingsContainer,
  LeaderBoardsStandingsHeader,
  LeaderBoardsStandingsSubTitle,
  LeaderBoardsStandingsTitle,
  LeaderBoardStandingsWrapper,
  TableWrapper,
  Table,
  TableHead,
  TableBody,
  TableName,
  TableRow,
  TablePosition,
  TableState,
  TableWinnings,
  Processing
} from "./styled-components";
import buildColor from "../../_static/ColorPalette";

type StandingsTypes = {
  accountNumber: string,
  title: string,
  tabLabels: TabLabel[],
  rankingMethod: RankingMethod,
  numberVisibleStandings: number,
  isLoadingStandings: boolean,
  isLoadingStandingsError: boolean,
  isLoadingStandingsPageError: boolean,
  contestStandingsDates: StandingsDates,
  contestStandings: StandingsData[],
  contestStandingsPages: Standing[][][],
  userStandings: StandingsData[],
  getContestPageData: any
};

type Props = {
  content: StandingsTypes,
  qaLabel: string
};

export const checkActiveTabStatus = (startDate: string) => {
  let isAfterStartDate = false;

  if (startDate) {
    const utcDate = moment.utc(startDate);
    isAfterStartDate = moment().isAfter(utcDate);
  }

  return isAfterStartDate;
};

const currencyFormattor = (currency: number): string => {
  const currencyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return currencyFormat.format(currency);
};

export const numberFormattor = (
  entry: Standing,
  rankingMethod: RankingMethod
): string => {
  const { points, winningsAmount, bankrollAmount } = entry;
  let numberFormat;

  switch (rankingMethod) {
    case "Winnings":
      numberFormat = currencyFormattor(winningsAmount);
      break;
    case "Bankroll":
      numberFormat = currencyFormattor(bankrollAmount);
      break;
    default:
      numberFormat = points.toLocaleString("en-US");
  }

  return numberFormat;
};

export const entryNameWithWagerCountFormatter = (entry: Standing) => {
  const entryNameAndWagerCount = [entry.name];
  if (entry.wagerCount != null && entry.maxWagerCount != null) {
    // wagerCount & maxWagerCount should be displayed
    if (entry.wagerCount > 0 && entry.maxWagerCount > 0) {
      entryNameAndWagerCount.push(
        `(${entry.wagerCount}/${entry.maxWagerCount})`
      );
    }

    // only wagerCount should be displayed
    if (entry.wagerCount > 0 && entry.maxWagerCount === 0) {
      entryNameAndWagerCount.push(`(${entry.wagerCount})`);
    }
  }

  return entryNameAndWagerCount.join(" ");
};

const LeaderBoardStandings = (props: Props) => {
  const { qaLabel } = props;
  const {
    accountNumber,
    title,
    tabLabels,
    rankingMethod,
    numberVisibleStandings,
    isLoadingStandings,
    isLoadingStandingsError,
    isLoadingStandingsPageError,
    contestStandingsDates,
    contestStandings,
    contestStandingsPages,
    userStandings,
    getContestPageData
  } = props.content;

  const [activeTab, setActiveTab] = useState(0);
  const [tabPage, setTabPage] = useState(0);
  const [standings, setStandings] = useState([]);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

  const hasMoreTabPages = () => {
    const totalParticipants = get(contestStandings[activeTab], "actives", 0);
    const resultsPerPage = get(
      contestStandings[activeTab],
      "numberVisibleStandings",
      20
    );
    return (tabPage + 1) * resultsPerPage < totalParticipants;
  };

  const needToFecthNextPage = () => {
    return (
      contestStandingsPages[activeTab] &&
      contestStandingsPages[activeTab][tabPage + 1] === undefined &&
      hasMoreTabPages()
    );
  };

  const setNextTabStandings = useCallback(() => {
    if (needToFecthNextPage()) {
      getContestPageData(activeTab, tabPage + 1);
    }
  }, [activeTab, tabPage, contestStandings]);

  useEffect(() => {
    setNextButtonEnabled(false);
    if (!needToFecthNextPage()) {
      setNextButtonEnabled(true);
    }

    if (contestStandingsPages[activeTab]) {
      setStandings(contestStandingsPages[activeTab][tabPage]);
    }
  }, [activeTab, tabPage, contestStandings]);

  useEffect(() => {
    setNextButtonEnabled(true);
  }, [contestStandingsPages]);

  setNextTabStandings();

  const tabs = [];

  tabLabels.map((label, index) => {
    const tabConfig = {
      title: label.text,
      isTitleUppercase: false,
      isActive: activeTab === index,
      isDisabled: !checkActiveTabStatus(
        contestStandings[index]
          ? contestStandings[index].processingBeginDate
          : contestStandingsDates.beginDate
      ),
      onClick: () => {
        setActiveTab(index);
        setTabPage(0);
      }
    };

    return tabs.push(tabConfig);
  });

  const setLastUpdate = () => {
    const date = get(contestStandings[contestStandings.length], "lastRunDate");
    const lastUpdateDate = moment(date).format("MM/DD/YY [at] h:mma");

    return (
      <LeaderBoardsStandingsSubTitle
        data-qa-label={`${qaLabel}LastUpdateDate`}
      >{`Last updated ${lastUpdateDate}`}</LeaderBoardsStandingsSubTitle>
    );
  };

  const renderTopTableStandings = (entries: Standing[]) =>
    entries
      .filter((entry, index) => index < numberVisibleStandings)
      .map((entry, index) => {
        const isHighlight = accountNumber === entry.accountID.toString();

        return (
          <TableRow
            isTopStandings
            addBottomPadding={index === numberVisibleStandings - 1}
            key={`user-list-${index.toString()}`}
            data-qa-label={`${index}-${qaLabel}TopTableRow`}
          >
            <TablePosition
              data-qa-label={`${qaLabel}TopTableRank`}
              showPadding
              isHighlight={isHighlight}
            >
              {entry.rank}
            </TablePosition>
            <TableName
              isHighlight={isHighlight}
              data-qa-label={`${qaLabel}TopTableName`}
            >
              {entryNameWithWagerCountFormatter(entry)}
            </TableName>
            <TableState
              isHighlight={isHighlight}
              data-qa-label={`${qaLabel}TopTableState`}
            >
              {entry.userState}
            </TableState>
            <TableWinnings
              isHighlight={isHighlight}
              data-qa-label={`${qaLabel}TopTableWinnings`}
            >
              {numberFormattor(entry, rankingMethod)}
            </TableWinnings>
          </TableRow>
        );
      });

  const renderNearbyTableStandings = (entries: Standing[]) =>
    entries.map((entry, index) => {
      const isHighlight = accountNumber === entry.accountID.toString();

      return (
        <TableRow
          key={`user-standings-${entry.rank}`}
          isTopStandings={false}
          showTopBorder={index === 0}
          addBottomPadding={index === entries.length - 1}
          data-qa-label={`${entry.rank}-${qaLabel}NearbyTableRow`}
        >
          <TablePosition
            data-qa-label={`${qaLabel}NearbyTableRank`}
            showPadding
            isHighlight={isHighlight}
          >
            {entry.rank}
          </TablePosition>
          <TableName
            data-qa-label={`${qaLabel}NearbyTableName`}
            isHighlight={isHighlight}
          >
            {entryNameWithWagerCountFormatter(entry)}
          </TableName>
          <TableState
            data-qa-label={`${qaLabel}NearbyTableState`}
            isHighlight={isHighlight}
          >
            {entry.userState}
          </TableState>
          <TableWinnings
            data-qa-label={`${qaLabel}NearbyTableWinnings`}
            isHighlight={isHighlight}
          >
            {numberFormattor(entry, rankingMethod)}
          </TableWinnings>
        </TableRow>
      );
    });

  const renderTable = (contestantStandings: StandingsData) => {
    const { nearbyEntries } = contestantStandings;
    const showNearbyStandings = nearbyEntries
      ? nearbyEntries.filter(
          (entry) =>
            entry.accountID === +accountNumber &&
            entry.rank > +numberVisibleStandings
        )
      : [];

    return (
      <TableWrapper data-qa-label={`${qaLabel}TableWrapper`}>
        <Table data-qa-label={`${qaLabel}Table`}>
          <TableHead data-qa-label={`${qaLabel}TableHead`}>
            <TableRow data-qa-label={`${qaLabel}TableHeadRow`} isTopStandings>
              <TablePosition data-qa-label={`${qaLabel}TableHeadRank`}>
                <Icon
                  icon={cup}
                  size={16}
                  view="0 0 16 16"
                  strokeList={["", buildColor("grey", "800")]}
                  qaLabel={`${qaLabel}TableHeadRankIcon`}
                />
              </TablePosition>
              <TableName data-qa-label={`${qaLabel}TableHeadName`} isHeader>
                Player
              </TableName>
              <TableState data-qa-label={`${qaLabel}TableHeadState`} isHeader>
                State
              </TableState>
              <TableWinnings
                data-qa-label={`${qaLabel}TableHeadWinnings`}
                isHeader
              >
                {rankingMethod}
              </TableWinnings>
            </TableRow>
          </TableHead>
          <TableBody data-qa-label={`${qaLabel}TableBody`}>
            {standings && renderTopTableStandings(standings)}
            {tabPage === 0 &&
              showNearbyStandings.length > 0 &&
              nearbyEntries &&
              renderNearbyTableStandings(nearbyEntries)}
          </TableBody>
        </Table>
      </TableWrapper>
    );
  };

  const handlePageChanges = (page) => {
    setTabPage(page - 1);
  };

  const renderTabContent = () =>
    tabLabels.map((content, index) => {
      if (activeTab !== index) return undefined;

      const eventStartDate = get(
        contestStandings[index],
        "processingBeginDate",
        ""
      );
      let enableRoundTab = true;

      if (eventStartDate) {
        const utcDate = moment.utc(eventStartDate);
        enableRoundTab = moment().isAfter(utcDate);
      }

      const totalParticipants = get(contestStandings[index], "actives", 0);
      const filteredPosition =
        userStandings.length > 0
          ? userStandings[index].nearbyEntries.filter(
              (entry) => entry.accountID === +accountNumber
            )
          : [];

      let userPosition;

      if (filteredPosition.length > 0) {
        userPosition = filteredPosition[0].rank;
      }

      const currentPosition = userPosition
        ? moment.localeData().ordinal(userPosition)
        : "";

      const roundStandings: Standing[] = get(
        contestStandings[index],
        "topEntries",
        []
      );
      const showRoundStandings = roundStandings.length > 0;

      return (
        <Fragment key={content._uid}>
          {isLoadingStandings && !contestStandings[index] && (
            <Processing data-qa-label={`${qaLabel}Processing`}>
              <Spinner qaLabel={`${qaLabel}Spinner`} />
              <div data-qa-label={`${qaLabel}LoadingText`}>Loading...</div>
            </Processing>
          )}
          {!isLoadingStandings &&
            userPosition &&
            totalParticipants &&
            moment().isAfter(contestStandings[index].processingEndDate) && (
              <InfoNotification
                className="anchorComponent"
                text={`You placed ${currentPosition} of ${totalParticipants.toLocaleString()}`}
                showBorders
                isAnimated={false}
                isBoldTitle
                qaLabel={`${qaLabel}InfoNotification`}
              />
            )}
          {isLoadingStandingsError && (
            <EmptyStandingsLeaderboard
              title="Contest not active"
              text="Stay tuned for contest updates"
              qaLabel={`${qaLabel}EmptyNotActive`}
            />
          )}
          {(!enableRoundTab || !showRoundStandings) &&
            !isLoadingStandings &&
            !isLoadingStandingsError && (
              <EmptyStandingsLeaderboard
                title="No standings yet"
                text="This section will update momentarily"
                qaLabel={`${qaLabel}Empty`}
              />
            )}
          {!isLoadingStandings &&
            enableRoundTab &&
            showRoundStandings &&
            contestStandings[index] &&
            renderTable(userStandings[index] || [])}
          {showRoundStandings && enableRoundTab && (
            <Paginator
              showPrevButton={tabPage > 0}
              showNextButton={hasMoreTabPages()}
              handleClick={handlePageChanges}
              firstPageLabel={`${totalParticipants} Participants`}
              isLoadingStandingsPageError={
                isLoadingStandingsPageError && needToFecthNextPage()
              }
              nextButtonEnabled={nextButtonEnabled}
              alwaysAtBottom
              qaLabel={`${qaLabel}Paginator`}
            />
          )}
        </Fragment>
      );
    });

  const renderTabs = () => (
    <LeaderBoardStandingsWrapper data-qa-label={`${qaLabel}Wrapper`}>
      <LeaderBoardsStandingsHeader data-qa-label={`${qaLabel}Header`}>
        <LeaderBoardsStandingsTitle data-qa-label={`${qaLabel}Title`}>
          {title}
        </LeaderBoardsStandingsTitle>
        {setLastUpdate()}
      </LeaderBoardsStandingsHeader>
      {tabLabels.length > 1 && (
        <TabGroup tabs={tabs} qaLabel={`${qaLabel}TabGroup`} />
      )}
      {renderTabContent()}
    </LeaderBoardStandingsWrapper>
  );

  const contestStartDate = contestStandingsDates.beginDate;

  return (
    <LeaderBoardsStandingsContainer data-qa-label={qaLabel}>
      {contestStartDate && moment().isAfter(contestStartDate) && renderTabs()}
      {contestStartDate && moment().isBefore(contestStartDate) && (
        <EmptyStandingsLeaderboard
          title="Contest hasn't started yet"
          text="Stay tuned for contest updates"
          qaLabel={`${qaLabel}EmptyHasntStarted`}
        />
      )}
      {!contestStartDate && (
        <Processing data-qa-label={`${qaLabel}Processing`}>
          <Spinner qaLabel={`${qaLabel}Spinner`} />
          <div data-qa-label={`${qaLabel}LoadingText`}>Loading...</div>
        </Processing>
      )}
    </LeaderBoardsStandingsContainer>
  );
};

LeaderBoardStandings.defaultProps = {
  content: {
    accountNumber: "",
    title: "",
    tabLabels: [],
    rankingMethod: "",
    numberVisibleStandings: 0,
    isLoadingStandings: true,
    isLoadingStandingsError: false,
    contestStandings: [],
    userStandings: []
  },
  qaLabel: "leaderBoardStandings"
};

export default LeaderBoardStandings;
