import { GestureResponderEvent } from "react-native";
import { SpaceProps, LayoutProps } from "styled-system";

import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { SaddleSelectionsProps } from "../saddleSelections";
import { Breed } from "../saddle";

type BetStatusType = "NONE" | "PLACED" | "ERROR" | "PROCESSING";
export enum BetStatusCodesEnum {
  ACTIVE = "A",
  REFUNDED = "R",
  LOSER = "L",
  WINNER = "W",
  CANCELED = "C"
}

export interface BetTypeCellProps extends SpaceProps, LayoutProps {
  /** Bet Amount */
  betAmount: number;
  /** Bet type name */
  betTypeName: string;
  /** Bet Ticket value */
  betTicket: number;
  /** Number Wagerable Runners */
  numWagerableRunners?: string[] | undefined;
  /** Approximated Payout */
  approximatedPayout: string;
  /** Bet type */
  type: WagerTypeCodesEnum;
  /** Type of race */
  breed?: Breed;
  /** Saddle selections */
  selections: SaddleSelectionsProps[];
  /** Complete Selection Array */
  completeSelection?: SaddleSelectionsProps[][];
  /** Status of the bet */
  betStatus: BetStatusType;
  /** Repeat bet handler */
  onRepeatBetHandler?: (event: GestureResponderEvent) => void;
  /** Cancel bet handler */
  onCancelBetHandler?: (event: GestureResponderEvent) => void;
  /** Custom data-qa-label attribute applied to element */
  qaLabel?: string;
  /** Is leg bet * */
  isLeg: boolean;
  /** If include scratch in selections */
  scratches?: string[][];
  /** Is Key bet */
  isKey?: boolean;
  /** Is Box bet */
  isBox?: boolean;
  /** Is Wheel bet */
  isWheel?: boolean;
  /** Current race number */
  raceNumber?: string;
  /** No header and no border */
  showOnlySelections?: boolean;
  betStatusCode?: BetStatusCodesEnum;
  winAmountBet?: number;
  /** Is the bet refund amount */
  betRefund?: number;
  /** Is the bet repeatable */
  isRepeatable?: boolean;
  /** If is on handicaping */
  onHandicaping?: boolean;
}
