// @flow
import { get } from "lodash/fp";
import { attempt } from "@tvg/conf/src/utils";
import { PRODUCTS_ANDROID, PRODUCTS_FDR_X_SELL } from "@tvg/conf/src/product";
import BaseEventEmitter from "./Base";
import type { FSA, EventName } from "../Types.js.flow";

type WebkitEvent = {
  [EventName]: {
    postMessage: (FSA) => *
  }
};

type Options = {
  webkitEvents?: WebkitEvent
};

const FD_BRIDGE_MESSAGE_EVT = "BRIDGE_MESSAGE";

const FD_BRIDGE_EVENT_PREFIX = "x-sell/bridge/to-react/";

export const isAndroid = (product: string) =>
  PRODUCTS_ANDROID.includes(product);

export const isXSell = (product: string) =>
  PRODUCTS_FDR_X_SELL.includes(product);

export const checkSBBridgeEvents = (eventName: String) =>
  typeof eventName === "string" && eventName.startsWith(FD_BRIDGE_EVENT_PREFIX);

export default class APPEventEmitter extends BaseEventEmitter {
  webkit: WebkitEvent;

  androidAppPostMessage: null;

  constructor(options: Options = {}) {
    super();
    // android app flow
    if (isAndroid(get("__TVG_GLOBALS__.PRODUCT", window))) {
      // $FlowFixMe
      this.androidAppPostMessage = get("androidBridge.postMessage", window);

      if (!this.androidAppPostMessage) {
        throw new Error("EventEmitterAPP requires android bridge");
      }
      // ios app flow
    } else {
      this.webkit =
        options.webkitEvents || get("webkit.messageHandlers", window);

      if (!this.webkit) {
        throw new Error("EventEmitterAPP requires webkit events to exist");
      }
    }
  }

  dispatch = (message: FSA) => {
    const product = get("__TVG_GLOBALS__.PRODUCT", window);
    const webkitEvent =
      get(FD_BRIDGE_MESSAGE_EVT, this.webkit) || get(message.type, this.webkit);

    // iOS doesn't show any error message if we simply pass an error instance,
    // so we transform the error into a plain object with message and stack
    const errorObj = message.payload instanceof Error && {
      ...message,
      payload: APPEventEmitter.encodeError(message.payload)
    };

    // We need to avoid send invalid events to xsell
    if (!isXSell(product) || checkSBBridgeEvents(message.type)) {
      if (window && window.androidBridge) {
        attempt(() => {
          window.androidBridge.postMessage(JSON.stringify(errorObj || message));
        });
      } else if (webkitEvent) {
        webkitEvent.postMessage(errorObj || message);
      }
    }

    return super.dispatch(message);
  };

  static encodeError(error: Error) {
    return {
      message: error.message,
      stack: error.stack
    };
  }
}
