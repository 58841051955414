// @flow
import React, { PureComponent } from "react";
import type { CardSlot } from "@tvg-mar/promos-types/Component";

import Slot from "../../_molecules/Slot";
import ContentTitle from "../../_atoms/ContentTitle";

import { PromoSlotsWrapper, FeaturedSlotsContainer } from "./styled-components";

type Content = {
  header: string,
  slots: CardSlot[]
};

type Props = {
  content: Content,
  qaLabel: string
};

export default class FeaturedSlots extends PureComponent<Props> {
  static defaultProps = {
    qaLabel: "featured-slots"
  };

  render() {
    const { content, qaLabel } = this.props;

    return (
      <PromoSlotsWrapper data-qa-label={qaLabel}>
        <ContentTitle
          content={{
            header: content.header,
            className: "promoTopSlotsTitle"
          }}
          qaLabel={`${qaLabel}HeaderTitle`}
        />
        <FeaturedSlotsContainer data-qa-label={`${qaLabel}Container`}>
          {content.slots.map((slot) => (
            <Slot
              key={slot._uid}
              isTopPromo
              content={slot}
              qaLabel={`${qaLabel}Slot`}
            />
          ))}
        </FeaturedSlotsContainer>
      </PromoSlotsWrapper>
    );
  }
}
