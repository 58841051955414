// @flow
import React, { Fragment } from "react";
import { get } from "lodash";
import { type OptinTypeContext } from "@tvg-mar/promos-types/Context";
import tvgConf from "@tvg/conf";

import Icon from "../../_static/Icons";
import { errorOutline, optedInTag } from "../../_static/Icons/icons";
import {
  OptinSuccessContainer,
  OptinSuccessText,
  OptinErrorContainer,
  OptinErrorText
} from "./styled-components";

type Props = {
  type: "success" | "error",
  status?: OptinTypeContext,
  error?: string
};

export const renderSuccessOptin = (status: OptinTypeContext) => (
  <Fragment>
    {(status.optedIn === true || status.isOpted === true) && (
      <OptinSuccessContainer data-qa-label="optinSuccessContainer">
        <Icon
          icon={optedInTag}
          size={tvgConf().device === "mobile" ? 16 : 24}
          qaLabel="optinSuccessIcon"
        />
        <OptinSuccessText data-qa-label="optinSuccessText">
          {status.isOpted
            ? "You have been successfully opted-in"
            : "You’re already opted-in for this offer"}
        </OptinSuccessText>
      </OptinSuccessContainer>
    )}
  </Fragment>
);

const renderErrorOptin = (error: string) => {
  let text;

  switch (error) {
    case "PromotionNotFoundException":
      text = "This promotion is not available at the moment";
      break;
    case "NotEligibleException":
      text = "You are not eligible for this offer";
      break;
    case "UserOnBlackListException":
      text = "You are not eligible to participate in this promotion";
      break;
    case "NotInValidOptinRangeException":
      text = "This promotion is not available at the moment";
      break;
    default:
      text = "An error has occurred";
  }

  return (
    <OptinErrorContainer data-qa-label="optinErrorContainer">
      <Icon
        icon={errorOutline}
        size={tvgConf().device === "mobile" ? 16 : 24}
        view="0 0 16 16"
        qaLabel="optinErrorIcon"
      />
      <OptinErrorText qaLabel="optinErrorText">{text}</OptinErrorText>
    </OptinErrorContainer>
  );
};

const OptionNotification = (props: Props) => {
  switch (props.type) {
    case "success":
      return renderSuccessOptin(get(props, "status"));
    case "error":
    default:
      return renderErrorOptin(get(props, "error"));
  }
};

OptionNotification.defaultProps = {
  type: "error",
  error: "An error has occurred"
};

export default OptionNotification;
