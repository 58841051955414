// @flow
import type { UserInfo } from "@tvg/types/User";
import type { UserOptedInPromos } from "../types";

export type UserData = {
  error: boolean | null,
  hasRequested: boolean,
  logged: boolean,
  logging: boolean,
  user: UserInfo,
  wasLogin: boolean,
  userLogin: boolean,
  userLogout: boolean,
  loginPin: number,
  tvg3token: string,
  optedInPromos: {},
  returningUser: boolean
};

export type UserPriorLoginStatus = {
  type: "USER_PRIOR_LOGIN_STATUS",
  payload: {
    returningUser: boolean
  }
};

type UserSessionSuccessAction = {
  type: "USER_DATA_UPDATE",
  payload: UserData
};

export type UserPromosSuccess = {
  type: "USER_PROMOS_SUCCESS",
  payload: { optedInPromos: UserOptedInPromos }
};

export type UserPromosLoading = {
  type: "USER_PROMOS_LOADING"
};

export type UserPromosClear = {
  type: "USER_PROMOS_CLEAR"
};

export type UserActions =
  | UserSessionSuccessAction
  | UserPromosSuccess
  | UserPromosLoading
  | UserPromosClear
  | UserPriorLoginStatus;

export const getUserData = (userData: UserData): UserSessionSuccessAction => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const setPriorLoginStatus = (
  returningUser: boolean
): UserPriorLoginStatus => ({
  type: "USER_PRIOR_LOGIN_STATUS",
  payload: { returningUser }
});

export const successUserPromos = (
  userPromos: UserOptedInPromos
): UserPromosSuccess => ({
  type: "USER_PROMOS_SUCCESS",
  payload: { optedInPromos: userPromos }
});

export const loadingUserPromos = (): UserPromosLoading => ({
  type: "USER_PROMOS_LOADING"
});

export const clearUserPromos = (): UserPromosClear => ({
  type: "USER_PROMOS_CLEAR"
});
