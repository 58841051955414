// @flow
import React, { PureComponent } from "react";
import type { BannerItem } from "@tvg-mar/promos-types/Component";

import PromosBannerContent from "../../_molecules/PromosBannerContent";
import {
  PromosBannerContainer,
  PromosBannerImageWrapper,
  PromosBannerImage,
  PromosMobileImage
} from "./styled-components";

type Props = {
  content: BannerItem,
  qaLabel: string
};

export default class PromosBanner extends PureComponent<Props> {
  static defaultProps = {
    content: {
      config: {
        path: {
          id: "",
          url: "",
          cached_url: "",
          linktype: "url"
        },
        plugin: "banner_plugin",
        segment: "all",
        ctaLabel: "",
        forOptin: false,
        promoId: "",
        promoCode: "",
        displayCTA: false,
        linkLabel: "",
        enablePromoCode: false
      },
      categoryTitle: "",
      categoryTitleColor: "",
      title: "",
      titleColor: "",
      description: "",
      descriptionColor: "",
      secondDescription: "",
      component: "banner",
      pictureAlt: "",
      pictureUrl: "",
      mobilePictureUrl: "",
      backgroundColor: "",
      isMobilePersistent: false
    },
    qaLabel: "promosBanner"
  };

  render() {
    const { content, qaLabel } = this.props;
    return (
      <PromosBannerContainer
        data-qa-label={qaLabel}
        backgroundColor={content.backgroundColor}
      >
        <PromosBannerImageWrapper data-qa-label={`${qaLabel}ImageWrapper`}>
          <PromosBannerImage
            data-qa-label={`${qaLabel}Image`}
            src={content.pictureUrl}
            alt={content.pictureAlt}
          />
          <PromosMobileImage
            data-qa-label={`${qaLabel}MobileImage`}
            src={content.mobilePictureUrl}
            alt={content.pictureAlt}
          />
        </PromosBannerImageWrapper>
        <PromosBannerContent
          qaLabel={`${qaLabel}Content`}
          promosBannerItem={content}
        />
      </PromosBannerContainer>
    );
  }
}
