// @flow
import React, { type Node } from "react";
import { type RouterHistory } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import { get } from "lodash";
import mediator from "@tvg/mediator";
import TvgConf from "@tvg/conf";
import gtmUtils from "@tvg-mar/utils/gtmEventHandlers";
import type { Origin } from "@tvg-mar/promos-types/GTMHandlers";
import pathUtils from "@tvg-mar/utils/pathHandlingUtils";

type Props = {
  url: string,
  text?: string,
  itemProp?: string,
  history: RouterHistory,
  children?: Node | null,
  qaLabel: string
};

const handleClick = (
  event: SyntheticEvent<HTMLAnchorElement>,
  props: Props
): void => {
  const { url, history } = props;
  const linkLabel = get(props, "text");
  const tvgConf = new TvgConf();
  const { product } = tvgConf;
  const iosApp =
    product.indexOf("ios2") === 0 || product.indexOf("iosnative") === 0;
  const hash = get(history, "location.hash", "");
  const search = get(history, "location.search", "");
  const pathName = get(history, "location.pathname");
  let pageOrigin: Origin = "promo offer page";

  // Redirect for iOS app
  if (iosApp) {
    event.preventDefault();
    event.stopPropagation();

    if (product === "iosnative") {
      // $FlowFixMe - not recognizing proper event type
      pathUtils.handleExternalIOSNativeAppPath(url, event);
    } else {
      mediator.ios.dispatch({
        type: "OPEN_EXTERNAL_APP",
        payload: { openExternalApp: url }
      });
    }
  }

  if (
    (hash === "#promos" && search === "") ||
    /^\/promos(\/)?$/.test(pathName)
  ) {
    pageOrigin = "promo hub landing";
  }

  if (pathName === "/referral") {
    pageOrigin = "refer a friend";
  }

  gtmUtils.handleNavigationDataLayerUpdate(linkLabel, pageOrigin, url);
};

const ExternalLink = (props: Props) => {
  const { url, text, itemProp, children, qaLabel } = props;
  const isAbsolute: boolean = /http(s)?/.test(url);
  const target: string = isAbsolute ? "_blank" : "_self";

  return (
    <a
      data-qa-label={qaLabel}
      href={url}
      target={target}
      onClick={(event) => {
        handleClick(event, props);
      }}
      itemProp={itemProp}
    >
      {text}
      {children}
    </a>
  );
};

ExternalLink.defaultProps = {
  text: "",
  itemProp: "",
  children: null,
  qaLabel: "externalLink"
};

export default withRouter(ExternalLink);
