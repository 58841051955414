//  @flow
import tvgConf from "@tvg/conf";
import { get } from "lodash";
import mediator from "@tvg/mediator";
import queryString from "query-string";
import { type LocationShape, type Location, Link } from "react-router-dom";
import type {
  LinkType,
  ExternalRoute,
  InternalRoute,
  Path,
  Params
} from "@tvg-mar/promos-types/PathHandling";

const getRfrValue = (): void => {
  if (typeof window !== "undefined") {
    return queryString.parse(window.location.search).rfr;
  }
  return undefined;
};

const parseQueryParams = (path: string, query: "?" | "#"): Array<string> =>
  path.split(query);

const checkQueryParams = (params: string): boolean => {
  const queryParams = ["?", "#"];

  return queryParams.map((q) => params.includes(q))[1];
};

const queryParamType = (params: string): "?" | "#" | void => {
  const queryParams = ["?", "#"];

  return queryParams.find((q) => params.includes(q));
};

export const decodeURLParams = (url: string): Params => {
  const hashes = checkQueryParams(url)
    ? url
        .slice(0, url.indexOf("#"))
        .slice(url.indexOf("?") + 1)
        .split("&")
    : url.slice(url.indexOf("?") + 1).split("&");

  return hashes.reduce((params, hash) => {
    const split = hash.indexOf("=");

    if (split < 0) {
      return {};
    }

    const key = hash.slice(0, split).toLowerCase();
    const val = hash.slice(split + 1);

    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
};

const createStoryModalLocationObject = (
  url: string,
  origin: string = "promos"
) => ({
  pathname: "/",
  hash: "#promos",
  search: `?promo=${url}`,
  state: {
    showBackBtn: true,
    pageOrigin: origin
  }
});

const createFullStoryLocationObject = (
  url: string,
  origin: string = "promos"
) => ({
  pathname: url,
  hash: "",
  search: "",
  state: {
    showBackBtn: true,
    pageOrigin: origin
  }
});

const createUrlLocationObject = (url: string) => {
  let pathname;
  let pathArray;
  let params;
  let hash = "";
  let search = "";

  if (!url) {
    return {};
  }

  const query = queryParamType(url);

  switch (query) {
    case "?":
      pathArray = parseQueryParams(url, query);
      pathname = pathArray[0];

      if (pathArray[1].includes("#")) {
        params = parseQueryParams(pathArray[1], "#");
        search = `?${params[0]}`;
        hash = `#${params[1]}`;
      } else {
        search = `?${pathArray[1]}`;
      }
      break;
    case "#":
      pathArray = parseQueryParams(url, query);
      pathname = pathArray[0];
      hash = `#${pathArray[1]}`;
      break;
    default:
      pathname = url;
  }

  if (
    (pathname === "/registration" || pathname === "/signup") &&
    typeof window !== "undefined" &&
    tvgConf().product !== "tvg4"
  ) {
    const rfr = getRfrValue() ? `&rfr=${getRfrValue() || ""}` : "";
    const callback = `${window.location.host}${window.location.pathname}`;

    if (!rfr && !search) {
      search = `?callback=${callback}`;
    } else {
      search = `${search}${rfr}&callback=${callback}`;
    }
  }

  return {
    pathname,
    hash,
    search
  };
};

export const setInternalPath = (
  path: Path,
  originLocation: Location
): InternalRoute => {
  let locationObject: LocationShape;
  let pageOrigin: string;
  const { hash, search, pathname } = originLocation;
  const isModal = hash === "promos" || hash === "#promos";
  const { linktype } = path;
  const url = linktype === "story" ? path.cached_url : path.url;
  let isInternalButExternal = false;

  if (linktype === "story") {
    if (isModal) {
      pageOrigin = search === "" ? "promos" : search.replace("?promo=", "");
      locationObject = createStoryModalLocationObject(url, pageOrigin);
    } else {
      pageOrigin = pathname.replace("/", "");
      locationObject = createFullStoryLocationObject(
        url.charAt(0) === "/" ? url : `/${url}`,
        pageOrigin
      );
    }
  } else {
    locationObject = createUrlLocationObject(url);
    isInternalButExternal = true;
  }

  return {
    to: locationObject,
    as: Link,
    isInternalButExternal
  };
};

export const setExternalPath = (path: Path): ExternalRoute => {
  const { url } = path;
  let newUrl: string = url;
  let isInternal = false;
  const { brand } = tvgConf();
  const tvgDomainRegex =
    /^(https?:\/\/www|https?:\/\/www-qa|https?:\/\/www-staging|https?:\/\/)?\.?tvg\.com/i;

  // Handles re-branding TVG absolute paths for 4NjBets or PABets
  if (tvgDomainRegex.test(url) && brand !== "tvg" && brand !== "fdr") {
    newUrl = tvgConf().buildUrl({
      path: url.replace(tvgDomainRegex, "")
    });
    isInternal = true;
  }

  if (tvgDomainRegex.test(url) && brand === "tvg") {
    newUrl = tvgConf().buildUrl({
      path: url.replace(tvgDomainRegex, "")
    });
    isInternal = true;
  }

  const target = isInternal ? "_self" : "_blank";

  return {
    href: newUrl,
    as: "a",
    target
  };
};

export const handleExternalIOSAppPath = (
  event: Event,
  linkType: LinkType,
  url: string
): void => {
  mediator.ios.dispatch({
    type: "OPEN_EXTERNAL_APP",
    payload: { openExternalApp: url }
  });
};

export const handleExternalIOSNativeAppPath = (
  url: string,
  event: Event
): void => {
  event.preventDefault();
  event.stopPropagation();
  window.handleNativeMessages("OPEN_EXTERNAL_URL", {
    url
  });
};

export default {
  decodeURLParams,
  handleExternalIOSAppPath,
  handleExternalIOSNativeAppPath,
  setExternalPath,
  setInternalPath
};
