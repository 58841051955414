// @flow
import React, { PureComponent } from "react";
import Parser from "html-react-parser";
import type { RichTextDoc } from "@tvg-mar/promos-types/RichText";
import ContentNoteText from "./styled-components";
import { RenderRichText } from "../../_static/richTextEditorResolver";

export type Content = {
  note: string | RichTextDoc,
  className?: string
};

type Props = {
  content: Content,
  qaLabel: string
};

class ContentNote extends PureComponent<Props> {
  static defaultProps = {
    content: {
      note: "",
      className: undefined
    },
    qaLabel: "contentNote"
  };

  render() {
    const { content, qaLabel } = this.props;

    const listOfContents = [];
    if (typeof content.note !== "string") {
      content.note.content.forEach((blok) => listOfContents.push(blok));
    } else {
      return <ContentNoteText>{Parser(content.note)}</ContentNoteText>;
    }

    return (
      <ContentNoteText className={content.className} data-qa-label={qaLabel}>
        {listOfContents.map((blok) => RenderRichText(blok))}
      </ContentNoteText>
    );
  }
}

export default ContentNote;
