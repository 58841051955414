// @flow

import React, { type Element } from "react";
import styled from "styled-components";
import { has } from "lodash";
import type { IconType, IconShape } from "./type";

type Props = {
  /**
   * Size in px for the icon square
   */
  size: number,
  /**
   * Icon to render
   */
  icon: IconType,
  /**
   * Color do fill the icon
   */
  color: ?string,
  /**
   * Stroke color
   */
  stroke: ?string,
  /**
   * Classname to style the component
   */
  className: string,
  /**
   * a QA label for the icon
   */
  qaLabel?: ?string,
  /**
   * Color palette for icon colorizer
   */
  colorList?: string[],
  /**
   * View box parameters
   */
  /**
   * Color list for icon stroke for more than one path object
   */
  strokeList?: string[],
  /**
   * ViewBox size
   */
  view?: string
};

export const generatePaths = (icon: IconShape[]): Array<Element<*>> =>
  icon.map((shape: IconShape, index: number): Element<*> => (
    <path
      key={`path-${index.toString()}`}
      fill={shape.fill}
      fillRule={shape.fillRule}
      stroke={shape.strokeColor}
      strokeWidth={shape.strokeWidth}
      d={shape.path}
    />
  ));

const colorize = (
  colorList: string[],
  strokeList: string[],
  icon: IconShape[]
): IconShape[] => {
  const localIcon = icon.map((shape) => ({ ...shape }));

  if (colorList.length > 0) {
    colorList.forEach((color: string, index: number) => {
      if (color && localIcon[index]) {
        localIcon[index].fill = color;
      }
    });
  }

  if (strokeList.length > 0) {
    strokeList.forEach((stroke: string, index: number) => {
      if (stroke && localIcon[index] && has(localIcon[index], "strokeColor")) {
        localIcon[index].strokeColor = stroke;
      }
    });
  }

  return localIcon;
};

const IconSVG = styled.svg.attrs(({ size, qaLabel, viewBox }) => ({
  width: size,
  height: size,
  viewBox,
  "data-qa-label": qaLabel
}))`
  display: inline-block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  fill: ${(props) =>
    props.color !== "inherit" && props.colorList.length === 0 && props.color};
`;

const Icon = (props: Props) => (
  <IconSVG
    size={props.size}
    qaLabel={props.qaLabel}
    className={props.className}
    colorList={props.colorList}
    color={props.color}
    stroke={props.stroke}
    viewBox={props.view}
  >
    {generatePaths(
      props.colorList || props.strokeList
        ? colorize(
            props.colorList || [],
            props.strokeList || [],
            props.icon.shapes
          )
        : props.icon.shapes
    )}
  </IconSVG>
);

Icon.defaultProps = {
  size: 16,
  icon: null,
  color: "inherit",
  stroke: "inherit",
  className: "",
  qaLabel: null,
  view: "0 0 1024 1024",
  colorList: [],
  strokeList: []
};

export default Icon;
