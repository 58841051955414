// @flow
import React, { Component, Fragment } from "react";
import { type Location, Link, type RouterHistory } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import { get } from "lodash";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import { isFDR, isTvg5 } from "@tvg/utils/generalUtils";
import pathUtils, {
  handleExternalIOSAppPath,
  handleExternalIOSNativeAppPath
} from "@tvg-mar/utils/pathHandlingUtils";
import { handleNavigationDataLayerUpdate } from "@tvg-mar/utils/gtmEventHandlers";

import type { Route } from "@tvg-mar/promos-types/PathHandling";
import type { CardSlot } from "@tvg-mar/promos-types/Component";

import Context from "@tvg-mar/promos-context";

import OptedTag from "../../_atoms/OptedTag";
import Icon from "../../_static/Icons";
import PromoSlotMask from "../../_static/Masks/promoSlot";
import { chevronRight } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import {
  SlotImage,
  SlotContainer,
  SlotImageWrapper,
  SlotImageGradient,
  SlotImageShadow,
  SlotImageBackGround,
  SlotCopyWrapper,
  SlotDescription,
  SlotWrapper,
  SlotMetaTitle,
  SlotTitle,
  SlotMoreWrapper,
  SlotMore,
  SlotStatusWrapper,
  SlotCopyContent
} from "./styled-components";

const { UserContext } = Context;

type Props = {
  content: CardSlot,
  isTopPromo: boolean,
  location: Location,
  qaLabel: string,
  history: RouterHistory
};

class Slot extends Component<Props> {
  static defaultProps = {
    content: {
      component: "",
      _uid: "",
      categoryTitle: "",
      title: "",
      description: "",
      pictureAlt: "",
      pictureUrl: "",
      path: {
        url: "",
        cached_url: "",
        linktype: "story"
      },
      promoId: 0,
      segment: "acq"
    },
    isTopPromo: false,
    qaLabel: "slot"
  };

  static contextType = UserContext;

  handleGTMEvents = (event: Event, linkProps: Route): void => {
    const { promoId } = this.props.content;
    const linkLabel = promoId ? promoId.toString() : "";
    const pageOrigin = "promo hub landing";
    let urlLink;

    if (linkProps.as === Link) {
      const linkPath = get(linkProps, "to", "");
      const pathName = get(linkPath, "pathname", "/");
      const search = get(linkPath, "search", "");
      const hash = get(linkPath, "hash", "");
      urlLink = `${pathName}${search}${hash}`;
    } else {
      urlLink = get(linkProps, "href", "");
    }

    handleNavigationDataLayerUpdate(linkLabel, pageOrigin, urlLink);
  };

  renderPromoStyle = (
    isOpted: boolean,
    isTopPromo?: boolean,
    promoId?: number,
    qaLabel: string = "promo"
  ) => {
    const allPromosColorState = !isOpted ? "default" : "opted";
    const topPromosColorState = !isOpted
      ? buildColor("yellow", "500")
      : buildColor("green", "500");
    const topPromosBckgrdColorState = !isOpted
      ? buildColor("tint", "yellow")
      : buildColor("tint", "green");

    return (
      <Fragment>
        <SlotImageGradient
          maskColor={allPromosColorState}
          isTopPromo={isTopPromo}
          data-qa-label={`${qaLabel}ImageGradient`}
        />
        {!isTopPromo ? (
          <SlotImageShadow
            data-qa-label={`${qaLabel}ImageShadow`}
            maskColor={allPromosColorState}
          />
        ) : (
          <PromoSlotMask
            promoId={promoId}
            maskColor={topPromosColorState}
            bckgrdColor={topPromosBckgrdColorState}
            qaLabel={`${qaLabel}Mask`}
          />
        )}
      </Fragment>
    );
  };

  render() {
    const { content, isTopPromo, location, qaLabel, history } = this.props;
    const { isMobile, optedInPromos, isLogged } = this.context;
    const isOpted: boolean = get(optedInPromos, content.promoId, false);
    const linkType = content.path.linktype;
    const urlPath =
      linkType === "story" ? content.path.cached_url : content.path.url;

    const isFullPath: boolean = /http(s)?/.test(urlPath);
    let componentProps: Route;
    let originLocation: Location = {
      pathname: "/promos",
      hash: "",
      search: ""
    };

    if (location.hash === "#promos") {
      originLocation = {
        pathname: "/",
        hash: "promos",
        search: ""
      };
    }

    if (linkType === "story" || /http(s)?/.test(urlPath) === false) {
      componentProps = pathUtils.setInternalPath(content.path, originLocation);
    } else {
      componentProps = pathUtils.setExternalPath(content.path);
    }

    // $FlowFixMe - Flow is not recognising the prop
    const { isInternalButExternal, ...componentPropsRest } = componentProps;

    return (
      <SlotContainer
        data-qa-label={`${qaLabel}-${get(content, "promoId", "")}`}
        ismobile={isMobile}
        {...componentPropsRest}
        onClick={(event) => {
          if (isFDR() && (linkType === "story" || isInternalButExternal)) {
            event.preventDefault();
            event.stopPropagation();
            history.push(`/promos/${urlPath}`);
          } else if (
            (linkType === "story" || isInternalButExternal) &&
            isTvg5()
          ) {
            event.preventDefault();
            event.stopPropagation();
            history.push(urlPath.charAt(0) === "/" ? urlPath : `/${urlPath}`);
          } else if (
            (linkType === "story" || isInternalButExternal) &&
            tvgConf().product === "tvg4"
          ) {
            event.preventDefault();
            event.stopPropagation();
            mediator.base.dispatch({
              type: "TVG4_NAVIGATION",
              payload: {
                route: urlPath.charAt(0) === "/" ? urlPath : `/${urlPath}`
              }
            });
          } else {
            if (linkType === "story") {
              mediator.base.dispatch({
                type: "UPDATE_PROMOS_CONTENT",
                payload: { slug: urlPath }
              });
            }

            // $FlowFixMe - Flow is not recognising the prop
            if (isInternalButExternal) {
              if (tvgConf().product === "iosnative") {
                window.handleNativeMessages("NAVIGATE_FROM_WEBVIEW", {
                  url: urlPath,
                  isLogged
                });
              } else {
                mediator.base.dispatch({
                  type: "CLOSE_PROMOS_MODAL"
                });
              }
            }

            const isExternal = isFullPath && linkType === "url";
            if (isExternal && tvgConf().product === "ios2") {
              handleExternalIOSAppPath(event, linkType, urlPath);
            } else if (isExternal && tvgConf().product === "iosnative") {
              handleExternalIOSNativeAppPath(urlPath, event);
            }
          }

          this.handleGTMEvents(event, componentProps);
        }}
      >
        <SlotImageWrapper data-qa-label={`${qaLabel}ImageWrapper`}>
          <SlotImage
            src={content.pictureUrl}
            alt={content.pictureAlt}
            data-qa-label={`${qaLabel}Picture`}
          />
        </SlotImageWrapper>
        <SlotImageBackGround data-qa-label={`${qaLabel}ImageBackground`} />
        <SlotWrapper data-qa-label={`${qaLabel}Wrapper`}>
          {this.renderPromoStyle(isOpted, isTopPromo, content.promoId, qaLabel)}
          <SlotCopyWrapper data-qa-label={`${qaLabel}CopyWrapper`}>
            <SlotCopyContent data-qa-label={`${qaLabel}CopyContent`}>
              <SlotMetaTitle data-qa-label={`${qaLabel}CategoryTitle`}>
                {content.categoryTitle}
              </SlotMetaTitle>
              <SlotTitle data-qa-label={`${qaLabel}Title`}>
                {content.title}
              </SlotTitle>
              <SlotDescription data-qa-label={`${qaLabel}Description`}>
                {content.description}
              </SlotDescription>
            </SlotCopyContent>
            <SlotMoreWrapper data-qa-label={`${qaLabel}MoreWrapper`}>
              <SlotMore data-qa-label={`${qaLabel}More`}>Learn more</SlotMore>
              <SlotStatusWrapper data-qa-label={`${qaLabel}StatusWrapper`}>
                {isOpted && <OptedTag qaLabel={`${qaLabel}OptedTag`} />}
                <Icon
                  icon={chevronRight}
                  size={16}
                  color={buildColor("blue", "200")}
                  qaLabel={`${qaLabel}Icon`}
                />
              </SlotStatusWrapper>
            </SlotMoreWrapper>
          </SlotCopyWrapper>
        </SlotWrapper>
      </SlotContainer>
    );
  }
}

export default withRouter(Slot);
Slot.contextType = UserContext;
