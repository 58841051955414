import styled from "styled-components";

const OptinFooterBtnWrapper = styled.div`
  position: fixed;
  transform: translate3d(0, 0, 0);
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  min-height: calc(46px + constant(safe-area-inset-bottom));
  min-height: calc(46px + env(safe-area-inset-bottom));
  padding: 12px;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 101;

  > div {
    display: flex;

    &.promosOptInButton {
      margin: 0;

      button,
      a {
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

export default OptinFooterBtnWrapper;
