// @flow
import React from "react";
import { type PathTypeContext } from "@tvg-mar/promos-types/Context";

const DEFAULT_PATH: PathTypeContext = {
  currentSlug: "",
  setContent: () => {}
};

const pathContext = React.createContext(DEFAULT_PATH);

export default pathContext;
