// @flow
import React, { createElement, Component, Fragment } from "react";

import Context from "@tvg-mar/promos-context";
import type { Blok } from "@tvg-mar/promos-types/Template";

import ContentText from "../../_atoms/ContentText";
import ContentTitle from "../../_atoms/ContentTitle";
import ContentNote from "../../_atoms/ContentNote";
import PromosInfoContent from "../PromosInfoContent";
import InfoContent from "../InfoContent";
import LegalContent from "../../_static/PromosLegalContent";
import OptinButton from "../../_molecules/OptinButton";
import OptinNotification from "../../_molecules/OptinNotification";
import ImageGrid from "../ImageGrid";
import TableComponent from "../TableComponent";
import TableContent from "../TableContent";
import TextAndQuote from "../TextAndQuote";
import BorderBottom from "../../_atoms/BorderBottom";
import EligibleRaces from "../EligibleRaces";
import {
  PromosBodyContentContainer,
  PromosBodyContentWrapper
} from "./styled-components";

import Footer from "../../_atoms/Footer";

const { OptinContext } = Context;

type Content = {
  bloks: Blok[]
};

type Props = {
  content: Content,
  qaLabel: string
};

let COMPONENTS_DICTIONARY = Object.create(null);

COMPONENTS_DICTIONARY = {
  border_bottom: BorderBottom,
  content_title: ContentTitle,
  content_text: ContentText,
  content_note: ContentNote,
  info_section: PromosInfoContent,
  info_section_v2_0: InfoContent,
  legal_content: LegalContent,
  optin_button: OptinButton,
  image_grid: ImageGrid,
  table_content: TableComponent,
  table_content_v2_0: TableContent,
  text_and_quote: TextAndQuote,
  footer: Footer,
  eligible_races: EligibleRaces
};

class PromosBodyContent extends Component<Props> {
  static defaultProps = {
    qaLabel: "promosBodyContent",
    content: []
  };

  renderChildrenComponents = (bloks: Blok[]): createElement =>
    bloks.map((blok) => {
      if (typeof COMPONENTS_DICTIONARY[blok.component] !== "undefined") {
        return createElement(COMPONENTS_DICTIONARY[blok.component], {
          key: blok._uid,
          content: blok
        });
      }

      return createElement(
        "div",
        { key: blok._uid },
        `The component ${blok.component} has not been created yet.`
      );
    });

  render() {
    const { qaLabel } = this.props;

    return (
      <PromosBodyContentContainer data-qa-label={qaLabel}>
        <PromosBodyContentWrapper data-qa-label={`${qaLabel}ContentWrapper`}>
          <OptinContext.Consumer>
            {(context) => (
              <Fragment>
                <OptinNotification
                  type={context.error !== "" ? "error" : "success"}
                  status={context}
                  error={context.error}
                />
              </Fragment>
            )}
          </OptinContext.Consumer>
          {this.renderChildrenComponents(this.props.content.bloks)}
        </PromosBodyContentWrapper>
      </PromosBodyContentContainer>
    );
  }
}

export default PromosBodyContent;
