// @flow
import React from "react";
import type { Step } from "@tvg-mar/promos-types/Component";

import * as icons from "../../_static/Icons/icons";
import Icon from "../../_static/Icons";
import {
  StepContainer,
  StepContent,
  StepIcon,
  StepDescription,
  StepTitle
} from "./styled-components";

type Props = Step & { qaLabel: string };

const InfoStep = ({ iconName, stepDescription, stepTitle, qaLabel }: Props) => (
  <StepContainer data-qa-label={qaLabel}>
    <StepIcon data-qa-label={`${qaLabel}IconWrapper`}>
      {iconName && (
        <Icon
          icon={icons[iconName]}
          size={16}
          view="0 0 16 16"
          qaLabel={`${qaLabel}Icon`}
        />
      )}
    </StepIcon>
    <StepContent data-qa-label={`${qaLabel}Content`}>
      <StepTitle data-qa-label={`${qaLabel}Title`}>{stepTitle}</StepTitle>
      <StepDescription data-qa-label={`${qaLabel}StepDescription`}>
        {stepDescription}
      </StepDescription>
    </StepContent>
  </StepContainer>
);

InfoStep.defaultProps = {
  iconName: "contests",
  stepDescription: "",
  stepTitle: "",
  qaLabel: "infoStep"
};

export default InfoStep;
