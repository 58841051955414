// $FlowFixMe
import {
  useNavigate as useRouterNavigate,
  useLocation
} from "react-router-dom";

const useNavigate = () => {
  return {
    push: useRouterNavigate(),
    location: useLocation()
  };
};

export default useNavigate;
