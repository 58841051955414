// @flow
module.exports = {
  domain: {
    desktop: "www-staging.tvg.com",
    mobile: "www-staging.tvg.com",
    talentPicks: "www-staging.tvg.com/talent-picks/",
    accountWallet: "account.racing.fanduel.com",
    equibase: "equibase-store.tvg.com"
  }
};
