import { Brand, DEFAULTS, TYPE_LOGO, Dimension } from "./types";

const defaultDimensions = {
  [TYPE_LOGO.complete]: [DEFAULTS.widthComplete, DEFAULTS.height],
  [TYPE_LOGO.illustration]: [DEFAULTS.widthIllustration, DEFAULTS.height]
};

export const getDimensions = (
  brand: Brand,
  height: number,
  typeLogo: TYPE_LOGO
): Dimension => {
  const [defaultWidth, defaultHeight] = defaultDimensions[typeLogo];

  switch (brand) {
    case "iowa":
      return {
        dimension: {
          viewBox: `0 0 103 30`,
          height,
          width: (height * 103) / 30
        }
      };
    case "fdr":
      return {
        dimension: {
          viewBox: `0 0 140 40`,
          height: 30,
          width: 140
        }
      };
    case "4njbets":
      return {
        dimension: {
          viewBox: `0 0 ${defaultWidth} ${defaultHeight}`,
          height: height * 1.5,
          width: (defaultWidth / defaultHeight) * (height * 1.5)
        }
      };

    default:
      return {
        dimension: {
          viewBox: `0 0 112 34`,
          height: 34,
          width: 112
        }
      };
  }
};
