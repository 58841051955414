// @flow
import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import cookie from "react-cookie";

import pushToDataLayer from "../gtmUtils";

type PageViewType = {
  type: "PROMOS_PAGE_VIEW",
  payload: {
    siteVersion: string,
    productVersion: string,
    accountId: string,
    state: string,
    logged: string,
    registrationStatus: string,
    page: string,
    sport: string,
    privateBrowser: "Yes" | "No",
    graphVersion: "v1" | "v2"
  }
};

export default () => {
  mediatorChannels.base.subscribe("PROMOS_PAGE_VIEW", (data: PageViewType) => {
    const hasLoginOnce = cookie.load("hasLoginOnce");
    pushToDataLayer({
      event: "ga_pageload",
      customerStatus: hasLoginOnce ? "Returning" : "First Time",
      siteVersion: get(data, "payload.siteVersion"),
      productVersion: get(data, "payload.productVersion"),
      accountId: get(data, "payload.accountId"),
      residenceState: get(data, "payload.state"),
      loginStatus: get(data, "payload.logged"),
      registrationStatus: get(data, "payload.registrationStatus"),
      page: get(data, "payload.page"),
      sport: get(data, "payload.sport"),
      privateBrowser: get(data, "payload.privateBrowser"),
      graphVersion: get(data, "payload.graphVersion")
    });
  });
};
