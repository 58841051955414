// @flow
import styled from "styled-components";
import { fontNormal, fontBold } from "../../_static/Typography";

export const BannerContentContainer = styled.div`
  position: relative;
  display: flex;

  @media only screen and (hover: hover) and (pointer: fine) and (min-width: 900px) {
    width: 615px;
  }

  @media only screen and (hover: none) and (pointer: coarse) and (min-width: 1024px) {
    width: 473px;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    margin: ${({ isEditorial }) => (isEditorial ? "auto 0 auto 24px" : "0")};
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const BannerContentWrapper = styled.div`
  h1,
  h2,
  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h2,
  h3 {
    white-space: nowrap;
  }

  h1,
  h3 {
    max-height: ${({ hasSecondDescription }) =>
      hasSecondDescription ? "inherit" : "2.35em"};
    overflow: hidden;
  }

  h1 {
    letter-spacing: 0;
    text-transform: none;
    font-family: ${fontBold};
    font-weight: bold;
    line-height: 37px;
  }

  h2 {
    font-weight: normal;
    font-family: ${fontNormal};
    margin-bottom: 8px;
    line-height: 16px;
  }

  h3 {
    font-weight: normal;
    text-transform: none;
    margin: 12px 0 0;
    font-family: ${fontNormal};
    line-height: 24px;
  }

  a,
  button {
    height: 44px;

    span.span {
      font-size: 14px;
      font-weight: bold;
      color: #384048;
    }
  }

  @media only screen and (min-width: 1023px) {
    h1 {
      max-width: 423px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    h1 {
      max-width: 350px;
    }
  }

  @media only screen and (min-width: 768px) {
    h1 {
      max-height: ${({ isEditorial }) => (isEditorial ? "148px" : "74px")};
    }

    h2,
    h3 {
      max-width: 340px;
    }
  }

  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2,
    h3 {
      max-width: 190px;
    }

    h1 {
      font-size: 18px;
      line-height: 24px;
      max-height: ${({ isEditorial }) => (isEditorial ? "96px" : "48px")};
      max-width: 323px;
    }

    h2 {
      font-size: 14px;
      max-height: 16px;
      letter-spacing: 0.5px;
      margin-bottom: 4px;
    }

    h3 {
      margin: 12px 0 0;
      font-size: 16px;
      line-height: 19px;
    }

    a,
    button {
      width: 100%;
      height: 40px;
    }
  }
`;

export const BannerButtonWrapper = styled.div`
  margin-top: 21px;

  @media only screen and (max-width: 499px) {
    padding-bottom: 12px;
  }

  @media only screen and (max-width: 600px) {
    ${({ isMobilePersistent }) =>
      !isMobilePersistent &&
      `
      display: none;
    `}
  }
`;

export const SecondDescription = styled.span`
  display: block;
  width: 100%;
  margin-top: 8px;
`;
