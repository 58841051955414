import styled, { css } from "styled-components/native";
import { space } from "styled-system";
import { View } from "react-native";

import { Paragraph } from "../typography";

import { SaddleProps } from "./types";

export const StyledSaddle = styled(View)<
  Pick<
    SaddleProps,
    "all" | "saddleColor" | "fullHeight" | "isScratched" | "size"
  >
>`
  align-items: center;
  background-color: ${({ saddleColor }) => saddleColor};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  opacity: ${({ isScratched }) => (isScratched ? 0.4 : 1)};
  ${({ size, fullHeight, all }) => {
    if (fullHeight && size !== "s") {
      return css`
        flex-grow: 1;
        height: 100%;
        width: 32px;
      `;
    }
    if (all === true && size === "s") {
      return css`
        width: 44px;
        height: 20px;
      `;
    }

    return css`
      height: ${size === "s" ? "20px" : "44px"};
      width: ${size === "s" ? "20px" : "32px"};
    `;
  }}
  ${space};
`;

export const StyledParagraph = styled(Paragraph)<Pick<SaddleProps, "size">>`
  font-family: ${({ size, theme }) =>
    size === "s" ? theme.fonts.medium : theme.fonts.bold};
  font-size: ${({ size }) => (size === "s" ? "12px" : "18px")};
  text-align: center;
  line-height: ${({ size }) => (size === "s" ? "15px" : "22px")};
`;
