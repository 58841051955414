// @flow
import React, { Component, Fragment } from "react";
import { Link, type Location } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import { get } from "lodash";

import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import Button from "@tvg/atomic-ui/_atom/Buttons/button";
import Text from "@tvg/atomic-ui/_atom/Text";
import Context from "@tvg-mar/promos-context";
import pathUtils from "@tvg-mar/utils/pathHandlingUtils";
import gtmUtils from "@tvg-mar/utils/gtmEventHandlers";
import type { Origin } from "@tvg-mar/promos-types/GTMHandlers";
import type { Route, Path } from "@tvg-mar/promos-types/PathHandling";
import type { Segment } from "@tvg-mar/promos-types/Promos";

import { OptinButtonContainer, ButtonLink } from "./styled-components";

const { PromoContext, OptinContext, UserContext } = Context;

export type Content = {
  ctaLabel?: string,
  forOptin: boolean,
  linkLabel: string,
  path: Path,
  isForBanner: boolean,
  segment?: Segment,
  promoCode?: string,
  isMobilePersistent: boolean,
  requiresAuth: boolean,
  isUpperCase: boolean
};

type Props = {
  content: Content,
  location: Location,
  qaLabel: string
};

class OptinButton extends Component<Props> {
  static defaultProps = {
    content: {
      ctaLabel: "",
      isForBanner: false,
      segment: "all",
      promoCode: "",
      isMobilePersistent: false,
      forOptin: false,
      linkLabel: "",
      requiresAuth: false,
      path: {
        url: "/promos",
        cached_url: "/promos",
        linktype: "story"
      },
      isUpperCase: true
    },
    qaLabel: "optinButton"
  };

  static contextType = PromoContext;

  // $FlowFixMe
  buttonLinkRef: ?React.ElementRef<typeof ButtonLink>;

  verifyOptinStatus = (isModal: boolean) => (
    <OptinContext.Consumer>
      {({ isOpted }) => (
        <Fragment>
          {isOpted ? this.renderButtonLink(isModal, true) : this.renderButton()}
        </Fragment>
      )}
    </OptinContext.Consumer>
  );

  callLoginModal = (event: Event, linkProps: Route) => {
    const { product } = tvgConf();
    const { brand } = tvgConf();

    event.preventDefault();
    event.stopPropagation();

    if (product === "ios2" || product === "iosnative") {
      const externalUrl = get(linkProps, "href", "/promos");
      let brandParam = "";

      if (brand === "4njbets" || brand === "pabets") {
        brandParam =
          externalUrl.indexOf("?") < 0 ? `?brand=${brand}` : `&brand=${brand}`;
      }
      if (tvgConf().product === "iosnative") {
        window.handleNativeMessages("OPEN_LOGIN_MODAL_WEBVIEW", {
          currentUrl: window.location.href
        });
      } else {
        mediator.ios.dispatch({
          type: "OPEN_EXTERNAL_APP",
          payload: { openExternalApp: externalUrl + brandParam }
        });
      }
    } else {
      mediator.base.dispatch({
        type: "OPEN_LOGIN",
        payload: {
          callback: (error, success) => {
            if (
              get(success, "status", "fail") === "success" &&
              typeof window !== "undefined"
            ) {
              // eslint-disable-next-line security/detect-non-literal-fs-filename
              window.open(get(linkProps, "href", "/promos"), "_blank");
            }
          }
        }
      });
    }
  };

  handleClick = (event: Event, linkProps: Route, isLogged: boolean): void => {
    const { path, linkLabel } = this.props.content;
    const linkType = path.linktype;
    let urlLink;
    let pageOrigin: Origin = "promo offer page";

    if (linkProps.as === Link) {
      const linkPath = get(linkProps, "to", "");
      let pathName = get(linkPath, "pathname", "/");
      const search = get(linkPath, "search", "");
      const hash = get(linkPath, "hash", "");

      if (pathName === "/registration" || pathName === "/signup") {
        pathName = "/registration";
        mediator.base.dispatch({
          type: "CLOSE_PROMOS_MODAL"
        });
      }

      if (hash === "#promos" && search !== "") {
        pageOrigin = "promo hub landing";
      }
      urlLink = `${pathName}${search}${hash}`;
    } else {
      urlLink = get(linkProps, "href", "");
    }
    gtmUtils.handleSiteClickDataLayerUpdate({ linkLabel, urlLink });
    gtmUtils.handleNavigationDataLayerUpdate(linkLabel, pageOrigin, urlLink);

    if (tvgConf().product === "iosnative") {
      if (linkProps.isInternalButExternal && typeof window !== "undefined") {
        window.handleNativeMessages("NAVIGATE_FROM_WEBVIEW", {
          url: urlLink,
          isLogged
        });
      } else {
        pathUtils.handleExternalIOSNativeAppPath(urlLink, event);
      }
    } else {
      pathUtils.handleExternalIOSAppPath(event, linkType, urlLink);
    }
  };

  renderButtonLink = (isModal: boolean, isOpted = false) => {
    const { qaLabel, content } = this.props;
    const { linkLabel, path, requiresAuth } = content;
    const linkType = path.linktype;
    const isDesktop = tvgConf().product === "tvg4";
    const isIOSApp =
      tvgConf().product === "ios2" || tvgConf().product === "iosnative";
    let buttonLinkProps: Route;
    let buttonStyling: string;
    const isToReg =
      /^\/registration(\/)?/i.test(path.url) ||
      /^\/signup(\/)?/i.test(path.url);

    if (
      linkType === "story" ||
      (path.url.indexOf("http") < 0 && linkType === "url")
    ) {
      buttonLinkProps = pathUtils.setInternalPath(path, this.props.location);
      buttonStyling =
        !buttonLinkProps.isInternalButExternal || isToReg
          ? "marketingTVG"
          : "betTVG";
    } else {
      buttonLinkProps = pathUtils.setExternalPath(path);
      buttonStyling =
        buttonLinkProps.href.indexOf("/promos") > 0 || requiresAuth
          ? "marketingTVG"
          : "betTVG";
    }

    return (
      <UserContext.Consumer>
        {({ isLogged }) => (
          <ButtonLink
            ref={(buttonLinkRef) => {
              this.buttonLinkRef = buttonLinkRef;
            }}
            {...buttonLinkProps}
            className={buttonStyling}
            onClick={(event: Event) => {
              if (!isLogged && requiresAuth) {
                this.callLoginModal(event, buttonLinkProps);
              } else {
                this.handleClick(event, buttonLinkProps, isLogged);

                const url =
                  `${get(buttonLinkProps, "to.pathname")}${get(
                    buttonLinkProps,
                    "to.search"
                  )}` || get(buttonLinkProps, "href", "/promos");

                if (isOpted && isModal) {
                  if (
                    tvgConf().product === "iosnative" &&
                    typeof window !== "undefined"
                  ) {
                    window.handleNativeMessages("NAVIGATE_FROM_WEBVIEW", {
                      url,
                      isLogged
                    });
                  } else {
                    mediator.base.dispatch({
                      type: "CLOSE_PROMOS_MODAL"
                    });
                  }
                }

                if (isDesktop) {
                  // Don't want TVG4 Navigation if it's registration path
                  if (isToReg) {
                    event.preventDefault();
                    event.stopPropagation();

                    window.location.assign(url);
                  } else {
                    mediator.base.dispatch({
                      type: "TVG4_NAVIGATION",
                      payload: {
                        route: url
                      }
                    });
                  }
                }
              }
            }}
            data-qa-label={`${qaLabel}ButtonLink`}
          >
            <Text
              tag="span"
              normal
              fontSize={14}
              qaLabel={`${qaLabel}LinkLabel`}
            >
              {!isLogged && requiresAuth && !isIOSApp ? "Login" : linkLabel}
            </Text>
          </ButtonLink>
        )}
      </UserContext.Consumer>
    );
  };

  renderButton = () => {
    const { content, qaLabel } = this.props;
    const { ctaLabel } = content;

    return (
      <OptinContext.Consumer>
        {({ handleOptinClick }) => (
          <Button
            size="big"
            type="marketing"
            className="marketingTVG"
            hasRoundedCorners
            onClick={() => handleOptinClick()}
            qaLabel={`${qaLabel}ButtonCTA`}
          >
            {ctaLabel}
          </Button>
        )}
      </OptinContext.Consumer>
    );
  };

  render() {
    const { content, qaLabel } = this.props;
    const {
      forOptin,
      isForBanner,
      isMobilePersistent,
      isUpperCase = true
    } = content;
    const isModal =
      get(this.props.location, "hash", "") === "#promos" ||
      tvgConf().product === "iosnative";
    const className = isForBanner ? "promosBannerBtn" : "promosOptInButton";
    return (
      <OptinButtonContainer
        isMobilePersistent={isMobilePersistent}
        className={className}
        isUpperCase={isUpperCase}
        data-qa-label={qaLabel}
      >
        {forOptin === true
          ? this.verifyOptinStatus(isModal)
          : this.renderButtonLink(isModal)}
      </OptinButtonContainer>
    );
  }
}

export default withRouter(OptinButton);
