// @flow
import React from "react";
import tvgConf from "@tvg/conf";

import { Container, Text } from "./styled-components";

type Props = {
  content: {
    text: string
  },
  qaLabel: string
};

const Footer = ({ content: { text }, qaLabel }: Props) =>
  tvgConf().product !== "tvg4" && (
    <Container data-qa-label={qaLabel}>
      <Text qaLabel={`${qaLabel}Text`}>{text}</Text>
    </Container>
  );

Footer.defaultProps = {
  content: {
    text: ""
  },
  qaLabel: "footer"
};

export default Footer;
