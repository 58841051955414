// @flow
import styled from "styled-components";
import { titleFontSecondary } from "@tvg/atomic-ui/_static/Typography";

export const PromosEvergreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 32px;

  span {
    text-align: center;
    padding: 0 35px;
    line-height: 1.1;
    font-weight: 500;
    font-family: ${titleFontSecondary};
  }

  h3 {
    text-align: center;
    margin-bottom: 32px;
  }

  @media only screen and (max-width: 599px) {
    align-items: flex-start;
    padding: 24px 12px;

    h3 {
      margin-bottom: 0;
      text-align: left;
    }
  }
`;

export const PromosEvergreenIconsContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  max-width: 1024px;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    width: 100%;
  }

  @media only screen and (max-width: 599px) {
    height: 372px;
    flex-flow: column;
    justify-content: space-between;
  }
`;
