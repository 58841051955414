// @flow

import type { User } from "@tvg-mar/promos-types/Promos";
import type { Dispatch } from "redux";
import { get } from "lodash";
import promoService from "@tvg/api/pro";

import type { UnaryFn } from "@tvg/types/Functional";

import type { LeaderBoard, StandingsData, Standing, Content } from "./types";
import {
  setOverallLeaderBoardStandingsAction,
  setLeaderBoardStandingsPageAction,
  setUserLeaderBoardStandingsAction,
  setLeaderBoardAllRacesAction,
  type PromosActions
} from "./actions";

type State = {
  [string]: boolean
};

export const getAllLeaderBoardStandings = (
  content: Content,
  dispatch: Dispatch<PromosActions>,
  setState: UnaryFn<State, void>
) => {
  const promoId: number = get(content, "promo_type.promo_id");
  const numberVisibleStandings: number = get(
    content,
    "standings[0].numberVisibleStandings",
    20
  );

  if (promoId === undefined) return;

  promoService
    .fetchAllLeaderBoardStandings(Number(promoId), numberVisibleStandings)
    .then((resp) => {
      const standingsData: LeaderBoard = get(resp, "data", {});

      dispatch(setOverallLeaderBoardStandingsAction(standingsData));
      setState({ isLoadingStandingsError: false });
    })
    .catch((e) => {
      console.error(e);
      setState({ isLoadingStandingsError: true });
    });
};

export const getUserLeaderBoardStandings = (
  user: User,
  content: Content,
  dispatch: Dispatch<PromosActions>,
  setState: UnaryFn<State, void>
) => {
  const { accountNumber } = user;
  const promoId: number = +get(content, "promo_type.promo_id", 0);
  const numberNearbyUsers = get(content, "standings[0].numberNearbyUsers", 2);

  promoService
    .fetchUserLeaderBoardStandings(accountNumber, promoId, numberNearbyUsers)
    .then((resp) => {
      const standingsData: StandingsData[] = get(
        resp.data,
        "leaderBoardDataResponses"
      );

      dispatch(setUserLeaderBoardStandingsAction(standingsData));
      setState({ isLoadingStandingsError: false });
    })
    .catch((e) => {
      console.error(e);
      setState({ isLoadingStandingsError: true });
    });
};

export const getLeaderBoardStandingsByPagination = (
  content: Content,
  dispatch: Dispatch<PromosActions>,
  round: number,
  page: number,
  setState: UnaryFn<State, void>
) => {
  const promoId: number = +get(content, "promo_type.promo_id", 0);
  const pageSize: number = get(
    content,
    "standings[0].numberVisibleStandings",
    20
  );
  promoService
    .fetchLeaderBoardStandingsByPagination(promoId, round + 1, page, pageSize)
    .then((resp) => {
      const standingsData: Standing[] = get(resp.data, "entries");
      dispatch(setLeaderBoardStandingsPageAction(round, page, standingsData));
      setState({ isLoadingStandingsPageError: false });
    })
    .catch((e) => {
      console.error(e);
      setState({ isLoadingStandingsPageError: true });
    });
};

export const fetchLeaderboardAllRaces = (
  content: Content,
  dispatch: Dispatch<PromosActions>
) => {
  const promoId = get(content, "promo_type.promo_id");

  if (promoId === undefined) return;

  promoService
    .fetchAllRaces(promoId)
    .then((response) => {
      dispatch(setLeaderBoardAllRacesAction(response.data));
    })
    .catch((e) => {
      console.error(e);
      dispatch(setLeaderBoardAllRacesAction([]));
    });
};
