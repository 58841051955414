// @flow
import { combineReducers } from "redux";

import promosReducer, {
  type State as PromosState,
  initialState as initialPromosState
} from "@tvg/promos/src/reducers";

import capiReducer, {
  type State as CapiState,
  initialState as initialCapiState
} from "./App/capiReducer";

import appReducer, {
  type State as AppState,
  initialState as initialAppState
} from "./App/appReducer";

import userDataReducer, {
  type State as UserState,
  initialState as initialUserState
} from "./App/userDataReducer";

export type State = {
  capi: CapiState,
  app: AppState,
  userData: UserState,
  promos: PromosState
};

export const initialState = {
  capi: initialCapiState,
  app: initialAppState,
  userData: initialUserState,
  promos: initialPromosState
};

export default combineReducers({
  capi: capiReducer,
  app: appReducer,
  userData: userDataReducer,
  promos: promosReducer
});
