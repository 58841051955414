// @flow
import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const PromosBannerContainer = styled.div`
  display: flex;
  background-size: cover;
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor !== ""
      ? props.backgroundColor
      : buildColor("blue", "900")};
  position: relative;
  width: 1024px;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    width: 100%;
    padding: 4% 50px;
    height: 312px;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 4%;
  }

  @media only screen and (max-width: 499px) {
    padding-bottom: 0%;
  }
`;

export const PromosBannerImageWrapper = styled.div`
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const PromosBannerImage = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;

  @media only screen and (min-width: 768px) {
    max-width: 80%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const PromosMobileImage = styled.img`
  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;
