// @flow
import React from "react";

import type { Path } from "@tvg-mar/promos-types/PathHandling";
import Text from "@tvg/atomic-ui/_atom/Text";
import Title from "@tvg/atomic-ui/_atom/Title";

import InternalLink from "../../_atoms/InternalLink";
import ExternalLink from "../../_atoms/ExternalLink";
import buildColor from "../../_static/ColorPalette";
import {
  AcqOfferContainer,
  AcqOfferContent,
  AcqLink
} from "./styled-components";

type Props = {
  title: string,
  text: string,
  ctaLabel: string,
  path: Path,
  qaLabel: string
};

const AcquisitionOffer = ({ title, text, ctaLabel, path, qaLabel }: Props) => {
  const renderExternalLink = () => (
    <ExternalLink url={path.url} qaLabel={`${qaLabel}CTAExternalLink`}>
      {ctaLabel}
    </ExternalLink>
  );

  const renderInternalLink = () => (
    <InternalLink
      pathName={path.linktype === "story" ? path.cached_url : path.url}
      linkType={path.linktype}
      target="_self"
      qaLabel={`${qaLabel}CTAInternalLink`}
    >
      {ctaLabel}
    </InternalLink>
  );

  return (
    <AcqOfferContainer data-qa-label={qaLabel}>
      <AcqOfferContent data-qa-label={`${qaLabel}Content`}>
        <Title
          tag="h2"
          fontSize={18}
          uppercase={false}
          color={buildColor("blue_accent", "800")}
          qaLabel={`${qaLabel}Title`}
        >
          {title}
        </Title>
        <Text
          tag="span"
          fontSize={14}
          uppercase={false}
          color={buildColor("grey", "800")}
          qaLabel={`${qaLabel}Text`}
        >
          {text}
        </Text>
      </AcqOfferContent>
      <AcqLink data-qa-label={`${qaLabel}Link`}>
        {path.linktype === "story" ||
        (path.url.indexOf("http") < 0 && path.linktype === "url")
          ? renderInternalLink()
          : renderExternalLink()}
      </AcqLink>
    </AcqOfferContainer>
  );
};

AcquisitionOffer.defaultProps = {
  title: "",
  text: "",
  ctaLabel: "",
  path: {
    url: "",
    cached_url: "",
    linktype: "story"
  },
  qaLabel: "acquisitionOffer"
};

export default AcquisitionOffer;
