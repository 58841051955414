import styled, { css } from "styled-components/native";
import { View } from "react-native";
import { space, layout, compose } from "styled-system";

const styledUtils = compose(space, layout);

export const Container = styled(View)<{ showOnlySelections?: boolean }>`
  overflow: hidden;
  ${styledUtils}
  border-radius: ${({ theme }) => theme.radii.xs};
  ${({ showOnlySelections }) =>
    !showOnlySelections &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.blue["100"]};
    `}
`;
export const Header = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  background-color: ${({ theme }) => theme.colors.blue_accent["000"]};
  border-bottom-color: ${({ theme }) => theme.colors.blue["100"]};
  padding: ${({ theme }) =>
    `${theme.space["space-2"]} ${theme.space["space-4"]}`};
`;

export const ContentActionsWrapper = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Content = styled(View)<{ hasButtons: boolean }>`
  background-color: ${({ theme }) => theme.colors.white["900"]};
  padding: ${({ theme }) => theme.space["space-4"]};
  max-width: ${({ hasButtons }) => (hasButtons ? "calc(100% - 72px)" : "100%")};
`;

export const Actions = styled(View)`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.space["space-3"]};
  margin: ${({ theme }) =>
    `${theme.space["space-2"]} ${theme.space["space-4"]} ${theme.space["space-2"]} 0`};
`;

export const VerticalParagraphLeft = styled(View)`
  text-align: left;
`;

export const VerticalParagraphRight = styled(View)`
  text-align: right;
`;
