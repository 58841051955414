// @flow
import React, { Component } from "react";
import { get, has } from "lodash";

import tvgConf from "@tvg/conf";
import Context from "@tvg-mar/promos-context";
import ProcessingOverlay from "@tvg/atomic-ui/_static/ProcessingOverlay";
import type { User } from "@tvg-mar/promos-types/Promos";
import type { ContentProps, Blok } from "@tvg-mar/promos-types/Template";
import type { CardSlot } from "@tvg-mar/promos-types/Component";

import Components from "../../_dictionary";
import HomePageContainer from "./styled-components";

const { UserContext } = Context;

type Props = {
  content: ContentProps,
  user: User,
  mobile: boolean,
  isLoading: boolean,
  qaLabel: string
};

class HomePage extends Component<Props> {
  static defaultProps = {
    isLoading: false,
    qaLabel: "homePage"
  };

  static promotionDate(date: string): Date {
    const parsedDate = date.split(" ");
    const _date = parsedDate[0].split("-");
    const _time = parsedDate[1].split(":");
    const localDate = new Date(
      Number(_date[0]),
      Number(_date[1]) - 1,
      Number(_date[2]),
      Number(_time[0]),
      Number(_time[1])
    );

    const pacificDate = new Date(
      localDate.toLocaleString("en-US", { timeZone: "UTC" })
    );
    const localTimeOffset = localDate.getTime() - pacificDate.getTime();

    return new Date(localDate.getTime() + localTimeOffset);
  }

  intervalID: IntervalID;

  showAllBannerPresent: boolean;

  constructor(props: Props) {
    super(props);

    this.showAllBannerPresent = false;
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.intervalID = setInterval(() => {
        if (this.props.content) {
          this.forceUpdate();
        }
      }, 60000);
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.intervalID);
  }

  filterBannerLogic = (blok: Blok): Blok | null => {
    const { user } = this.props;
    const segment = get(blok, "config.segment", "");

    if (segment === "ret" && (user.isLogged || user.returningUser)) {
      return blok;
    }

    if (segment === "acq" && !user.returningUser && !user.isLogged) {
      return blok;
    }

    if (segment === "all") {
      this.showAllBannerPresent = true;
      return blok;
    }

    return null;
  };

  filterBanners = (blok: Blok): Blok | null => {
    if (get(blok, "startDate") && get(blok, "endDate")) {
      const start = HomePage.promotionDate(get(blok, "startDate"));
      const end = HomePage.promotionDate(get(blok, "endDate"));
      return start <= new Date(Date.now()) && new Date(Date.now()) <= end
        ? this.filterBannerLogic(blok)
        : null;
    }

    if (get(blok, "startDate")) {
      const start = HomePage.promotionDate(get(blok, "startDate"));
      return start <= new Date(Date.now())
        ? this.filterBannerLogic(blok)
        : null;
    }

    if (get(blok, "endDate")) {
      const end = HomePage.promotionDate(get(blok, "endDate"));
      return new Date(Date.now()) <= end ? this.filterBannerLogic(blok) : null;
    }
    return this.filterBannerLogic(blok);
  };

  filterSlots = (slots: CardSlot[]): CardSlot[] => {
    const { user } = this.props;
    const filteredSlots = [];
    slots
      .filter(({ startDate, endDate }) => {
        if (startDate && endDate) {
          const start = HomePage.promotionDate(startDate);
          const end = HomePage.promotionDate(endDate);
          return start <= new Date(Date.now()) && new Date(Date.now()) <= end;
        }

        if (startDate) {
          const start = HomePage.promotionDate(startDate);
          return start <= new Date(Date.now());
        }

        if (endDate) {
          const end = HomePage.promotionDate(endDate);
          return new Date(Date.now()) <= end;
        }
        return true;
      })
      .forEach((slot) => {
        if (slot.segment === "all") {
          filteredSlots.push(slot);
        }

        if (slot.segment === "acq" && !user.returningUser && !user.isLogged) {
          filteredSlots.push(slot);
        }

        if (slot.segment === "ret" && (user.returningUser || user.isLogged)) {
          const promoId: number = get(slot, "promoId");
          const eligiblePromo = has(
            user,
            `optedInPromos.${promoId.toString()}`
          );

          if (eligiblePromo) {
            filteredSlots.push(slot);
          }
        }
      });

    return filteredSlots;
  };

  toggleComponents(): Blok[] {
    const { content, mobile, user } = this.props;
    const result = [];
    let bannerToDisplay = null;

    content.body.forEach((blok) => {
      switch (blok.component) {
        case "banner": {
          if (!this.showAllBannerPresent) {
            const bannerComponent = this.filterBanners(blok);

            if (bannerComponent) {
              bannerToDisplay = bannerComponent;
            }
          }

          break;
        }
        case "all_promos":
        case "top_promos": {
          const slots = get(blok, "slots");

          if (slots && slots.length > 0) {
            const filteredSlots = this.filterSlots(slots);

            if (filteredSlots.length > 0) {
              result.push({
                header: get(blok, "header"),
                component: blok.component,
                slots: filteredSlots,
                _uid: blok._uid
              });
            }
          }

          break;
        }
        case "legal_content": {
          const legalsBannerLink = get(blok, "promoCode");

          if (bannerToDisplay !== null && legalsBannerLink) {
            const bannerPromoCode = get(bannerToDisplay, "config.promoCode");
            const bannerPromoId = get(bannerToDisplay, "config.promoId");

            if (
              bannerPromoCode === legalsBannerLink ||
              bannerPromoId === legalsBannerLink
            ) {
              result.push(blok);
            }
          }

          break;
        }
        case "seo_content": {
          if (
            (tvgConf().product !== "ios2" ||
              tvgConf().product !== "iosnative") &&
            !user.returningUser
          ) {
            result.push(blok);
          }
          break;
        }
        case "footer": {
          if (mobile) {
            result.push(blok);
          }

          break;
        }
        default:
          result.push(blok);
      }
    });

    // Insures that the correct banner component is set as the first component to render
    // on the array after parsing through all potential banner segment types
    if (bannerToDisplay !== null) {
      result.unshift(bannerToDisplay);
    }

    return result;
  }

  render() {
    const { user, isLoading, qaLabel = "homePage" } = this.props;
    const { isLogged, returningUser, optedInPromos } = user;
    const toggledContent = this.toggleComponents();

    return (
      <UserContext.Provider value={{ isLogged, returningUser, optedInPromos }}>
        <HomePageContainer data-qa-label={qaLabel} isLoading={isLoading}>
          {isLoading ? (
            <ProcessingOverlay qaLabel={`${qaLabel}ProcessingOverlay`} />
          ) : (
            toggledContent.map((blok) => Components(blok))
          )}
        </HomePageContainer>
      </UserContext.Provider>
    );
  }
}

export default HomePage;
