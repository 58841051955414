// @flow
import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const Separator = styled.div`
  height: 8px;

  @media only screen and (min-width: 1024px) {
    height: 12px;
  }
`;

export const ProcessingContainer = styled.div`
  height: 100vh;
`;

export const LeaderboardPageContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #102337;

  @media only screen and (max-width: 767px) {
    padding-bottom: ${({ addBottomMargin }) =>
      addBottomMargin ? "64px" : "0"};
  }
`;

export const LeaderboardContentStacked = styled.div`
  background-color: ${buildColor("blue", "000")};

  @media only screen and (min-width: 1024px) {
    display: none;
  }

  @media only screen and (min-width: 800px) and (max-width: 1023px) {
    width: 800px;
    margin: 0 auto;
    position: relative;
  }

  @media only screen and (min-width: 651px) and (max-width: 1023px) {
    > div {
      width: 100%;
    }

    > div:last-child {
      width: auto;
    }
  }
`;

export const LeaderboardContentGrid = styled.div`
  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    background-color: ${buildColor("blue", "000")};
  }

  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

const gridLayout = css`
  display: flex;
  flex-direction: column;
`;

export const LeaderboardBodyGrid = styled.div`
  width: 687px;
  padding: 12px;
  ${gridLayout}
`;

export const LeaderboardGlobalGrid = styled.div`
  padding: 12px 12px 12px 0;
  width: 325px;
  ${gridLayout}

  > div {
    margin-bottom: 12px;
  }

  > div:last-child {
    margin-bottom: 0;
  }
`;
