// @flow
import tvgConf from "@tvg/conf";
import type { SocialButtonType } from "@tvg-mar/promos-types/Component";

type SocialLinksUtils = {
  code: string,
  path: string,
  message?: string,
  hashTag?: string,
  subjectLine?: string,
  email?: string
};

const detectAndroidDevice = (): boolean => {
  if (typeof window !== "undefined") {
    const { userAgent } = navigator;

    return /Android/i.test(userAgent);
  }

  return false;
};

const setProductionBrandUrl = (): string => {
  const { brand } = tvgConf();
  let url;

  switch (brand) {
    case "pabets":
      url = "https://pabets.tvg.com";
      break;
    case "4njbets":
      url = "https://4njbets.us.betfair.com";
      break;
    case "fdr":
      url = "https://racing.fanduel.com";
      break;
    default:
      url = "https://www.tvg.com";
  }

  return url;
};

export const setLink = (code: string, path: string): string => {
  const domainUrl = setProductionBrandUrl();

  return `${domainUrl}${path}?rcode=${code}`;
};

export const setFaceBookLink = ({
  path,
  code,
  message = ""
}: SocialLinksUtils): string => {
  const domainUrl = setProductionBrandUrl();
  const referUrl = `${domainUrl}${path}?rcode=${code}&quote=${message}`;
  const encodedMsg = encodeURI(referUrl);
  // TODO: Move FB app id to conf
  return `https://www.facebook.com/dialog/share?app_id=1991295927575211&href=${encodedMsg}`;
};

export const setTextLink = ({
  path,
  code,
  message = ""
}: SocialLinksUtils): string => {
  const isAndroid = detectAndroidDevice();
  const referUrl = setLink(code, path);
  const encodedMsg = encodeURI(`${message} ${referUrl}`);

  // Note: Url scheme difference to enable within iOS or Android
  return `${isAndroid ? "sms:?&body=" : "sms:&body="}${encodedMsg}`;
};

export const setTwitterLink = ({
  path,
  code,
  message = "",
  hashTag = ""
}: SocialLinksUtils): string => {
  const referUrl = encodeURI(setLink(code, path));
  const twitterText = encodeURI(message);
  const twitterHashtags = hashTag !== "" ? hashTag : "";

  return `https://twitter.com/intent/tweet?text=${twitterText}&url=${referUrl}&hashtags=${twitterHashtags}`;
};

export const setEmailLink = ({
  path,
  code,
  message = "",
  subjectLine = "",
  email = ""
}: SocialLinksUtils): string => {
  const referUrl = setLink(code, path);
  const encodedMsg = encodeURI(`${subjectLine}&body=${message} ${referUrl}`);
  // Note: Url scheme requires user email to enable within TVG iOS app
  const userMailToReference =
    tvgConf().product === "ios2" || tvgConf().product === "iosnative"
      ? email
      : "";

  return `mailto:${userMailToReference}?subject=${encodedMsg}`;
};

export const copyToClipboard = (type: SocialButtonType): void => {
  if (type === "button") {
    const el: HTMLElement | null = document.getElementById("refer-input");

    if (el !== null && el instanceof HTMLInputElement) {
      el.select();
      document.execCommand("copy");
      el.blur();
    }
  }
};

export const setEmailReferralLink = (): string => {
  const { product } = tvgConf();
  return `${product === "iosnative" ? "" : "/referral"}#invite`;
};
