// @flow
import type { AllRaceContext } from "@tvg-mar/promos-types/Context";

import type {
  ReduxStory,
  Story,
  LeaderBoard,
  StandingsData,
  Standing
} from "./types";

export type SetPromosAction = {
  type: "SET_PROMOS",
  payload: ReduxStory
};

export type SetSinglePromoAction = {
  type: "SET_PROMO",
  payload: Story
};

export type SetOverallLeaderBoardStandings = {
  type: "SET_OVERALL_LEADERBOARD_STANDINGS",
  payload: LeaderBoard
};

export type SetLeaderBoardStandingsPage = {
  type: "SET_LEADERBOARD_STANDINGS_PAGE",
  payload: {
    round: number,
    page: number,
    standingsData: Standing[]
  }
};

export type SetUserLeaderBoardStandings = {
  type: "SET_USER_LEADERBOARD_STANDINGS",
  payload: StandingsData[]
};

export type SetLeaderboardAllRaces = {
  type: "SET_LEADERBOARD_ALL_RACES",
  payload: AllRaceContext[]
};

export type PromosActions =
  | SetPromosAction
  | SetSinglePromoAction
  | SetOverallLeaderBoardStandings
  | SetLeaderBoardStandingsPage
  | SetUserLeaderBoardStandings
  | SetLeaderboardAllRaces;

export const setPromosAction = (stories: ReduxStory): SetPromosAction => ({
  type: "SET_PROMOS",
  payload: stories
});

export const setSinglePromoAction = (story: Story): SetSinglePromoAction => {
  const promo = story;
  // since we are mapping homepage as just "promos" this is just a fallback to make sure it handles as it should
  if (promo.full_slug === "promos/") {
    promo.full_slug = "promos";
  }

  return {
    type: "SET_PROMO",
    payload: promo
  };
};

export const setOverallLeaderBoardStandingsAction = ({
  beginDate,
  endDate,
  optInBeginDate,
  optInEndDate,
  leaderBoardDataResponses
}: LeaderBoard): SetOverallLeaderBoardStandings => {
  return {
    type: "SET_OVERALL_LEADERBOARD_STANDINGS",
    payload: {
      beginDate,
      endDate,
      optInBeginDate,
      optInEndDate,
      leaderBoardDataResponses
    }
  };
};

export const setLeaderBoardStandingsPageAction = (
  round: number,
  page: number,
  standingsData: Standing[]
): SetLeaderBoardStandingsPage => ({
  type: "SET_LEADERBOARD_STANDINGS_PAGE",
  payload: {
    round,
    page,
    standingsData
  }
});

export const setUserLeaderBoardStandingsAction = (
  standingsData: StandingsData[]
): SetUserLeaderBoardStandings => ({
  type: "SET_USER_LEADERBOARD_STANDINGS",
  payload: standingsData
});

export const setLeaderBoardAllRacesAction = (
  races: AllRaceContext[]
): SetLeaderboardAllRaces => ({
  type: "SET_LEADERBOARD_ALL_RACES",
  payload: races
});
