// @flow
import React, { PureComponent } from "react";
import type { CardSlot } from "@tvg-mar/promos-types/Component";

import Slot from "../../_molecules/Slot";
import ContentTitle from "../../_atoms/ContentTitle";

import { PromoSlotsContainer, PromoSlotsWrapper } from "./styled-components";

type Content = {
  header: string,
  slots: CardSlot[]
};

type Props = {
  content: Content,
  qaLabel: string
};

export default class FeaturedSlots extends PureComponent<Props> {
  static defaultProps = {
    qaLabel: "promoSlots"
  };

  render() {
    const { content, qaLabel } = this.props;

    return (
      <PromoSlotsWrapper data-qa-label={qaLabel}>
        <ContentTitle
          content={{
            header: content.header,
            className: "promoAllSlotsTitle"
          }}
          qaLabel={`${qaLabel}Title`}
        />
        <PromoSlotsContainer data-qa-label={`${qaLabel}Container`}>
          {content.slots.map((slot) => (
            <Slot
              key={slot._uid}
              isTopPromo={false}
              content={slot}
              qaLabel={`${slot.promoId || ""}-${qaLabel}PromoSlot`}
            />
          ))}
        </PromoSlotsContainer>
      </PromoSlotsWrapper>
    );
  }
}
