// @flow
import React, { createElement, Fragment } from "react";
import { get } from "lodash";
import { v4 as generateUUID } from "uuid";
import Parser from "html-react-parser";
import StoryblokService from "@tvg-mar/storyblok-bridge";
import type {
  RichTextContentDetails,
  Blok
} from "@tvg-mar/promos-types/RichText";
import Text from "@tvg/atomic-ui/_atom/Text";

import OptinButton from "../../_molecules/OptinButton";
import ExternalLink from "../../_atoms/ExternalLink";
import InternalLink from "../../_atoms/InternalLink";

const storyblok = new StoryblokService();

let COMPONENTS_DICTIONARY = Object.create(null);

COMPONENTS_DICTIONARY = {
  optin_button: OptinButton
};

export const RenderRichTextInlineComp = (
  blok: RichTextContentDetails
): createElement => {
  const elem: Blok = get(blok, "attrs.body[0]", { component: blok });

  if (elem && typeof COMPONENTS_DICTIONARY[elem.component] !== "undefined") {
    return React.createElement(COMPONENTS_DICTIONARY[elem.component], {
      key: elem._uid,
      content: elem
    });
  }

  return React.createElement(
    () => (
      <Text tag="div">
        The component {elem.component} is not configured on rich text editor.
      </Text>
    ),
    { key: elem._uid }
  );
};

export const RenderRichTextParagraph = (blok: RichTextContentDetails): any => {
  // If rich text editor has an paragraph with no text we want to have br being rendered
  if (typeof blok.content === "undefined") {
    return "<br />";
  }
  return storyblok.richText(blok);
};

export const renderCodeBlok = (blok: RichTextContentDetails) => {
  const codeType = get(blok, "attrs.class");
  const uniqueId = generateUUID();

  if (typeof window !== "undefined" && codeType === "language-html") {
    const codeBlok = storyblok.richText(blok);
    const htmlBlok = document.createElement("textarea");

    htmlBlok.innerHTML = codeBlok;

    if (/<script[\s\S]*?>[\s\S]*?<\/script>/.test(htmlBlok.value) === false) {
      return <Fragment key={uniqueId}>{Parser(htmlBlok.value)}</Fragment>;
    }
  }

  return (
    <Text tag="div">Only Support for HTML with no script tags is enabled!</Text>
  );
};

export const RenderRichText = (
  blok: RichTextContentDetails
): string | createElement => {
  let renderBlok;
  const uniqueId = generateUUID();

  switch (blok.type) {
    case "heading":
    case "blockquote":
    case "ordered_list":
    case "bullet_list":
    case "horizontal_rule":
    case "paragraph":
      renderBlok = (
        <Fragment key={uniqueId}>
          {Parser(RenderRichTextParagraph(blok), {
            replace: (domNode) => {
              if (domNode.name === "a") {
                if (
                  domNode.attribs.linktype === "url" &&
                  /http(s)?/.test(domNode.attribs.href)
                ) {
                  return (
                    <ExternalLink
                      url={domNode.attribs.href}
                      text={domNode.children[0].data}
                    />
                  );
                }

                return (
                  <InternalLink
                    pathName={domNode.attribs.href}
                    linkType={domNode.attribs.linktype}
                    text={domNode.children[0].data}
                    target={domNode.attribs.target}
                  />
                );
              }
              return domNode;
            }
          })}
        </Fragment>
      );
      break;
    case "code_block":
      renderBlok = renderCodeBlok(blok);
      break;
    case "blok":
      renderBlok = RenderRichTextInlineComp(blok);
      break;
    default:
      renderBlok = (
        <Text tag="div" key={uniqueId}>
          {`Suppport not enabled for ${blok.type}`}
        </Text>
      );
  }

  return renderBlok;
};
