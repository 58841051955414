// @flow
import React from "react";

export type UserOptedInPromos = {
  [string]: boolean
};

export type User = {
  isLogged: boolean,
  returningUser: boolean,
  optedInPromos?: UserOptedInPromos,
  referCode?: string,
  userEmail?: string
};

const DEFAULT_OPTIN: UserOptedInPromos = {};

const DEFAULT_USER: User = {
  isLogged: false,
  returningUser: false,
  optedInPromos: DEFAULT_OPTIN
};

const userContext = React.createContext(DEFAULT_USER);

export default userContext;
