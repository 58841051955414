// @flow
import styled from "styled-components";

import buildColor from "../../_static/ColorPalette";
import { fontNormal } from "../../_static/Typography";

const MenuItemLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 48px;
  width: 100%;
  border-top: 1px solid ${buildColor("blue", "100")};

  a {
    padding: 0 12px;
    height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ focusOn }) =>
      focusOn ? buildColor("blue_accent", "000") : buildColor("white", "100")};

    span {
      line-height: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 38ch;
      font-family: ${fontNormal};
    }

    &:hover {
      background-color: ${buildColor("blue_accent", "000")};
    }

    &:active {
      background-color: ${buildColor("blue_accent", "100")};
    }
  }
`;

export default MenuItemLinkContainer;
