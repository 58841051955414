// @flow
import Mediator from "./Mediator";

let mediator = new Mediator();

// Attach mediator channels to the window to allow cross app communication
if (Mediator.isBrowser()) {
  if (!window.mediatorChannels) {
    window.mediatorChannels = mediator.channels;
  } else {
    mediator = {
      channels: window.mediatorChannels
    };
  }
}

export default mediator.channels;
