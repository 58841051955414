// @flow
import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const ProcessingContainer = styled.div`
  height: 100vh;
`;

export const SeoPageContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  background-color: #102337;

  @media only screen and (max-width: 650px) {
    margin-bottom: ${({ addBottomMargin }) =>
      addBottomMargin ? "112px" : "48px"};
  }
`;

export const SeoContentStacked = styled.div`
  @media only screen and (min-width: 1024px) {
    display: none;
  }

  @media only screen and (min-width: 800px) and (max-width: 1023px) {
    width: 800px;
    margin: 0 auto;
    position: relative;
  }

  @media only screen and (min-width: 651px) and (max-width: 1023px) {
    padding-bottom: 64px;
    border-bottom: 1px solid ${buildColor("blue", "100")};
    background-color: ${buildColor("white", "900")};

    > div {
      width: 100%;
    }

    > div:last-child {
      width: auto;
    }
  }
`;

export const SeoContentGrid = styled.div`
  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    background: ${buildColor("blue", "000")};
  }

  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

const gridLayout = css`
  display: flex;
  flex-direction: column;
`;

export const SeoBodyGrid = styled.div`
  width: 687px;
  padding: 12px;
  ${gridLayout}
`;

export const SeoGlobalGrid = styled.div`
  padding: 12px 12px 12px 0;
  width: 325px;
  ${gridLayout}

  > div {
    margin-bottom: 12px;
  }

  > div:last-child {
    margin-bottom: 0;
  }
`;

export const SeoMobileFooter = styled.div`
  transform: translate3d(0, 0, 0);
  bottom: 0;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 101;
  border-radius: 4px 4px 0 0;

  @media only screen and (max-width: 650px) {
    position: fixed;
    width: 100%;
    left: 0;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
    background-color: ${buildColor("white", "900")};
  }

  @media only screen and (min-width: 651px) and (max-width: 1023px) {
    position: fixed;
    right: 20px;
  }

  .promosOptInButton {
    width: 100%;
    padding: 12px;
    background-color: ${buildColor("white", "900")};
    border-top: 1px solid ${buildColor("blue", "100")};

    a {
      width: 100%;
    }

    @media only screen and (min-width: 651px) {
      display: none;
    }
  }
`;
