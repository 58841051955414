import React from "react";
import Svg, { Rect } from "react-native-svg";
import {
  Paragraph,
  LoadingMaskAnimation,
  buildColor
} from "@tvg/design-system";
import {
  CounterWrapper,
  LoadingMaskContainer,
  ValueText
} from "./styled-components";

interface Props {
  value: number | string;
  description: string;
  isCurrency?: boolean;
  isLoading?: boolean;
  maskWidth?: number;
  qaLabel?: string;
}

const ReferCounter = ({
  value,
  description,
  isCurrency,
  isLoading,
  maskWidth = 38,
  qaLabel = "referCounter"
}: Props) => (
  <CounterWrapper data-qa-label={qaLabel}>
    {isLoading ? (
      <LoadingMaskContainer
        maskWidth={maskWidth}
        data-qa-label={`${qaLabel}LoadingMaskContainer`}
      >
        <LoadingMaskAnimation>
          <Svg
            width={maskWidth}
            height="32"
            viewBox={`0 0 ${maskWidth} 32`}
            fill="none"
          >
            <Rect
              width={maskWidth}
              height="32"
              rx="2"
              fill={buildColor("blue", "000")}
            />
          </Svg>
        </LoadingMaskAnimation>
      </LoadingMaskContainer>
    ) : (
      <ValueText
        qaLabel={`${qaLabel}Value`}
        fontFamily="bold"
        color={isCurrency ? "green.700" : "grey.900"}
      >
        {value.toString()}
      </ValueText>
    )}
    <Paragraph
      qaLabel={`${qaLabel}Description`}
      fontFamily="condensedRegular"
      fontSize="xs"
      color="grey.800"
    >
      {description}
    </Paragraph>
  </CounterWrapper>
);

export default ReferCounter;
