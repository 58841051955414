import { useCallback, useMemo, useEffect } from "react";
import { Platform } from "react-native";

import { usePopperContext } from "../context/popperProvider";
import { ACTIONS } from "../context/popperReducer";
import { EventTypes, Layout } from "../types";

const isWeb = Platform.OS === "web";

const usePopper = () => {
  const [state, dispatch] = usePopperContext();

  useEffect(() => {
    if (Platform.OS === "web" && typeof window !== "undefined") {
      window.addEventListener("scroll", closeContent);
    }
    return () => window.removeEventListener("scroll", closeContent);
  }, []);

  const openContent = useCallback(
    (triggerLayout: Layout) => {
      dispatch({ type: ACTIONS.OPEN_POPPER, payload: triggerLayout });
    },
    [dispatch]
  );

  const closeContent = useCallback(() => {
    dispatch({ type: ACTIONS.CLOSE_POPPER });
  }, [dispatch]);

  const getHandlers = useCallback(
    (
      on: EventTypes = "press",
      callback: (fn: (layout: Layout) => void) => void
    ) => {
      const openCallback = () => callback(openContent);

      if (on === "hover" && !isWeb) return null;

      if (on === "hover" && isWeb) {
        return {
          onMouseEnter: openCallback,
          onMouseLeave: closeContent,
          onFocus: openCallback,
          onBlur: closeContent
        };
      }

      return { onPress: state.isOpen ? closeContent : openCallback };
    },
    [state.isOpen]
  );

  return useMemo(
    () => ({
      isWeb,
      isOpen: state.isOpen,
      triggerLayout: state.triggerLayout,
      openContent,
      closeContent,
      getHandlers
    }),
    [isWeb, state.isOpen, openContent, closeContent, getHandlers]
  );
};

export default usePopper;
