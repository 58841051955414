// @flow
// $FlowFixMe
import React, { useContext } from "react";
import { get } from "lodash";
import Title from "@tvg/atomic-ui/_atom/Title";
import Context from "@tvg-mar/promos-context";
import type { BannerItem, BannerConfig } from "@tvg-mar/promos-types/Component";

import buildColor from "../../_static/ColorPalette";
import NextRaceButton from "../NextRaceButton";
import OptinButton from "../OptinButton";
import {
  BannerContentWrapper,
  BannerContentContainer,
  BannerButtonWrapper,
  SecondDescription
} from "./styled-components";

const { PathContext } = Context;

type Props = BannerItem & { qaLabel: string };

type CTAButtonProps = {
  slug: string,
  config: BannerConfig,
  isMobilePersistent: boolean,
  showNextRaceBtn: boolean,
  raceNumber: number,
  trackCode: string,
  trackName: string,
  isOpted: boolean,
  qaLabel: string
};

type DescriptionProps = {
  description: string,
  descriptionColor?: string,
  secondDescription: string,
  hasSecondDescription?: boolean,
  qaLabel: string
};

const CallToActionButton = ({
  slug,
  config,
  isMobilePersistent = false,
  showNextRaceBtn = false,
  raceNumber = 0,
  trackCode = "",
  trackName = "",
  isOpted = false,
  qaLabel = "callToAction"
}: CTAButtonProps) => {
  const {
    ctaLabel,
    forOptin,
    linkLabel,
    path,
    segment,
    promoCode,
    displayCTA,
    requiresAuth
  } = config;

  const buttonProps = {
    ctaLabel,
    forOptin,
    linkLabel,
    path,
    isForBanner: true,
    segment,
    promoCode,
    requiresAuth,
    isMobilePersistent:
      displayCTA && isMobilePersistent ? true : isMobilePersistent,
    isUpperCase: false
  };

  return (
    <BannerButtonWrapper
      promosPage={slug === "promos"}
      isMobilePersistent={isMobilePersistent}
      data-qa-label={qaLabel}
    >
      {showNextRaceBtn && isOpted ? (
        <NextRaceButton
          trackCode={trackCode}
          trackName={trackName}
          raceNumber={raceNumber}
          isMobilePersistent={isMobilePersistent}
          qaLabel={`${qaLabel}NextRaceButton`}
        />
      ) : (
        <OptinButton qaLabel={`${qaLabel}OptinButton`} content={buttonProps} />
      )}
    </BannerButtonWrapper>
  );
};

const DescriptionField = ({
  description,
  descriptionColor,
  secondDescription,
  hasSecondDescription,
  qaLabel
}: DescriptionProps) => {
  const textColor =
    descriptionColor && descriptionColor !== ""
      ? descriptionColor
      : buildColor("yellow", "100");

  return (
    <Title tag="h3" fontSize={18} color={textColor} qaLabel={qaLabel}>
      {description}
      {hasSecondDescription && (
        <SecondDescription data-qa-label={`${qaLabel}SecondDescription`}>
          {secondDescription}
        </SecondDescription>
      )}
    </Title>
  );
};

DescriptionField.defaultProps = {
  descriptionColor: "",
  hasSecondDescription: false,
  qaLabel: "descriptionField"
};

const BannerContent = (props: Props) => {
  const {
    config,
    isMobilePersistent = false,
    categoryTitle,
    title,
    description,
    secondDescription,
    isOpted = false,
    isForReturningCustomers = false,
    isLogged = false,
    returningUser = false,
    isEligible = true,
    showNextRaceBtn = false,
    trackCode = "",
    trackName = "",
    raceNumber = 0,
    qaLabel = "banner"
  } = props;
  const { displayCTA } = config;
  const pathContext = useContext(PathContext);
  const hasSecondDescription = secondDescription && secondDescription !== "";
  const textSegmentColors = {
    optedStyling: {
      color: "green",
      categoryTitle: "500",
      description: "100"
    },
    promoStyling: {
      color: "yellow",
      categoryTitle: "500",
      description: "100"
    },
    editorialStyling: {
      color: "blue_accent",
      categoryTitle: "500",
      description: "000"
    }
  };

  const isEditorialBanner = get(config, "pageSelected", "edit") === "edit";

  const segmentStyling = {
    ret: isOpted
      ? textSegmentColors.optedStyling
      : textSegmentColors.promoStyling,
    acq: textSegmentColors.promoStyling,
    all: isEditorialBanner
      ? textSegmentColors.editorialStyling
      : textSegmentColors.promoStyling
  };

  const categoryTitleColor =
    props.categoryTitleColor && props.categoryTitleColor !== ""
      ? props.categoryTitleColor
      : buildColor(
          segmentStyling[config.segment].color,
          segmentStyling[config.segment].categoryTitle
        );
  const titleColor =
    props.titleColor && props.titleColor !== ""
      ? props.titleColor
      : buildColor("white", "100");
  const descriptionColor =
    props.descriptionColor && props.descriptionColor !== ""
      ? props.descriptionColor
      : buildColor(
          segmentStyling[config.segment].color,
          segmentStyling[config.segment].description
        );

  // Logic to handle the case of displaying CTA to only returning users
  const showCTA = isForReturningCustomers
    ? (isLogged || returningUser) && displayCTA
    : displayCTA;

  return (
    <BannerContentContainer
      data-qa-label={qaLabel}
      promosPage={pathContext.currentSlug === "promos"}
      isEditorial={isEditorialBanner}
    >
      <BannerContentWrapper
        hasSecondDescription={hasSecondDescription}
        isEditorial={isEditorialBanner}
        data-qa-label={`${qaLabel}Wrapper`}
      >
        <Title
          tag="h2"
          fontSize={14}
          color={categoryTitleColor}
          uppercase
          qaLabel={`${qaLabel}CategoryTitle`}
        >
          {categoryTitle}
        </Title>
        <Title
          tag="h1"
          fontSize={32}
          color={titleColor}
          uppercase={false}
          qaLabel={`${qaLabel}Title`}
        >
          {title}
        </Title>
        {description && description !== "" && (
          <DescriptionField
            description={description}
            descriptionColor={descriptionColor}
            secondDescription={secondDescription}
            qaLabel={`${qaLabel}Description`}
          />
        )}
        {showCTA && isEligible && (
          <CallToActionButton
            slug={pathContext.currentSlug}
            config={config}
            isMobilePersistent={isMobilePersistent}
            hasSecondDescription={hasSecondDescription}
            showNextRaceBtn={showNextRaceBtn}
            trackCode={trackCode}
            raceNumber={raceNumber}
            trackName={trackName}
            isOpted={isOpted}
            qaLabel={`${qaLabel}CallToActionButton`}
          />
        )}
      </BannerContentWrapper>
    </BannerContentContainer>
  );
};

BannerContent.defaultProps = {
  content: {
    config: {
      path: {
        id: "",
        url: "",
        cached_url: "",
        linktype: "url"
      },
      plugin: "banner_plugin",
      segment: "all",
      ctaLabel: "",
      forOptin: false,
      promoId: "",
      promoCode: "",
      displayCTA: false,
      linkLabel: "",
      enablePromoCode: false,
      requiresAuth: false,
      displaySocialLinks: false
    },
    categoryTitle: "",
    categoryTitleColor: "",
    title: "",
    titleColor: "",
    description: "",
    descriptionColor: "",
    secondDescription: "",
    isMobilePersistent: false,
    links: [],
    ismobile: false
  },
  qaLabel: "banner"
};

export default BannerContent;
