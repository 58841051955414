// @flow
import React from "react";
import type { StepLink } from "@tvg-mar/promos-types/Component";

import ContentTitle from "../../_atoms/ContentTitle";
import IconLink from "../../_molecules/IconLink";
import {
  PromosEvergreenContainer,
  PromosEvergreenIconsContainer
} from "./styled-components";

type Content = {
  iconLinks: StepLink[],
  header: string
};

type Props = {
  content: Content,
  qaLabel?: string
};

const PromosEvergreenContent = ({
  content,
  qaLabel = "promosEvergreen"
}: Props) => (
  <PromosEvergreenContainer data-qa-label={qaLabel}>
    <ContentTitle
      content={{ header: content.header }}
      qaLabel={`${qaLabel}HeaderTitle`}
    />
    <PromosEvergreenIconsContainer data-qa-label={`${qaLabel}Icons`}>
      {content.iconLinks.map((link) => (
        <IconLink key={link._uid} content={link} qaLabel={`${qaLabel}Icon`} />
      ))}
    </PromosEvergreenIconsContainer>
  </PromosEvergreenContainer>
);

export default PromosEvergreenContent;
