// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";

const ContainerSVG = styled.div`
  z-index: 25;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 4px 0 0;
  height: 112px;
  overflow: hidden;
  background-color: ${(props) => props.bckgrdColor};
  width: 100%;

  @media only screen and (min-width: 600px) {
    width: 100%;
  }

  @media only screen and (max-width: 599px) {
    top: 0;
  }
`;

const MaskSVG = styled.svg.attrs({
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  viewBox: "0 0 324 150"
})`
  width: 324px;
  height: 150px;

  @media only screen and (min-width: 600px) {
    width: 100%;
  }
`;

type Props = {
  promoId?: number,
  maskColor: string,
  bckgrdColor: string,
  qaLabel: string
};

export default class PromoSlotMask extends PureComponent<Props> {
  static defaultProps = {
    promoId: 0,
    qaLabel: "promoSlotMask"
  };

  render() {
    const {
      maskColor,
      bckgrdColor,
      promoId = 0,
      qaLabel = "promoSlotMask"
    } = this.props;

    return (
      <ContainerSVG bckgrdColor={bckgrdColor} data-qa-label={qaLabel}>
        <MaskSVG>
          <defs>
            <linearGradient
              x1="2.508%"
              y1="3.96030148%"
              x2="97.505%"
              y2="96.0396985%"
              id={`linearGradient-1-${promoId}`}
            >
              <stop stopColor={maskColor} stopOpacity="0" offset="0%" />
              <stop stopColor={maskColor} offset="100%" />
            </linearGradient>
            <linearGradient
              x1="0.51%"
              y1="0.967890425%"
              x2="99.504%"
              y2="98.2929721%"
              id={`linearGradient-2-${promoId}`}
            >
              <stop stopColor={maskColor} stopOpacity="0" offset="0%" />
              <stop stopColor={maskColor} offset="100%" />
            </linearGradient>
            <linearGradient
              x1="1.358%"
              y1="2.24096011%"
              x2="98.642%"
              y2="97.7221826%"
              id={`linearGradient-3-${promoId}`}
            >
              <stop stopColor={maskColor} stopOpacity="0" offset="0%" />
              <stop stopColor={maskColor} offset="100%" />
            </linearGradient>
            <linearGradient
              x1="0.407%"
              y1="0.821457527%"
              x2="99.593%"
              y2="99.1785425%"
              id={`linearGradient-4-${promoId}`}
            >
              <stop stopColor={maskColor} stopOpacity="0" offset="0%" />
              <stop stopColor={maskColor} offset="100%" />
            </linearGradient>
            <linearGradient
              x1="0.439%"
              y1="0.858314787%"
              x2="99.573%"
              y2="99.1536389%"
              id={`linearGradient-5-${promoId}`}
            >
              <stop stopColor={maskColor} stopOpacity="0" offset="0%" />
              <stop stopColor={maskColor} offset="100%" />
            </linearGradient>
            <linearGradient
              x1="2.167%"
              y1="3.48514167%"
              x2="97.844%"
              y2="93.5463547%"
              id={`linearGradient-6-${promoId}`}
            >
              <stop stopColor={maskColor} stopOpacity="0" offset="0%" />
              <stop stopColor={maskColor} offset="100%" />
            </linearGradient>
            <linearGradient
              x1="0.48%"
              y1="0.922067885%"
              x2="99.52%"
              y2="98.3955747%"
              id={`linearGradient-7-${promoId}`}
            >
              <stop stopColor={maskColor} stopOpacity="0" offset="0%" />
              <stop stopColor={maskColor} offset="100%" />
            </linearGradient>
            <linearGradient
              x1="6.468%"
              y1="9.80068299%"
              x2="93.532%"
              y2="82.3347746%"
              id={`linearGradient-8-${promoId}`}
            >
              <stop stopColor={maskColor} stopOpacity="0" offset="0%" />
              <stop stopColor={maskColor} offset="100%" />
            </linearGradient>
            <linearGradient
              x1="2.604%"
              y1="4.13761479%"
              x2="97.396%"
              y2="92.3469995%"
              id={`linearGradient-9-${promoId}`}
            >
              <stop stopColor={maskColor} stopOpacity="0" offset="0%" />
              <stop stopColor={maskColor} offset="100%" />
            </linearGradient>
            <linearGradient
              x1="2.329%"
              y1="3.40511085%"
              x2="97.671%"
              y2="93.3712424%"
              id={`linearGradient-10-${promoId}`}
            >
              <stop stopColor={maskColor} stopOpacity="0" offset="0%" />
              <stop stopColor={maskColor} offset="100%" />
            </linearGradient>
            <linearGradient
              x1="3.348%"
              y1="7.34892283%"
              x2="96.652%"
              y2="88.4303037%"
              id={`linearGradient-11-${promoId}`}
            >
              <stop stopColor={maskColor} stopOpacity="0" offset="0%" />
              <stop stopColor={maskColor} offset="100%" />
            </linearGradient>
          </defs>
          <g
            id="Components"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Components-Assets"
              transform="translate(-455.000000, -93.000000)"
              fillRule="nonzero"
            >
              <g id="green-svg" transform="translate(455.000000, 93.000000)">
                <g id="Group" opacity="0.7">
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="78.0024642 105.633186 68.7910506 110.911504 56.7814024 98.9250442 66.0062047 93.6467257"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="187.869993 82.3417699 178.658579 87.6200885 166.648931 75.6469912 175.873733 70.3686726"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="54.5722698 133.962389 45.3608563 139.254071 33.3645968 127.267611 42.5760103 121.989292"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.4"
                    points="189.409691 109.70885 185.058369 112.221062 172.057959 99.2457522 176.422669 96.7469027"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.3"
                    points="194.162673 114.452655 189.797963 116.951504 186.303517 113.463805 190.668227 110.964956"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.4"
                    points="246.981026 107.410442 242.616315 109.909292 236.939514 104.243451 241.304224 101.744602"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.2"
                    points="155.630045 128.122832 151.265335 130.621681 134.44915 113.824602 138.81386 111.325752"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="52.8451298 11.4920354 59.6331918 7.5900885 64.5066722 12.4541593 57.7186102 16.3427434"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="59.1645879 17.7859292 65.9526499 13.8973451 68.3893901 16.3293805 61.5879394 20.2179646"
                  />
                  <polygon
                    id="Path"
                    fill={`url(#linearGradient-1-${promoId})`}
                    opacity="0.5"
                    points="63.6096305 22.2357522 70.4110812 18.3338053 170.397762 118.154159 163.6097 122.042743"
                  />
                  <polygon
                    id="Path"
                    fill={`url(#linearGradient-2-${promoId})`}
                    points="76.9849243 0.0801769912 78.9128946 0.0801769912 171.401913 92.4039823 170.196932 93.0988496"
                  />
                  <polygon
                    id="Path"
                    fill={`url(#linearGradient-3-${promoId})`}
                    opacity="0.4"
                    points="36.5377145 90.3461062 37.7560846 89.6512389 72.0177231 123.846726 70.799353 124.541593"
                  />
                  <polygon
                    id="Path"
                    fill={`url(#linearGradient-4-${promoId})`}
                    opacity="0.4"
                    points="18.891431 10.6368142 20.1098011 9.9419469 137.448214 127.040442 136.229844 127.73531"
                  />
                  <polygon
                    id="Path"
                    fill={`url(#linearGradient-5-${promoId})`}
                    opacity="0.4"
                    points="197.134961 13.9240708 198.353331 13.2292035 307.002489 121.681947 305.797508 122.376814"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.3"
                    points="265.899234 114.679823 272.687296 110.791239 292.515935 130.581593 285.727873 134.470177"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.3"
                    points="86.2900586 128.176283 93.0915093 124.287699 112.906759 144.078053 106.118697 147.966637"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.3"
                    points="122.934883 28.2089381 129.722945 24.3069912 149.551583 44.0973451 142.763521 47.999292"
                  />
                  <g
                    opacity="0.5"
                    transform="translate(102.747871, 77.504425)"
                    fill={maskColor}
                    id="Path"
                  >
                    <polygon points="0.291429727 5.02442478 0.144154219 4.78389381 6.94560492 0.881946903 7.07949175 1.13584071" />
                    <polygon points="1.05458463 5.78610619 0.907309121 5.54557522 7.70875983 1.64362832 7.84264665 1.89752212" />
                    <polygon points="1.81773953 6.54778761 1.67046402 6.30725664 8.45852604 2.40530973 8.60580155 2.65920354" />
                    <polygon points="2.58089443 7.30946903 2.43361892 7.06893805 9.23506963 3.16699115 9.36895645 3.42088496" />
                    <polygon points="3.34404933 8.07115044 3.19677383 7.83061947 9.98483585 3.92867257 10.1321114 4.18256637" />
                    <polygon points="4.10720424 8.83283186 3.95992873 8.57893805 10.7479907 4.69035398 10.8952663 4.94424779" />
                    <polygon points="4.87035914 9.59451327 4.72308363 9.3539823 11.5111457 5.4520354 11.6584212 5.69256637" />
                    <polygon points="5.62012536 10.3561947 5.48623853 10.1023009 12.2743006 6.21371681 12.4215761 6.45424779" />
                    <polygon points="6.38328026 11.1178761 6.24939343 10.8639823 13.0374555 6.97539823 13.184731 7.2159292" />
                    <polygon points="7.14643516 11.8795575 7.01254834 11.6256637 13.8006104 7.73707965 13.9478859 7.97761062" />
                    <polygon points="7.90959006 12.6278761 7.77570324 12.3873451 14.5637653 8.49876106 14.7110408 8.73929204" />
                    <polygon points="8.67274496 13.4029204 8.53885814 13.1490265 15.3269202 9.26044248 15.4741957 9.50097345" />
                    <polygon points="9.43589987 14.1512389 9.30201304 13.910708 16.0900751 10.0221239 16.2373506 10.2626549" />
                    <polygon points="10.1990548 14.9129204 10.0651679 14.6723894 16.85323 10.7838053 17.0005055 11.0243363" />
                    <polygon points="10.9622097 15.6746018 10.8283228 15.4340708 17.6163849 11.5454867 17.7502717 11.7860177" />
                    <polygon points="11.7253646 16.4362832 11.5914777 16.1957522 18.3795398 12.3071681 18.5134266 12.5476991" />
                    <polygon points="12.4885195 17.1979646 12.3546326 16.9574336 19.1426947 13.0688496 19.2765815 13.3093805" />
                    <polygon points="13.2516744 17.959646 13.1043989 17.719115 19.9058496 13.830531 20.0397364 14.0710619" />
                    <polygon points="14.0148293 18.7213274 13.8809425 18.4807965 20.6690045 14.5922124 20.8028913 14.8327434" />
                    <polygon points="14.7779842 19.4830088 14.6307087 19.2424779 21.4321594 15.3538938 21.5660462 15.5944248" />
                    <polygon points="15.5411391 20.2446903 15.3938636 20.0041593 22.1953143 16.1155752 22.3292011 16.3561062" />
                    <polygon points="16.304294 21.0063717 16.1570185 20.7658407 22.9584692 16.8772566 23.092356 17.1177876" />
                    <polygon points="17.0674489 21.7680531 16.9201734 21.5275221 23.7216241 17.6389381 23.8555109 17.879469" />
                    <polygon points="17.8306038 22.5297345 17.6833283 22.2892035 24.484779 18.4006195 24.6186658 18.6411504" />
                    <polygon points="18.5937587 23.2914159 18.4464832 23.050885 25.2479339 19.1489381 25.3818207 19.4028319" />
                    <polygon points="19.3569136 24.0530973 19.2096381 23.8125664 26.0110888 19.9106195 26.1449756 20.1645133" />
                    <polygon points="20.1200685 24.8147788 19.972793 24.5742478 26.760855 20.6723009 26.9081305 20.9261947" />
                  </g>
                  <g
                    opacity="0.5"
                    transform="translate(187.096570, 29.398230)"
                    fill={maskColor}
                    id="Path"
                  >
                    <polygon points="0.385150504 13.4162832 0.251263679 13.1757522 21.9141519 0.761681416 22.0480388 1.00221239" />
                    <polygon points="2.15245659 15.180177 2.00518109 14.9262832 23.6680694 2.52557522 23.8153449 2.76610619" />
                    <polygon points="3.906374 16.930708 3.75909849 16.690177 25.4219868 4.27610619 25.5692623 4.51663717" />
                    <polygon points="5.6602914 18.6812389 5.52640458 18.440708 27.1759042 6.02663717 27.3231797 6.26716814" />
                    <polygon points="7.41420881 20.4317699 7.28032199 20.1912389 28.9432103 7.77716814 29.0770971 8.01769912" />
                    <polygon points="9.16812622 22.1823009 9.03423939 21.9417699 30.6971277 9.52769912 30.8310145 9.78159292" />
                    <polygon points="10.9354323 23.9328319 10.7881568 23.6923009 32.4510451 11.2915929 32.5983206 11.5321239" />
                    <polygon points="12.6893497 25.6967257 12.5420742 25.4561947 34.2049625 13.0421239 34.352238 13.2826549" />
                    <polygon points="14.4432671 27.4472566 14.3093803 27.2067257 35.9588799 14.7926549 36.1061554 15.0331858" />
                    <polygon points="16.1971845 29.1977876 16.0632977 28.9572566 37.726186 16.5431858 37.8600728 16.7837168" />
                    <polygon points="17.9511019 30.9483186 17.8172151 30.7077876 39.4801034 18.2937168 39.6139902 18.5342478" />
                    <polygon points="19.718408 32.7122124 19.5711325 32.4583186 41.2340208 20.0442478 41.3812963 20.2981416" />
                    <polygon points="21.4723254 34.4627434 21.3250499 34.2088496 42.9879382 21.8081416 43.1352137 22.0486726" />
                    <polygon points="23.2262428 36.2132743 23.092356 35.9727434 44.7418556 23.5586726 44.8891311 23.7992035" />
                    <polygon points="24.9801602 37.9638053 24.8462734 37.7232743 46.5091617 25.3092035 46.6430485 25.5497345" />
                    <polygon points="26.7340776 39.7143363 26.6001908 39.4738053 48.2630791 27.0597345 48.3969659 27.3002655" />
                    <polygon points="28.5013837 41.4648673 28.3541082 41.2243363 50.0169965 28.8102655 50.164272 29.0641593" />
                  </g>
                  <g
                    opacity="0.5"
                    transform="translate(216.896656, 116.256637)"
                    fill={maskColor}
                    id="Path"
                  >
                    <polygon points="1.08448328 13.5231858 0.937207774 13.2826549 22.600096 0.868584071 22.7473715 1.10911504" />
                    <polygon points="2.83840069 15.2737168 2.69112518 15.0331858 24.3540134 2.61911504 24.501289 2.85964602" />
                    <polygon points="4.59231809 17.0242478 4.45843127 16.7837168 26.1079309 4.36964602 26.2552064 4.61017699" />
                    <polygon points="6.3462355 18.7881416 6.21234867 18.5342478 27.8752369 6.12017699 28.0091238 6.3740708" />
                    <polygon points="8.10015291 20.5386726 7.96626608 20.2847788 29.6291543 7.87070796 29.7630412 8.12460177" />
                    <polygon points="9.86745899 22.2892035 9.72018349 22.0486726 31.3830718 9.63460177 31.5169586 9.87513274" />
                    <polygon points="11.6213764 24.0397345 11.4741009 23.7992035 33.1369892 11.3851327 33.2842647 11.6256637" />
                    <polygon points="13.3752938 25.7902655 13.2280183 25.5497345 34.8909066 13.1356637 35.0381821 13.3761947" />
                    <polygon points="15.1292112 27.5407965 14.9953244 27.3002655 36.6582127 14.8861947 36.7920995 15.1400885" />
                    <polygon points="16.8831286 29.3046903 16.7492418 29.0507965 38.4121301 16.6367257 38.5460169 16.8906195" />
                    <polygon points="18.6504347 31.0552212 18.5031592 30.8146903 40.1660475 18.4006195 40.2999343 18.6411504" />
                    <polygon points="20.4043521 32.8057522 20.2570766 32.5652212 41.9199649 20.1511504 42.0672404 20.3916814" />
                  </g>
                  <g
                    opacity="0.5"
                    transform="translate(0.000000, 38.752212)"
                    fill={maskColor}
                    id="Path"
                  >
                    <polygon points="0.133886825 13.4697345 3.26849658e-13 13.2292035 21.6628883 0.815132743 21.7967751 1.05566372" />
                    <polygon points="1.90119291 15.2202655 1.75391741 14.9797345 23.4168057 2.56566372 23.5640812 2.81955752" />
                    <polygon points="3.65511032 16.9841593 3.50783481 16.7302655 25.1707231 4.31619469 25.3179986 4.5700885" />
                    <polygon points="5.40902773 18.7346903 5.2751409 18.4941593 26.9246405 6.0800885 27.071916 6.32061947" />
                    <polygon points="7.16294513 20.4852212 7.02905831 20.2446903 28.6919466 7.83061947 28.8258334 8.07115044" />
                    <polygon points="8.93025122 22.2357522 8.78297571 21.9952212 30.445864 9.58115044 30.5797508 9.82168142" />
                    <polygon points="10.6841686 23.9862832 10.5368931 23.7457522 32.1997814 11.3316814 32.3470569 11.5722124" />
                    <polygon points="12.438086 25.750177 12.2908105 25.4962832 33.9536988 13.0822124 34.1009743 13.3361062" />
                    <polygon points="14.1920034 27.500708 14.0581166 27.260177 35.7210049 14.8461062 35.8548917 15.0866372" />
                    <polygon points="15.9459208 29.2512389 15.812034 29.010708 37.4749223 16.5966372 37.6088091 16.8371681" />
                    <polygon points="17.6998382 31.0017699 17.5659514 30.7612389 39.2288397 18.3471681 39.3627265 18.5876991" />
                    <polygon points="19.4671443 32.7523009 19.3198688 32.5117699 40.9827571 20.0976991 41.1300326 20.3382301" />
                    <polygon points="21.2210617 34.5028319 21.0737862 34.2623009 42.7366745 21.8482301 42.88395 22.1021239" />
                    <polygon points="22.9749791 36.2667257 22.8277036 36.0128319 44.4905919 23.6121239 44.6378674 23.8526549" />
                    <polygon points="24.7288966 38.0172566 24.5950097 37.7767257 46.257898 25.3626549 46.3917848 25.6031858" />
                    <polygon points="26.482814 39.7677876 26.3489271 39.5272566 48.0118154 27.1131858 48.1457022 27.3537168" />
                    <polygon points="28.25012 41.5183186 28.1028445 41.2777876 49.7657328 28.8637168 49.8996196 29.1042478" />
                  </g>
                  <g
                    opacity="0.5"
                    transform="translate(191.113175, 114.920354)"
                    fill={maskColor}
                    id="Path"
                  >
                    <polygon points="0.385150504 3.42088496 0.251263679 3.18035398 4.61597417 0.668141593 4.749861 0.922035398" />
                    <polygon points="1.02780726 4.06230088 0.893920439 3.82176991 5.25863093 1.32292035 5.39251775 1.56345133" />
                    <polygon points="1.68385271 4.70371681 1.5365772 4.46318584 5.90128769 1.96433628 6.0485632 2.20486726" />
                    <polygon points="2.32650946 5.34513274 2.17923396 5.10460177 6.54394445 2.60575221 6.69121996 2.84628319" />
                    <polygon points="2.96916622 5.98654867 2.82189072 5.7460177 7.18660121 3.24716814 7.33387672 3.48769912" />
                    <polygon points="3.61182298 6.64132743 3.47793616 6.38743363 7.82925797 3.88858407 7.97653347 4.12911504" />
                    <polygon points="4.25447974 7.28274336 4.12059292 7.04221239 8.48530341 4.53 8.61919023 4.78389381" />
                    <polygon points="4.8971365 7.92415929 4.76324968 7.68362832 9.12796017 5.18477876 9.26184699 5.42530973" />
                    <polygon points="5.53979326 8.56557522 5.40590644 8.32504425 9.77061693 5.82619469 9.91789244 6.06672566" />
                    <polygon points="6.1958387 9.20699115 6.0485632 8.96646018 10.4132737 6.46761062 10.5605492 6.70814159" />
                  </g>
                  <g
                    opacity="0.5"
                    transform="translate(45.521520, 0.000000)"
                    fill={maskColor}
                    id="Path"
                  >
                    <polygon points="0.696211489 4.91752212 0.548935982 4.67699115 7.35038669 0.78840708 7.48427351 1.02893805" />
                    <polygon points="1.94135896 6.17362832 1.80747214 5.91973451 8.59553416 2.03115044 8.72942098 2.27168142" />
                    <polygon points="3.18650643 7.41637168 3.05261961 7.16247788 9.84068163 3.27389381 9.97456845 3.51442478" />
                    <polygon points="4.4316539 8.65911504 4.29776708 8.40522124 11.0858291 4.51663717 11.2331046 4.75716814" />
                    <polygon points="5.67680137 9.90185841 5.54291455 9.66132743 12.3309766 5.75938053 12.4782521 6.01327434" />
                  </g>
                  <g
                    opacity="0.5"
                    transform="translate(299.906488, 84.185841)"
                    fill={maskColor}
                    id="Path"
                  >
                    <polygon points="1.0710946 4.15584071 0.923819092 3.91530973 7.71188111 0.0267256637 7.85915662 0.267256637" />
                    <polygon points="2.31624207 5.39858407 2.16896656 5.1580531 8.95702858 1.26946903 9.10430409 1.51" />
                    <polygon points="3.56138954 6.64132743 3.41411403 6.40079646 10.2155647 2.51221239 10.3494516 2.75274336" />
                    <polygon points="4.80653701 7.89743363 4.65926151 7.64353982 11.4607122 3.75495575 11.594599 3.99548673" />
                    <polygon points="6.05168448 9.14017699 5.90440898 8.88628319 12.7058597 4.99769912 12.8397465 5.23823009" />
                    <polygon points="7.29683196 10.3829204 7.16294513 10.1423894 13.9510072 6.24044248 14.084894 6.48097345" />
                    <polygon points="8.54197943 11.6256637 8.4080926 11.3851327 15.1961546 7.48318584 15.3434301 7.73707965" />
                    <polygon points="9.7871269 12.8684071 9.65324007 12.6278761 16.4413021 8.7259292 16.5885776 8.97982301" />
                    <polygon points="11.0322744 14.1111504 10.8983875 13.8706195 17.6864496 9.9820354 17.8337251 10.2225664" />
                    <polygon points="12.2908105 15.3538938 12.143535 15.1133628 18.931597 11.2247788 19.0788725 11.4653097" />
                    <polygon points="13.535958 16.5966372 13.3886825 16.3561062 20.1767445 12.4675221 20.32402 12.7080531" />
                    <polygon points="14.7811055 17.8393805 14.63383 17.5988496 21.4352807 13.7102655 21.5691675 13.9507965" />
                    <polygon points="16.0262529 19.0821239 15.8789774 18.8415929 22.6804281 14.9530088 22.814315 15.1935398" />
                    <polygon points="17.2714004 20.3382301 17.1241249 20.0843363 23.9255756 16.1957522 24.0594624 16.4362832" />
                  </g>
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="292.997928 80.7382301 299.78599 76.849646 304.65947 81.700354 297.858019 85.6023009"
                  />
                  <g
                    opacity="0.5"
                    transform="translate(168.697399, 122.938053)"
                    fill={maskColor}
                    id="Path"
                  >
                    <polygon points="0.548935982 4.77053097 0.401660475 4.53 7.20311118 0.641415929 7.336998 0.881946903" />
                    <polygon points="1.79408345 6.01327434 1.64680795 5.77274336 8.44825865 1.88415929 8.58214547 2.12469027" />
                    <polygon points="3.03923092 7.26938053 2.9053441 7.01548673 9.69340612 3.12690265 9.82729295 3.36743363" />
                    <polygon points="4.2843784 8.51212389 4.15049157 8.25823009 10.9385536 4.36964602 11.0858291 4.61017699" />
                    <polygon points="5.52952587 9.75486726 5.39563904 9.50097345 12.1837011 5.61238938 12.3309766 5.85292035" />
                    <polygon points="6.77467334 10.9976106 6.64078651 10.7570796 13.4288485 6.85513274 13.576124 7.09566372" />
                    <polygon points="8.03320949 12.240354 7.88593399 11.999823 14.673996 8.09787611 14.8212715 8.35176991" />
                    <polygon points="9.27835696 13.4830973 9.13108146 13.2425664 15.9325322 9.3539823 16.066419 9.59451327" />
                    <polygon points="10.5235044 14.7258407 10.3762289 14.4853097 17.1642909 10.5967257 17.3115665 10.8372566" />
                    <polygon points="11.7686519 15.9685841 11.6213764 15.7280531 18.4228271 11.839469 18.5567139 12.08" />
                    <polygon points="13.0137994 17.2113274 12.8799126 16.9707965 19.6679746 13.0822124 19.8018614 13.3227434" />
                    <polygon points="14.2589468 18.4540708 14.12506 18.2135398 20.913122 14.3249558 21.0470089 14.5654867" />
                    <polygon points="15.5040943 19.710177 15.3702075 19.4562832 22.1582695 15.5676991 22.305545 15.8082301" />
                    <polygon points="16.7492418 20.9529204 16.615355 20.7123894 23.403417 16.8104425 23.5506925 17.0643363" />
                    <polygon points="17.9943893 22.1956637 17.8605024 21.9551327 24.6485645 18.0531858 24.79584 18.2937168" />
                    <polygon points="19.2529254 23.4384071 19.1056499 23.1978761 25.8937119 19.2959292 26.0409874 19.549823" />
                    <polygon points="20.4980729 24.6811504 20.3507974 24.4406195 27.1522481 20.5520354 27.2861349 20.7925664" />
                  </g>
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="263.516049 40.1285841 259.151338 42.6274336 253.474537 36.9482301 257.839247 34.4493805"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="303.64193 24.6677876 310.443381 20.7792035 315.303473 25.6432743 308.515411 29.5318584"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="309.961388 30.9750442 316.74945 27.0864602 319.186191 29.5184956 312.398128 33.4070796"
                  />
                  <g
                    opacity="0.4"
                    transform="translate(240.996285, 0.000000)"
                    fill={maskColor}
                    id="Path"
                  >
                    <polygon points="0.629268077 4.4899115 0.495381252 4.24938053 7.17633381 0.414247788 7.31022064 0.654778761" />
                    <polygon points="1.84763818 5.7059292 1.71375136 5.46539823 8.39470392 1.64362832 8.54197943 1.88415929" />
                    <polygon points="3.07939697 6.93530973 2.94551015 6.69477876 9.62646271 2.85964602 9.76034953 3.10017699" />
                    <polygon points="4.31115576 8.16469027 4.16388025 7.92415929 10.8582215 4.08902655 10.9921083 4.32955752" />
                    <polygon points="5.52952587 9.38070796 5.39563904 9.14017699 12.0765916 5.31840708 12.2238671 5.55893805" />
                    <polygon points="6.76128466 10.6100885 6.62739783 10.3695575 13.3083504 6.53442478 13.4422372 6.77495575" />
                    <polygon points="7.99304345 11.8261062 7.84576794 11.5855752 14.5267205 7.76380531 14.673996 8.00433628" />
                    <polygon points="9.21141355 13.0554867 9.07752673 12.8149558 15.7584793 8.97982301 15.8923661 9.22035398" />
                  </g>
                  <g
                    opacity="0.4"
                    transform="translate(282.156216, 0.000000)"
                    fill={maskColor}
                    id="Path"
                  >
                    <polygon points="0.304818409 4.11575221 0.170931584 3.87522124 6.85188415 0.0534513274 6.99915965 0.293982301" />
                    <polygon points="1.5365772 5.34513274 1.40269037 5.10460177 8.08364293 1.26946903 8.21752976 1.51" />
                    <polygon points="2.76833599 6.57451327 2.62106048 6.3339823 9.31540172 2.49884956 9.44928855 2.73938053" />
                    <polygon points="3.98670609 7.79053097 3.85281927 7.56336283 10.5337718 3.72823009 10.6810473 3.96876106" />
                    <polygon points="5.21846488 9.0199115 5.08457806 8.77938053 11.7655306 4.94424779 11.8994174 5.18477876" />
                    <polygon points="6.45022367 10.249292 6.30294816 10.0087611 12.9839007 6.17362832 13.1311762 6.41415929" />
                    <polygon points="7.66859378 11.4653097 7.53470695 11.2247788 14.2156595 7.40300885 14.362935 7.64353982" />
                    <polygon points="8.90035257 12.6946903 8.75307706 12.4541593 15.4474183 8.61902655 15.5813051 8.85955752" />
                    <polygon points="10.1321114 13.910708 9.98483585 13.6835398 16.6657884 9.84840708 16.8130639 10.0889381" />
                    <polygon points="11.3504815 15.1400885 11.2165946 14.8995575 17.8975472 11.0777876 18.031434 11.3049558" />
                    <polygon points="12.5822403 16.369469 12.4349647 16.1289381 19.129306 12.2938053 19.2631928 12.5343363" />
                    <polygon points="13.8006104 17.5854867 13.6667235 17.3449558 20.3476761 13.5231858 20.4949516 13.7637168" />
                    <polygon points="15.0323691 18.8148673 14.8984823 18.5743363 21.5794349 14.7525664 21.7133217 14.9797345" />
                    <polygon points="16.2641279 20.0442478 16.1168524 19.8037168 22.8111937 15.9685841 22.9450805 16.209115" />
                    <polygon points="17.482498 21.2602655 17.3486112 21.0197345 24.0295638 17.1979646 24.1634506 17.4384956" />
                    <polygon points="18.7142568 22.489646 18.58037 22.249115 25.2613226 18.4139823 25.3952094 18.6545133" />
                  </g>
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="277.855328 54.6539823 273.477228 57.1528319 260.490206 44.1775221 264.854917 41.6786726"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="20.9934541 100.087611 16.6287437 102.58646 10.9519423 96.9072566 15.3166528 94.4084071"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="35.3193444 114.613009 30.9546339 117.111858 17.9542232 104.136549 22.3189337 101.637699"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="202.061996 149.743894 197.630342 145.320796 190.828892 149.209381 191.377827 149.743894"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.3"
                    points="153.380747 149.743894 143.111627 139.494602 138.746917 142.006814 146.512352 149.743894"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.4"
                    points="94.7650946 0.0801769912 100.388341 5.69256637 104.753052 3.19371681 101.633489 0.0801769912"
                  />
                  <polygon
                    id="Path"
                    fill={`url(#linearGradient-6-${promoId})`}
                    opacity="0.5"
                    points="122.185116 0.0801769912 111.47417 0.0801769912 228.250259 116.617434 235.038321 112.72885"
                  />
                  <polygon
                    id="Path"
                    fill={`url(#linearGradient-7-${promoId})`}
                    opacity="0.4"
                    points="139.443128 0.0801769911 137.528547 0.0801769911 236.725295 99.0853982 237.943665 98.390531"
                  />
                  <polygon
                    id="Path"
                    fill={maskColor}
                    opacity="0.5"
                    points="185.178867 0.0801769912 174.48131 0.0801769912 188.191321 13.7637168 194.979383 9.87513274"
                  />
                  <polygon
                    id="Path"
                    fill={`url(#linearGradient-8-${promoId})`}
                    opacity="0.5"
                    points="223.323224 -7.28306304e-13 212.625667 -7.28306304e-13 247.235411 34.5429204 254.023473 30.6409735"
                  />
                  <polygon
                    id="Path"
                    fill={`url(#linearGradient-9-${promoId})`}
                    opacity="0.4"
                    points="262.913558 0.0801769911 258.066855 0.0801769911 301.767515 43.6964602 304.846912 41.9325664"
                  />
                </g>
                <polygon
                  id="Path"
                  fill={maskColor}
                  points="296 73.7894737 286.884543 79 275 67.1973684 284.128707 62"
                />
                <polygon
                  id="Path"
                  fill={maskColor}
                  points="83 88.5377685 78.6725664 91 62 74.4622315 66.3274336 72"
                />
                <polygon
                  id="Path"
                  fill={`url(#linearGradient-10-${promoId})`}
                  points="25.8569211 0 21 0 69.9190168 49 73 47.2211221"
                />
                <polygon
                  id="Path"
                  fill={`url(#linearGradient-11-${promoId})`}
                  points="155.927888 0 151 0 184.869021 33 188 31.2491961"
                />
              </g>
            </g>
          </g>
        </MaskSVG>
      </ContainerSVG>
    );
  }
}
