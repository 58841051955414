// @flow

import mediatorChannels from "@tvg/mediator";
import pushToDataLayer from "../gtmUtils";

type ClickedLink = {
  payload: {
    clickedLink: string
  }
};

export default () => {
  mediatorChannels.base.subscribe("FOOTER_LINK_CLICK", (data: ClickedLink) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Navigated to",
      gaEventLabel: data.payload.clickedLink,
      tag: undefined,
      module: "Footer",
      microApp: "non-Microapp"
    });
  });
};
