// @flow
import React from "react";

import { ToolTipContainer, ToolTipText } from "./styled-components";

type Props = {
  backgroundColor: string,
  borderColor: string,
  textColor: string,
  text: string,
  enableTransition: boolean,
  qaLabel: string
};

const ToolTip = ({
  backgroundColor,
  borderColor,
  textColor,
  text,
  enableTransition,
  qaLabel
}: Props) => (
  <ToolTipContainer
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    enableTransition={enableTransition}
    data-qa-label={qaLabel}
  >
    <ToolTipText data-qa-label={`${qaLabel}Text`} textColor={textColor}>
      {text}
    </ToolTipText>
  </ToolTipContainer>
);

ToolTip.defaultProps = {
  backgroundColor: "",
  borderColor: "",
  textColor: "",
  text: "",
  enableTransition: false,
  qaLabel: "toolTip"
};

export default ToolTip;
