import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";

const LandingPageContainer = styled.div`
  ${({ isLoading }) => isLoading && `min-height: 100vh;`}
  width: 100%;
  max-width: 1024px;
  background-color: ${buildColor("blue", "900")};
  ${({ hasMargin }) =>
    hasMargin &&
    css`
      @media only screen and (max-width: 599px) {
        margin-bottom: calc(64px + constant(safe-area-inset-bottom));
        margin-bottom: calc(64px + env(safe-area-inset-bottom));
        overflow-x: hidden;
      }
    `};
`;

export default LandingPageContainer;
