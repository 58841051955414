// @flow
import React, { PureComponent } from "react";
// $FlowFixMe
import { Route, Routes, type Location } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import type { RouterHistory } from "react-router";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import queryString from "query-string";
import { attempt, get, isEmpty, bindAll } from "lodash";
import tvgConf from "@tvg/conf";
import { Containers } from "@tvg/atomic-ui/_static/BaseComponents";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import { getAccountNumber } from "@urp/store-selectors";

import type {
  TvgConf,
  Feature,
  FeatureToggles,
  MapFeatureToggles,
  Messages
} from "@tvg/conf/src/types";
import calculateFeatureOverride from "@tvg/utils/featuresUtils";
import {
  failFeaturesRequest,
  successFeaturesRequest,
  successMessagesRequest,
  failMessagesRequest
} from "../actions/capi";
import Promos from "./components/Promos";
import PromosOnboardingPreview from "./components/PromosOnboardingPreview";
import {
  getUserData,
  clearUserPromos,
  successUserPromos
} from "../actions/user";
import type { UserData } from "../actions/user";
import { MainContainer } from "./styled-components";

type Props = {
  isLogged: boolean,
  accountNumber: string,
  dispatch: Dispatch<*>,
  features: Feature[],
  featureToggles: MapFeatureToggles,
  messages: Messages,
  location: Location,
  history: RouterHistory,
  hasPromoBadge: boolean,
  hasPromosOptedIn: boolean,
  loadingPromos: boolean,
  gotPromos: boolean
};

type State = {
  initialRender: boolean
};

export class Main extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    bindAll(this, ["getFeatures"]);

    // $FlowFixMe
    this.tvg = tvgConf();

    this.state = {
      initialRender: false
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      mediatorClassic.subscribe("UPDATE_ROUTER", () => {
        const pathWithParameters = window.location.href.replace(
          window.location.origin,
          ""
        );

        if (this.props.location.pathname !== pathWithParameters) {
          this.props.history.replace(pathWithParameters);
        }
      });

      mediator.base.subscribe("TVG4_NAVIGATION", (data) => {
        mediatorClassic.dispatch("TVG4_NAVIGATION", data.payload);
      });

      mediator.base.subscribe("TVG_LOGIN:UPDATE_USER_PROMOS", (data) => {
        if (data.payload) {
          this.props.dispatch(successUserPromos(data.payload));
        }
      });

      mediator.base.subscribe("TVG_LOGIN:CLEAR_USER_PROMOS", () => {
        this.props.dispatch(clearUserPromos());
      });

      if (!isEmpty(this.props.features)) {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        this.props.features.forEach((toggle: Feature) => {
          let { enabled } = toggle;
          featureOverrides = calculateFeatureOverride(
            hasFeaturesOverrides,
            featureOverrides,
            toggle
          );
          if (get(featureOverrides, toggle.name)) {
            enabled = featureOverrides[toggle.name].enabled;
          }

          featureToggles[toggle.name] = enabled;
        });

        if (typeof window !== "undefined" && !isEmpty(featureOverrides)) {
          // $FlowFixMe
          this.setFeaturesOverrides(featureOverrides);
        }
        this.props.dispatch(successFeaturesRequest(featureToggles));
      } else {
        this.getFeatures();
      }
      mediatorClassic.subscribeWithPast(
        "TVG_LOGIN:USER_SESSION_UPDATE",
        (data: UserData) => {
          this.props.dispatch(getUserData(data));
        }
      );
    }
    this.getMessages();
    this.setInitialRender();
  }

  componentDidUpdate(): void {
    const currPath = get(this.props, "location.pathname", "");

    if (
      this.props.isLogged &&
      this.props.accountNumber &&
      this.props.accountNumber !== "" &&
      (!this.props.gotPromos || currPath.includes("promos"))
    ) {
      mediator.base.dispatch({
        type: "TVG_LOGIN:GET_USER_PROMOS"
      });
    }

    if (!this.props.isLogged && this.props.gotPromos) {
      mediator.base.dispatch({
        type: "TVG_LOGIN:CLEAR_USER_PROMOS"
      });
    }
  }

  setInitialRender = () => {
    this.setState({ initialRender: true });
  };

  getMessages = () => {
    const messageNamespaces = [
      "MobileWebApp",
      "Login",
      "Global",
      "InformationalPages",
      "BetTicket",
      "WagerRewards",
      "Homepage",
      "tutorials",
      "DepositLimits",
      "ResponsibleGaming",
      "FundingMethods",
      "tutorials",
      "RedirectEngine"
    ];

    return this.tvg
      .getMessages(messageNamespaces, true)
      .then((response) => this.props.dispatch(successMessagesRequest(response)))
      .catch((err: Error) => this.props.dispatch(failMessagesRequest(err)));
  };

  getFeaturesOverrides = () =>
    attempt(
      () => JSON.parse(window.localStorage.getItem("featureOverrides")),
      false
    );

  setFeaturesOverrides = (features: Feature) =>
    attempt(() =>
      window.localStorage.setItem("featureOverrides", JSON.stringify(features))
    );

  getFeatures = () =>
    typeof window !== "undefined" &&
    this.tvg
      .getFeatures()
      .then((response: FeatureToggles | false) => {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        if (response && Array.isArray(response.featureToggles)) {
          response.featureToggles.forEach((toggle: Feature) => {
            let { enabled } = toggle;
            featureOverrides = calculateFeatureOverride(
              hasFeaturesOverrides,
              featureOverrides,
              toggle
            );
            if (get(featureOverrides, toggle.name)) {
              enabled = featureOverrides[toggle.name].enabled;
            }

            featureToggles[toggle.name] = enabled;
          });
        }

        if (!isEmpty(featureOverrides)) {
          // $FlowFixMe
          this.setFeaturesOverrides(featureOverrides);
        }

        this.props.dispatch(successFeaturesRequest(featureToggles));
      })
      .catch((err: Error) => this.props.dispatch(failFeaturesRequest(err)));

  getPromosPage = () => {
    const path = get(this.props, "location.pathname");
    const parameters = get(this.props, "location.search");
    const currentPath = `${path}${parameters}`;
    const queryParameters = queryString.parse(parameters);

    return (
      <Containers.Page style={{ flex: 1 }}>
        <Promos
          isLogged={this.props.isLogged}
          currentPath={currentPath}
          promoPage={get(queryParameters, "promo", "promos")}
          router={{ history: this.props.history }}
          initialRenderComplete={this.state.initialRender}
        />
      </Containers.Page>
    );
  };

  tvg: TvgConf;

  render() {
    const Component = () => this.getPromosPage();
    return (
      <MainContainer>
        <Routes>
          <Route
            path="/promos/preview/:contentFolder/:contentId"
            element={<PromosOnboardingPreview />}
          />
          {/* $FlowFixMe */}
          <Route path="/promos/*" element={<Component />} />
        </Routes>
      </MainContainer>
    );
  }
}

export default connect(
  (store) => ({
    isLogged: get(store, "userData.logged"),
    accountNumber: getAccountNumber(store),
    gotPromos: get(store, "userData.gotPromos"),
    loadingPromos: get(store, "userData.loadingPromos")
  }),
  (dispatch: Dispatch<*>) => ({ dispatch })
)(withRouter(Main));
