// @flow
import styled from "styled-components";
import buildColor from "@tvg-mar/tvg-promos-atomic-ui/_static/ColorPalette";

const ErrorPage = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${buildColor("white", "100")};
  height: 100%;

  @media screen and (min-width: 1023px) {
    max-width: 800px;
    max-height: 712px;
    ${({ isDesktop }) => isDesktop && "min-height:70vh"};
    margin: auto;
    width: 100%;
  }

  @media screen and (max-width: 1023px) {
    ${({ isDektop, isModal }) =>
      !isDektop && !isModal && "height: calc(100vh - 48px)"}
  }

  @media screen and (min-width: 800px) and (max-width: 1023px) {
    margin: 40px 72px;
  }

  @media (min-aspect-ratio: 8/7) and (min-width: 800px) and (max-width: 1023px) {
    margin: 40px auto;
  }

  /* Portrait ratio */
  @media screen and (max-aspect-ratio: 7/8) and (min-width: 601px) and (max-width: 1023px) {
    margin: 40px 72px;
  }

  /* Landscape ratio */
  @media screen and (min-aspect-ratio: 8/7) and (min-width: 800px) and (max-width: 1023px) {
    margin: 40px auto;
  }
`;

export default ErrorPage;
