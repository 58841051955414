// @flow
import React from "react";
import {
  SuccessContainer,
  SuccessContent,
  SuccessText,
  SuccessTitle
} from "./styled-components";
import Icon from "../../_static/Icons";
import { promoOptedIn } from "../../_static/Icons/icons";

type Props = {
  optinTriggered: boolean,
  text: string,
  addBottomMargin?: boolean,
  borderTopRadius?: boolean,
  qaLabel: string
};

const SuccessNotification = ({
  optinTriggered,
  text,
  addBottomMargin,
  borderTopRadius,
  qaLabel
}: Props) => (
  <SuccessContainer
    data-qa-label={qaLabel}
    addBottomMargin={addBottomMargin}
    borderTopRadius={borderTopRadius}
  >
    <Icon
      icon={promoOptedIn}
      size={16}
      view="0 0 16 16"
      qaLabel={`${qaLabel}Icon`}
    />
    <SuccessContent data-qa-label={`${qaLabel}SuccessContent`}>
      <SuccessTitle data-qa-label={`${qaLabel}SuccessTitle`}>
        {optinTriggered ? "Successfully opted in" : "You’ve already opted in"}
      </SuccessTitle>
      {text && (
        <SuccessText data-qa-label={`${qaLabel}SuccessText`}>
          {text}
        </SuccessText>
      )}
    </SuccessContent>
  </SuccessContainer>
);

SuccessNotification.defaultProps = {
  addBottomMargin: false,
  borderTopRadius: false,
  qaLabel: "successNotification"
};

export default SuccessNotification;
