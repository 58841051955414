import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";

const HomePageContainer = styled.div`
  ${({ isLoading }) => isLoading && `min-height: 80vh;`}
  min-width: 100%;
  max-width: 1024px;
  background-color: ${buildColor("white", "100")};

  @media only screen and (max-width: 599px) {
    overflow-x: hidden;
  }
`;

export default HomePageContainer;
