// @flow
import React from "react";

type Platform = "ios" | "web";

export type Device = {
  platform: Platform,
  mobile: boolean
};

const DEFAULT_DEVICE: Device = {
  platform: "web",
  mobile: true
};

const deviceContext = React.createContext(DEFAULT_DEVICE);

export default deviceContext;
