import React from "react";
import { useSpring } from "@react-spring/native";
import { LoadingMaskAnimatioProps } from "./types";
import { StyledAnimatedContainer } from "./styled-components";
import { useQaLabel } from "../../hooks/useQaLabel";

export const LoadingMaskAnimation = ({
  children,
  qaLabel = "load-mask-animation",
  ...rest
}: LoadingMaskAnimatioProps) => {
  const viewTestProps = useQaLabel(qaLabel);

  const stylesSpringAnimation = useSpring({
    loop: { reverse: true },
    reset: true,
    to: { opacity: 1 },
    from: { opacity: 0.2 },
    config: { mass: 1, tension: 180, friction: 12, duration: 1000 }
  });

  return (
    <StyledAnimatedContainer
      {...rest}
      {...viewTestProps}
      style={stylesSpringAnimation}
    >
      {children}
    </StyledAnimatedContainer>
  );
};

export { LoadingMaskAnimatioProps };
export default LoadingMaskAnimation;
