// @flow
import React, { Fragment } from "react";
import { get, find, hasIn, isEqual } from "lodash";
import { type RouterHistory } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";

import Context from "@tvg-mar/promos-context";
import promoService from "@tvg/api/pro";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import ProcessingOverlay from "@tvg/atomic-ui/_static/ProcessingOverlay";
import type { TvgConf } from "@tvg/conf/src/types";
import type { User, Segment } from "@tvg-mar/promos-types/Promos";
import type { OptinTypeContext, Error } from "@tvg-mar/promos-types/Context";
import type { ContentProps } from "@tvg-mar/promos-types/Template";

import OptinFooterButton from "../../_molecules/OptinFooterButton";
import BackButton from "../../_molecules/BackButton";

import LandingPageContainer from "./styled-components";

import Components from "../../_dictionary";

const { UserContext, OptinContext, PromoContext, PathContext } = Context;

type Props = {
  content: ContentProps,
  user: User,
  promoId: number,
  promoType: Segment,
  promoCode: string,
  error: Error,
  isOpted: boolean,
  mobile: boolean,
  history: RouterHistory,
  isLoading: boolean,
  isDesktop: boolean,
  optinInfo: {
    promoId: string,
    state: string,
    error?: string
  },
  qaLabel: string
};

type State = OptinTypeContext;

/* eslint-disable react/no-unused-state */
export class Page extends React.Component<Props, State> {
  static defaultProps = {
    error: "",
    isOpted: false,
    promoCode: "",
    promoId: 0,
    isLoading: false,
    isDesktop: false,
    optinInfo: {
      promoId: "",
      state: ""
    },
    qaLabel: "landingPageContainer"
  };

  // eslint-disable-next-line react/sort-comp
  static contextType = PathContext;

  tvgConf: TvgConf;

  // $FlowFixMe
  compRef: ?React.ElementRef<typeof LandingPageContainer>;

  constructor(props: Props) {
    super(props);

    const { handleOptinClick } = this;

    this.state = {
      isOpted: false,
      optedIn: false,
      error: "",
      handleOptinClick,
      sentOptinEvents: false
    };
    this.tvgConf = tvgConf();
  }

  componentDidMount() {
    const isModal = get(this.props.history, "location.hash", "") === "#promos";

    // The following error and isOpted props permit to toggle state from Storybook
    this.setOptinError(this.props.error);
    this.setOptinState(this.props.isOpted);

    if (isModal) {
      this.scrollModalToTop();
    }

    if (
      this.tvgConf.product === "iosnative" &&
      get(this.props, "optinInfo.state", "") !== ""
    ) {
      this.handleReactNativeEvents(this.props.optinInfo);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const isModal = get(this.props.history, "location.hash", "") === "promos";

    // The following error and isOpted props permit to toggle state from Storybook
    if (
      this.props.error !== null &&
      this.props.error !== undefined &&
      this.props.error !== prevProps.error
    ) {
      this.setOptinError(this.props.error);
    }

    if (
      this.props.isOpted !== null &&
      this.props.isOpted !== undefined &&
      this.props.isOpted !== prevProps.isOpted
    ) {
      this.setOptinState(this.props.isOpted);
    }

    if (isEqual(this.props.content) !== isEqual(prevProps.content) && isModal) {
      this.scrollModalToTop();
    }

    if (
      !isEqual(prevProps.optinInfo, this.props.optinInfo) &&
      get(this.props, "optinInfo.state", "") !== ""
    ) {
      this.setSentOptinEvents(false);
      this.handleReactNativeEvents(this.props.optinInfo);
    }
  }

  setOptinError = (error: Error) => {
    this.setState({ error });
  };

  setOptinState = (isOpted: boolean) => {
    this.setState({ isOpted });
  };

  setSentOptinEvents = (sentOptinEvents: boolean) => {
    this.setState({ sentOptinEvents });
  };

  optinUser = (accountNumber: string, id: number): void => {
    promoService
      .postUserPromoOptin(accountNumber, id)
      .then(() => {
        this.setState({ isOpted: true });
        this.handleOptinDataLayerUpdate("Success");
        mediator.base.dispatch({
          type: "TVG_LOGIN:GET_USER_PROMOS"
        });
      })
      .catch((error) => {
        const exception =
          get(error, "response.data.exception", "") !== ""
            ? get(error, "response.data.exception", "")
            : error;

        this.setState({ error: exception });
        this.handleOptinDataLayerUpdate("Failure");
      });

    this.scrollModalToTop();
  };

  handleOptinClick = (): void => {
    const { user, promoId } = this.props;
    const { isLogged, accountNumber } = user;

    if (!isLogged) {
      if (this.tvgConf.product === "iosnative") {
        window.handleNativeMessages("OPEN_LOGIN_MODAL_WEBVIEW", {
          currentUrl: window.location.href,
          promoId
        });
      } else {
        mediator.base.dispatch({
          type: "OPEN_LOGIN",
          payload: {
            callback: (error, success) => {
              if (get(success, "status", "fail") === "success") {
                this.optinUser(
                  get(success, "data.userDetails.accountNumber", ""),
                  promoId
                );
              }
            }
          }
        });
      }
    } else {
      this.optinUser(accountNumber, promoId);
    }
  };

  handleOptinDataLayerUpdate = (tag: string): void => {
    const { promoId } = this.props;

    mediator.base.dispatch({
      type: "PROMOS_RET_CTA_CLICK",
      payload: {
        gaEventLabel: promoId,
        tag
      }
    });
  };

  scrollModalToTop = (): void => {
    if (this.compRef) {
      this.compRef.scrollIntoView();
    }
  };

  handleReactNativeEvents = (optinInfo: {
    promoId: string,
    state: string,
    error?: string
  }) => {
    if (!this.state.sentOptinEvents) {
      this.handleOptinDataLayerUpdate(optinInfo.state);

      if (optinInfo.state !== "Success") {
        this.setState({ error: get(optinInfo, "error", "") });
      }

      this.setSentOptinEvents(true);
    }
  };

  render() {
    const {
      promoType,
      promoCode,
      user,
      content,
      mobile,
      history,
      isLoading,
      isDesktop,
      qaLabel
    } = this.props;
    const { isLogged, returningUser, optedInPromos } = user;

    const showBackBtn =
      get(history, "location.state.showBackBtn", false) ||
      get(history, "location.search", "").includes("showBackBtn");
    const hasContent = find(content.body, ["component", "content_section"]);
    let contentBtnProps;

    if (hasContent && hasIn(hasContent, "bloks")) {
      contentBtnProps = find(hasContent.bloks, ["component", "optin_button"]);
    }

    return (
      // Disclaimer: Refactor into hooks once flow.js version is updated to support types
      <PromoContext.Provider value={{ promoType, promoCode }}>
        <UserContext.Provider
          value={{ isLogged, returningUser, optedInPromos }}
        >
          <OptinContext.Provider value={this.state}>
            <LandingPageContainer
              data-qa-label={qaLabel}
              isLoading={isLoading}
              ref={(compRef) => {
                this.compRef = compRef;
              }}
              hasMargin={mobile && !!contentBtnProps}
            >
              {isLoading ? (
                <ProcessingOverlay qaLabel={`${qaLabel}ProcessingOverlay`} />
              ) : (
                <Fragment>
                  {showBackBtn && !isDesktop && (
                    <BackButton qaLabel={`${qaLabel}BackButton`} />
                  )}
                  {content.body.map((blok) => Components(blok))}
                </Fragment>
              )}
            </LandingPageContainer>
            {mobile && contentBtnProps && !isLoading && (
              <OptinFooterButton
                content={contentBtnProps}
                qaLabel={`${qaLabel}OptinFooterButton`}
              />
            )}
          </OptinContext.Provider>
        </UserContext.Provider>
      </PromoContext.Provider>
    );
  }
}
/* eslint-enable react/no-unused-state */

export default withRouter(Page);
