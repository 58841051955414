import { fanduelColorPalette } from "../common";
import { ColorTokens } from "./types";

const content = {
  strong: fanduelColorPalette.core.navyD3,
  default: fanduelColorPalette.core.greyD4,
  subtle: fanduelColorPalette.core.greyD1,
  subtle2: fanduelColorPalette.core.grey,
  subtle3: fanduelColorPalette.core.greyL2,
  disabled: fanduelColorPalette.core.greyL3,
  onLight: fanduelColorPalette.core.navyD3,
  onDark: fanduelColorPalette.core.white,
  link: fanduelColorPalette.core.blueD1,
  linkActive: fanduelColorPalette.core.blueD3,
  brandPrimary: fanduelColorPalette.brand.primary.default,
  brandSecondary: fanduelColorPalette.brand.secondary.default,
  info: fanduelColorPalette.core.blueD1,
  positive: fanduelColorPalette.core.green,
  negative: fanduelColorPalette.core.redD1,
  warning: fanduelColorPalette.core.orangeD1,
  alert: fanduelColorPalette.core.yellowD1,
  neutral: fanduelColorPalette.core.greyD1,
  mtp: fanduelColorPalette.core.navyL1,
  loadingDefault: fanduelColorPalette.core.blackTint005,
  loadingInverse: fanduelColorPalette.core.whiteTint010
};

const component = {
  // TODO: REVISION TAGS
  tag: {
    offer: {
      background: fanduelColorPalette.core.yellowL4,
      content: fanduelColorPalette.core.navyD3
    },
    offer_subtle: {
      background: fanduelColorPalette.core.yellowL4,
      content: fanduelColorPalette.core.navyD3
    },
    "1stpick": {
      background: fanduelColorPalette.core.greenL4,
      content: fanduelColorPalette.core.greenD3
    },
    "2ndpick": {
      background: fanduelColorPalette.core.blueL4,
      content: fanduelColorPalette.core.blueD3
    },
    "3rdpick": {
      background: fanduelColorPalette.core.orangeL4,
      content: fanduelColorPalette.core.orangeD3
    },
    regular_subtle: {
      background: fanduelColorPalette.core.greyL4,
      content: fanduelColorPalette.core.greyD1
    }
  },
  button: {
    primary: {
      content: {
        base: fanduelColorPalette.core.white,
        hover: fanduelColorPalette.core.white,
        active: fanduelColorPalette.core.white,
        disabled: fanduelColorPalette.core.greyL2
      },
      background: {
        base: fanduelColorPalette.core.green,
        hover: fanduelColorPalette.core.greenD1,
        active: fanduelColorPalette.core.greenD2,
        disabled: fanduelColorPalette.core.greyL4
      }
    },
    secondary: {
      content: {
        base: fanduelColorPalette.core.white,
        hover: fanduelColorPalette.core.white,
        active: fanduelColorPalette.core.white,
        disabled: fanduelColorPalette.core.greyL2
      },
      background: {
        base: fanduelColorPalette.core.blue,
        hover: fanduelColorPalette.core.blueD1,
        active: fanduelColorPalette.core.blueD2,
        disabled: fanduelColorPalette.core.greyL4
      }
    },
    tertiary: {
      content: {
        base: fanduelColorPalette.core.blue,
        hover: fanduelColorPalette.core.blue,
        active: fanduelColorPalette.core.white,
        disabled: fanduelColorPalette.core.greyL2
      },
      background: {
        base: fanduelColorPalette.core.transparentWhite,
        hover: fanduelColorPalette.core.blueL5,
        active: fanduelColorPalette.core.blue,
        disabled: fanduelColorPalette.core.transparentWhite
      },
      border: {
        base: fanduelColorPalette.core.blue,
        hover: fanduelColorPalette.core.blue,
        active: fanduelColorPalette.core.blue,
        disabled: fanduelColorPalette.core.greyL2
      }
    },
    destructive: {
      content: {
        base: fanduelColorPalette.core.white,
        hover: fanduelColorPalette.core.white,
        active: fanduelColorPalette.core.white,
        disabled: fanduelColorPalette.core.greyL2
      },
      background: {
        base: fanduelColorPalette.core.red,
        hover: fanduelColorPalette.core.redD1,
        active: fanduelColorPalette.core.redD2,
        disabled: fanduelColorPalette.core.greyL4
      }
    },
    buttonLink: {
      content: {
        base: fanduelColorPalette.core.blue,
        hover: fanduelColorPalette.core.blueD1,
        active: fanduelColorPalette.core.white,
        disabled: fanduelColorPalette.core.greyL2
      },
      background: {
        base: fanduelColorPalette.core.transparentWhite,
        hover: fanduelColorPalette.core.blueL5,
        active: fanduelColorPalette.core.blueD1,
        disabled: "transparent"
      },
      border: {
        disabled: fanduelColorPalette.core.greyL2
      }
    },
    betting: {
      content: {
        base: fanduelColorPalette.core.white,
        hover: fanduelColorPalette.core.white,
        active: fanduelColorPalette.core.white,
        disabled: fanduelColorPalette.core.greyL2
      },
      background: {
        base: fanduelColorPalette.core.greenL1,
        hover: fanduelColorPalette.core.green,
        active: fanduelColorPalette.core.greenD1,
        disabled: fanduelColorPalette.core.greyL4
      }
    },
    promo: {
      content: {
        base: fanduelColorPalette.core.navyD3,
        hover: fanduelColorPalette.core.navyD3,
        active: fanduelColorPalette.core.navyD3,
        disabled: fanduelColorPalette.core.greyL2
      },
      background: {
        base: fanduelColorPalette.core.yellowL4,
        hover: fanduelColorPalette.core.yellowL3,
        active: fanduelColorPalette.core.yellowL2,
        disabled: fanduelColorPalette.core.greyL4
      }
    },
    secondaryDark: {
      content: {
        base: fanduelColorPalette.core.white,
        hover: fanduelColorPalette.core.white,
        active: fanduelColorPalette.core.white,
        disabled: fanduelColorPalette.core.whiteTint030
      },
      background: {
        base: fanduelColorPalette.core.whiteTint010,
        hover: fanduelColorPalette.core.whiteTint020,
        active: fanduelColorPalette.core.whiteTint030,
        disabled: fanduelColorPalette.core.whiteTint010
      }
    },
    transparent: {
      content: {
        base: fanduelColorPalette.core.white,
        hover: fanduelColorPalette.core.white,
        active: fanduelColorPalette.core.white,
        disabled: fanduelColorPalette.core.navy
      },
      background: {
        base: fanduelColorPalette.core.transparentWhite,
        hover: fanduelColorPalette.core.whiteTint010,
        active: fanduelColorPalette.core.blue,
        disabled: fanduelColorPalette.core.transparentWhite
      },
      border: {
        base: fanduelColorPalette.core.white,
        hover: fanduelColorPalette.core.white,
        active: fanduelColorPalette.core.transparentWhite,
        disabled: fanduelColorPalette.core.navy
      }
    }
  },
  notification: {
    info: {
      background: fanduelColorPalette.core.blueD1,
      backgroundSubtle: fanduelColorPalette.core.blueL5,
      border: fanduelColorPalette.core.blueD1,
      content: fanduelColorPalette.core.navyD3
    },
    positive: {
      background: fanduelColorPalette.core.green,
      backgroundSubtle: fanduelColorPalette.core.greenL5,
      border: fanduelColorPalette.core.green,
      content: fanduelColorPalette.core.navyD3
    },
    negative: {
      background: fanduelColorPalette.core.red,
      backgroundSubtle: fanduelColorPalette.core.redL5,
      border: fanduelColorPalette.core.red,
      content: fanduelColorPalette.core.navyD3
    },
    warning: {
      background: fanduelColorPalette.core.orangeL2,
      backgroundSubtle: fanduelColorPalette.core.orangeL5,
      border: fanduelColorPalette.core.orangeL1,
      content: fanduelColorPalette.core.navyD3
    },
    promo: {
      background: fanduelColorPalette.core.yellowL4,
      backgroundSubtle: fanduelColorPalette.core.yellowL5,
      border: fanduelColorPalette.core.orangeL3,
      content: fanduelColorPalette.core.navyD3
    },
    geocomply: {
      backgroundVerification: fanduelColorPalette.core.purpleD2,
      backgroundSuccess: fanduelColorPalette.core.green
    }
  },
  betslip: {
    background: fanduelColorPalette.core.navyD2,
    backgroundExpanded: fanduelColorPalette.core.navyD4
  },
  activeGroupedFilterCell: {
    content: fanduelColorPalette.core.white,
    background: fanduelColorPalette.core.blueD1,
    border: fanduelColorPalette.core.blueD1
  }
};

const background = {
  base: fanduelColorPalette.core.greyL4,
  surface: fanduelColorPalette.core.white,
  layer: fanduelColorPalette.core.greyL5,
  accent: fanduelColorPalette.core.blackTint005,
  hover: fanduelColorPalette.core.blueL5,
  fadeEnd: "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)",
  fadeBottom:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)",
  primary: fanduelColorPalette.brand.primary.default,
  secondary: fanduelColorPalette.brand.secondary.default,
  promotional: fanduelColorPalette.core.navyD3,
  info: fanduelColorPalette.core.blueD1,
  infoSubtle: fanduelColorPalette.core.blueL5,
  positive: fanduelColorPalette.core.green,
  positiveSubtle: fanduelColorPalette.core.greenL5,
  negative: fanduelColorPalette.core.red,
  negativeSubtle: fanduelColorPalette.core.redL5,
  warning: fanduelColorPalette.core.orangeL1,
  warningSubtle: fanduelColorPalette.core.orangeL5,
  alert: fanduelColorPalette.core.yellowL4,
  alertSubtle: fanduelColorPalette.core.yellowL5,
  neutral: fanduelColorPalette.core.greyL2,
  neutralSubtle: fanduelColorPalette.core.greyL5
};

const lhnHeader = {
  content: {
    strong: fanduelColorPalette.core.white,
    default: fanduelColorPalette.core.greyL3,
    subtle: fanduelColorPalette.core.greyL1,
    brandSecondary: fanduelColorPalette.brand.secondary.tint,
    positive: fanduelColorPalette.core.greenL2,
    negative: fanduelColorPalette.core.redL1
  },
  border: {
    default: fanduelColorPalette.core.whiteTint020,
    inverse: fanduelColorPalette.core.greyL4
  },
  selectedcell: {
    border: fanduelColorPalette.core.blue,
    background: fanduelColorPalette.core.blueD2
  }
};

const border = {
  default: fanduelColorPalette.core.greyL3,
  subtle: fanduelColorPalette.core.greyL4,
  inverse: fanduelColorPalette.core.white,
  brand: fanduelColorPalette.brand.primary.default,
  info: fanduelColorPalette.core.blueD1,
  positive: fanduelColorPalette.core.greenL1,
  negative: fanduelColorPalette.core.red,
  warning: fanduelColorPalette.core.orangeL1,
  alert: fanduelColorPalette.core.yellowL3
};

export const fanduelColorTokens: ColorTokens = {
  component,
  content,
  background,
  lhnHeader,
  border,
  groupedfiltercell: {
    background: {
      default: fanduelColorPalette.core.transparentWhite,
      hover: fanduelColorPalette.core.transparentWhite,
      pressed: fanduelColorPalette.core.transparentWhite,
      active: fanduelColorPalette.core.blueD1
    },
    border: {
      default: fanduelColorPalette.core.transparentWhite,
      hover: fanduelColorPalette.core.greyL3,
      pressed: fanduelColorPalette.core.greyL3,
      active: fanduelColorPalette.core.greyL3
    },
    content: {
      default: fanduelColorPalette.core.greyD4,
      hover: fanduelColorPalette.core.greyD4,
      pressed: fanduelColorPalette.core.greyD4,
      active: fanduelColorPalette.core.white
    }
  },
  notification: {
    info: {
      background: fanduelColorPalette.core.blueD1,
      backgroundSubtle: fanduelColorPalette.core.blueL5,
      border: fanduelColorPalette.core.blueD1
    },
    positive: {
      background: fanduelColorPalette.core.green,
      backgroundSubtle: fanduelColorPalette.core.greenL5,
      border: fanduelColorPalette.core.green
    },
    negative: {
      background: fanduelColorPalette.core.red,
      backgroundSubtle: fanduelColorPalette.core.redL5,
      border: fanduelColorPalette.core.red
    },
    warning: {
      background: fanduelColorPalette.core.orangeL2,
      backgroundSubtle: fanduelColorPalette.core.orangeL5,
      border: fanduelColorPalette.core.orangeL1
    }
  }
};
