/*
  Used to check if a key is being pressed or not on the web.

  Usage:
  const escPress = useWebKeyPress("Escape");
*/

import { useState, useEffect } from "react";
import { Platform } from "react-native";

export function useWebKeyPress(targetKey: string): boolean {
  if (
    // only work in browser
    Platform.OS !== "web"
  ) {
    return false;
  }

  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }: { key: string }): void {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }: { key: string }): void => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  // Add event listeners
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (window) {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);

      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }
  }, []);

  return keyPressed;
}

export default useWebKeyPress;
