// @flow
import type { AllRaceContext } from "@tvg-mar/promos-types/Context";

import type { Story, StandingsData, Standing } from "./types";
import type { PromosActions } from "./actions";

export type State = {
  stories: {
    [string]: Story
  },
  leaderBoardData: {
    contestStandingsDates: {
      beginDate: string,
      endDate: string,
      optInBeginDate: string,
      optInEndDate: string
    },
    contestStandings: StandingsData[],
    userStandings: StandingsData[],
    isLoadingStandings: boolean,
    allRaces: AllRaceContext[],
    allRacesLoading: boolean,
    contestStandingsPages: [[Standing[]]]
  }
};

export const initialState: State = {
  stories: {},
  leaderBoardData: {
    contestStandingsDates: {
      beginDate: "",
      endDate: "",
      optInBeginDate: "",
      optInEndDate: ""
    },
    contestStandings: [],
    contestStandingsPages: [[[]]],
    userStandings: [],
    isLoadingStandings: true,
    allRaces: [],
    allRacesLoading: true
  }
};

export default (state: State = initialState, action: PromosActions) => {
  switch (action.type) {
    case "SET_PROMOS":
      return { ...state, stories: action.payload };
    case "SET_PROMO":
      return {
        ...state,
        stories: {
          ...state.stories,
          [action.payload.full_slug]: action.payload
        }
      };
    case "SET_OVERALL_LEADERBOARD_STANDINGS":
      return {
        ...state,
        leaderBoardData: {
          ...state.leaderBoardData,
          contestStandingsDates: {
            beginDate: action.payload.beginDate,
            endDate: action.payload.endDate,
            optInBeginDate: action.payload.optInBeginDate,
            optInEndDate: action.payload.optInEndDate
          },
          contestStandings: action.payload.leaderBoardDataResponses,
          contestStandingsPages: action.payload.leaderBoardDataResponses.map(
            (round) => [round.topEntries]
          ),
          isLoadingStandings: false
        }
      };
    case "SET_LEADERBOARD_STANDINGS_PAGE":
      return {
        ...state,
        leaderBoardData: {
          ...state.leaderBoardData,
          contestStandingsPages: [
            ...state.leaderBoardData.contestStandingsPages.map(
              (round, index) => {
                if (
                  action.payload.round === index &&
                  // $FlowFixMe
                  round.length <= +action.payload.page
                ) {
                  // $FlowFixMe
                  return [...round, action.payload.standingsData];
                }
                return round;
              }
            )
          ]
        }
      };
    case "SET_USER_LEADERBOARD_STANDINGS":
      return {
        ...state,
        leaderBoardData: {
          ...state.leaderBoardData,
          userStandings: action.payload
        }
      };
    case "SET_LEADERBOARD_ALL_RACES":
      return {
        ...state,
        leaderBoardData: {
          ...state.leaderBoardData,
          allRaces: action.payload,
          allRacesLoading: false
        }
      };
    default: {
      return state;
    }
  }
};
