// @flow
import React, { PureComponent } from "react";
import Parser from "html-react-parser";
import { v4 as generateUUID } from "uuid";
import Title from "@tvg/atomic-ui/_atom/Title";
import Text from "@tvg/atomic-ui/_atom/Text";
import { blackGhostHover } from "@tvg/atomic-ui/_static/Colors";
import type { Seo } from "@tvg-mar/promos-types/Component";

import {
  RenderRichTextInlineComp,
  RenderRichTextParagraph
} from "../richTextEditorResolver";
import PromosSeoContainer from "./styled-components";
import ExternalLink from "../../_atoms/ExternalLink";

type Props = {
  content: Seo,
  qaLabel: string
};

export default class PromosSeoContent extends PureComponent<Props> {
  static defaultProps = {
    content: {
      title: "",
      description: ""
    },
    qaLabel: "promosSeoContent"
  };

  render() {
    const { content, qaLabel } = this.props;
    const { description } = content;
    let simpleDescription = null;

    const listOfContents = [];

    if (typeof description !== "string") {
      description.content.forEach((blok) => listOfContents.push(blok));
    } else {
      simpleDescription = (
        <Text
          tag="div"
          color={blackGhostHover.value}
          fontSize={14}
          qaLabel={`${qaLabel}SimpleDescription`}
        >
          {Parser(description)}
        </Text>
      );
    }

    return (
      <PromosSeoContainer data-qa-label={qaLabel}>
        <Title
          tag="h3"
          uppercase={false}
          color={blackGhostHover.value}
          fontSize={18}
          qaLabel={`${qaLabel}Title`}
        >
          {content.title}
        </Title>
        {simpleDescription}
        {listOfContents.map((blok) => {
          if (blok.type === "paragraph") {
            const actualText = RenderRichTextParagraph(blok);
            const uniqueId = generateUUID();
            return (
              <Text
                tag="div"
                key={uniqueId}
                color={blackGhostHover.value}
                fontSize={14}
                qaLabel={`${uniqueId}-${qaLabel}Description`}
              >
                {Parser(actualText, {
                  replace: (domNode) => {
                    if (domNode.name === "a") {
                      if (domNode.attribs.linktype !== "story") {
                        return (
                          <ExternalLink
                            url={domNode.attribs.href}
                            text={domNode.children[0].data}
                            qaLabel={`${qaLabel}ExternalLink`}
                          />
                        );
                      }
                    }
                    return domNode;
                  }
                })}
              </Text>
            );
          }
          return RenderRichTextInlineComp(blok);
        })}
      </PromosSeoContainer>
    );
  }
}
