import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  isValidElement,
  forwardRef,
  useRef
} from "react";
import {
  View,
  Animated,
  LayoutRectangle,
  Platform,
  NativeSyntheticEvent,
  NativeScrollEvent,
  ScrollView,
  KeyboardAvoidingView,
  StyleSheet,
  LayoutChangeEvent
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useQaLabel } from "../../hooks/useQaLabel";
import { useTvgConfContext } from "../../utils/tvgConfProvider";
import {
  ModalProps,
  ModalCloseType,
  ModalType,
  ModalCompoundComponent
} from "./types";
import { ModalHeader, PageHeader } from "../headers";
import {
  StyledModal,
  OverlayContainer,
  ContentContainerFullscreen,
  ContentContainerLightbox,
  ContentInnerLightbox,
  ContentContainerFlexible,
  ContentInnerFlexible,
  ContentContainerFullWidthPage,
  ChildrenContainer,
  TouchableContainer,
  TouchableOverlayContainer,
  StickyAreaContainer,
  LIGHTBOX_MARGIN_SIDE,
  INSET_VERTICAL,
  INSET_BOTTOM,
  TopStickyAreaContainer,
  ContentContainerCustomHeight
} from "./styled-components";
import { getAccessibilityRole } from "../../utils/accessibility";
import { useWebKeyPress } from "../../hooks";
import { useModalAnimations } from "./hooks/useModalAnimations";
import { useScreenSize } from "./hooks/useScreenSize";
import { ModalPage, ModalPageContainer } from "./components";
import {
  useModalPagesReducer,
  ModalPagesContext
} from "./context/modalPagesContext";
import { Fading } from "../scrollViewFading/fading";

const AnimatedOverlayContainer =
  Animated.createAnimatedComponent(OverlayContainer);

const AnimatedContentContainerFullscreen = Animated.createAnimatedComponent(
  ContentContainerFullscreen
);

const AnimatedContentContainerCustomHeight = Animated.createAnimatedComponent(
  ContentContainerCustomHeight
);

const AnimatedContentContainerLightbox = Animated.createAnimatedComponent(
  ContentContainerLightbox
);

const AnimatedContentContainerFlexible = Animated.createAnimatedComponent(
  ContentContainerFlexible
);

const AnimatedContentContainerFullWidth = Animated.createAnimatedComponent(
  ContentContainerFullWidthPage
);

const isNative = Platform.OS !== "web";

const getPageHeight = ({
  type,
  height,
  headerHeight = 0,
  childrenHeight = 0,
  modalHeight = 0
}: {
  type: ModalType;
  height: number;
  headerHeight?: number;
  childrenHeight?: number;
  modalHeight?: number;
}) => {
  switch (type) {
    case "fullscreen":
      return height - INSET_VERTICAL - headerHeight - LIGHTBOX_MARGIN_SIDE;
    case "full-width-page":
      return height - INSET_VERTICAL - headerHeight;
    case "modal-custom-height":
      return modalHeight;
    default:
      return childrenHeight;
  }
};

export const modalTypesWithFade = ["full-width-page", "flexible", "fullscreen"];

const styles = StyleSheet.create({
  keyboardView: {
    flex: 1
  }
});

export const Modal = forwardRef<View, ModalProps>(
  (
    {
      type = "lightbox",
      children,
      isOpen: isOpenFromProps = true,
      onOpen,
      onClose,
      onBack,
      onOverlayClick,
      onAfterClose,
      qaLabel = "modal-label",
      topStickyArea,
      stickyArea,
      alwaysBounceVertical = true,
      shouldCloseOnEsc = true,
      maxWidth,
      isDarkMode = false,
      titlePosition = "center",
      isTrackRulesModal = false,
      maxHeight,
      onScroll,
      ...modalHeaderProps
    },
    ref
  ) => {
    const [isModalOpen, setModalIsOpen] = useState(isOpenFromProps);
    const [closeType, setCloseType] = useState<ModalCloseType>("none");

    // handle open and close with animations per type
    const {
      isAnimating,
      isClosing,
      setIsClosing,
      contentOpacityAnimation,
      overlayOpacityAnimation,
      lightboxContentAnimation,
      lightboxScaleAnimation,
      flexibleContentAnimation,
      fullscreenContentAnimation,
      fullWidthPageContentAnimation
    } = useModalAnimations({
      isModalOpen,
      setModalIsOpen,
      onClose,
      closeType,
      setCloseType,
      onBack,
      onOverlayClick,
      type,
      onAfterClose
    });

    const { height, width } = useScreenSize();
    const [childrenLayout, setChildrenLayout] = useState<
      Partial<LayoutRectangle>
    >({});
    const [headerLayout, setHeaderLayout] = useState<Partial<LayoutRectangle>>(
      {}
    );
    const [stickyAreaLayout, setStickyAreaLayout] = useState<
      Partial<LayoutRectangle>
    >({});
    const [customModalLayout, setCustomModalLayout] = useState<
      Partial<LayoutRectangle>
    >({});
    const [topStickyAreaLayout, setTopStickyAreaLayout] = useState<
      Partial<LayoutRectangle>
    >({});
    const [isScrolling, setIsScrolling] = useState(false);

    const { device } = useTvgConfContext();
    const isMobile = device === "mobile";

    const isEscPressed = useWebKeyPress("Escape");
    const viewTestProps = useQaLabel(qaLabel);
    const modalViewTestProps = useQaLabel(`${qaLabel}-safe-area`);
    const overlayTestProps = useQaLabel("overlay");
    const stickyAreaTestProps = useQaLabel("sticky-area");
    const topStickyAreaTestProps = useQaLabel("sticky-area");
    const [state, dispatch] = useModalPagesReducer({
      isFlexible: type === "flexible" || type === "lightbox"
    });
    const scrollRef = useRef<ScrollView | null>(null);
    const hasPages = useMemo(
      () =>
        isValidElement(children) &&
        (children as JSX.Element)?.type.name === "ModalPageContainer",
      [children, type]
    );

    // set initial render before animation
    useLayoutEffect(
      () => () => {
        setModalIsOpen(false);
      },
      []
    );

    // close on escape press
    useEffect(() => {
      if (shouldCloseOnEsc && isModalOpen && !isClosing && isEscPressed) {
        setIsClosing(true);
      }
    }, [isEscPressed]);

    // Prevents background from scrolling while modal is opened
    useEffect(() => {
      if (!isNative) {
        document.body.style.overflow = isModalOpen ? "hidden" : "initial";
      }
      return () => {
        if (!isNative) {
          document.body.style.overflow = "initial";
        }
      };
    }, [isModalOpen]);

    // handle initial open/close animation from isOpen prop
    useEffect(() => {
      if (isOpenFromProps) {
        // open up modal with animation
        setModalIsOpen(true);
        // if open is false from props but the state is open, begin closing animation
      } else if (isModalOpen) {
        setIsClosing(true);
      }

      if (isOpenFromProps && typeof onOpen === "function") {
        onOpen();
      }
    }, [isOpenFromProps]);

    // set is scrolling for header shadow
    const handleOnScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      const {
        contentOffset: { y }
      } = e.nativeEvent;
      setIsScrolling(y > 0);
      if (typeof onScroll === "function") {
        onScroll({
          ref: scrollRef.current,
          event: e
        });
      }
    };

    // scroll to top on page change
    useEffect(() => {
      if (hasPages && state.nextPage === state.activePage) {
        scrollRef.current?.scrollTo({ y: 0, animated: true });
      }
    }, [hasPages, state.nextPage, state.activePage]);

    // height of header and children
    const childrenHeight =
      typeof childrenLayout?.height === "number"
        ? childrenLayout.height + 1
        : undefined;

    const stickyAreaLayoutHeight =
      React.isValidElement(stickyArea) &&
      typeof stickyAreaLayout?.height === "number"
        ? stickyAreaLayout.height
        : 0;
    const topStickyAreaLayoutHeight =
      React.isValidElement(topStickyArea) &&
      typeof topStickyAreaLayout?.height === "number"
        ? topStickyAreaLayout.height
        : 0;

    const childrenWithStickyHeight = childrenHeight
      ? stickyAreaLayoutHeight + topStickyAreaLayoutHeight + childrenHeight
      : childrenHeight;

    const contentHeight =
      typeof headerLayout?.height === "number" &&
      typeof childrenWithStickyHeight === "number"
        ? headerLayout.height + childrenWithStickyHeight
        : undefined;

    const pageHeight = useMemo(
      () =>
        getPageHeight({
          type,
          height,
          headerHeight: headerLayout?.height,
          childrenHeight: childrenWithStickyHeight,
          modalHeight: customModalLayout?.height
        }),
      [childrenWithStickyHeight, height, headerLayout, customModalLayout]
    );

    const renderOverlay = () => (
      <TouchableOverlayContainer
        onPress={() => {
          if (!isAnimating) {
            setIsClosing(true);
            setCloseType("onOverlayClick");
            onClose?.();
          }
        }}
      >
        <TouchableContainer
          style={{
            height,
            width
          }}
          {...overlayTestProps}
        />
      </TouchableOverlayContainer>
    );

    const showBack =
      typeof onBack === "function" ||
      (state.activePage && state.activePage > 1);

    const renderContent = () => (
      <>
        <View
          onLayout={(event) => setHeaderLayout(event?.nativeEvent?.layout)}
          ref={ref}
        >
          {
            // render header depending on type
            type === "full-width-page" ? (
              <PageHeader
                {...modalHeaderProps}
                {...(showBack
                  ? {
                      onBack: () => {
                        setCloseType("onBack");
                        setIsClosing(true);
                      }
                    }
                  : {})}
                hasShadow={isScrolling}
                qaLabel="pageHeader"
                titlePosition={titlePosition}
                isTrackRulesModal={isTrackRulesModal}
              />
            ) : (
              <ModalHeader
                {...modalHeaderProps}
                onClose={() => {
                  setCloseType("onClose");
                  setIsClosing(true);
                }}
                {...(showBack
                  ? {
                      onBack: () => {
                        setCloseType("onBack");
                        setIsClosing(false);
                        if (onBack) onBack();
                      }
                    }
                  : {})}
                hasRoundedCorners
                hasShadow={isScrolling}
                isDarkMode={isDarkMode}
                qaLabel="modalHeader"
                titlePosition={titlePosition}
                isTrackRulesModal={isTrackRulesModal}
              />
            )
          }
        </View>
        {React.isValidElement(topStickyArea) && (
          <TopStickyAreaContainer
            isScrolling={isScrolling}
            type={type}
            onLayout={(event) =>
              setTopStickyAreaLayout(event?.nativeEvent?.layout)
            }
            {...topStickyAreaTestProps}
          >
            {topStickyArea}
          </TopStickyAreaContainer>
        )}
        <ScrollView
          onScroll={handleOnScroll}
          scrollEventThrottle={500}
          alwaysBounceVertical={alwaysBounceVertical}
          ref={scrollRef}
        >
          <ChildrenContainer
            hasPages={hasPages}
            pageHeight={pageHeight}
            isMobile={isMobile}
            type={type}
            isDarkMode={isDarkMode}
            onLayout={(event) => setChildrenLayout(event?.nativeEvent?.layout)}
            {...modalHeaderProps}
          >
            <ModalPagesContext.Provider value={[state, dispatch]}>
              {children}
            </ModalPagesContext.Provider>
          </ChildrenContainer>
        </ScrollView>
        {modalTypesWithFade.includes(type) && isMobile && (
          <Fading
            position="bottom"
            height="10px"
            width="100%"
            viewBox="0 0 10 45"
            preserveAspectRatio="none"
            isVisible
            gradientPosition={{
              x1: 24,
              y1: 44,
              x2: 24,
              y2: 0
            }}
            qaLabel="fading-effect"
          />
        )}
        {React.isValidElement(stickyArea) && (
          <StickyAreaContainer
            isMobile={isMobile}
            type={type}
            onLayout={(event) =>
              setStickyAreaLayout(event?.nativeEvent?.layout)
            }
            {...stickyAreaTestProps}
          >
            {stickyArea}
          </StickyAreaContainer>
        )}
      </>
    );

    // render container dependending  on modal type
    const renderContainer = () => {
      switch (type) {
        case "fullscreen": {
          return (
            <AnimatedContentContainerFullscreen
              style={{
                height: height - LIGHTBOX_MARGIN_SIDE,
                opacity: contentOpacityAnimation,
                transform: [
                  {
                    translateY: fullscreenContentAnimation.interpolate({
                      inputRange: [0, 1],
                      outputRange: ["100%", "0%"]
                    })
                  }
                ]
              }}
            >
              {renderOverlay()}
              <KeyboardAvoidingView
                behavior="height"
                keyboardVerticalOffset={INSET_VERTICAL}
                style={styles.keyboardView}
                {...viewTestProps}
              >
                {renderContent()}
              </KeyboardAvoidingView>
            </AnimatedContentContainerFullscreen>
          );
        }
        case "modal-custom-height": {
          return (
            <AnimatedContentContainerCustomHeight
              style={{
                height: height - LIGHTBOX_MARGIN_SIDE,
                transform: [
                  {
                    translateY: fullscreenContentAnimation.interpolate({
                      inputRange: [0, 1],
                      outputRange: ["100%", "0%"]
                    })
                  }
                ]
              }}
              maxHeight={maxHeight}
              onLayout={(event: LayoutChangeEvent) =>
                setCustomModalLayout(event?.nativeEvent?.layout)
              }
            >
              {renderOverlay()}
              <KeyboardAvoidingView
                behavior="height"
                keyboardVerticalOffset={INSET_VERTICAL}
                style={styles.keyboardView}
                {...viewTestProps}
              >
                {renderContent()}
              </KeyboardAvoidingView>
            </AnimatedContentContainerCustomHeight>
          );
        }
        case "lightbox": {
          return (
            <AnimatedContentContainerLightbox
              width={width}
              height={height}
              contentHeight={contentHeight || 0}
              isNative={isNative}
              style={{
                opacity: contentOpacityAnimation,
                transform: [
                  {
                    translateY: lightboxContentAnimation.interpolate({
                      inputRange: [0, 1],
                      outputRange: [50, 0]
                    })
                  },
                  {
                    scale: lightboxScaleAnimation.interpolate({
                      inputRange: [0.5, 1],
                      outputRange: [0.5, 1]
                    })
                  }
                ]
              }}
            >
              {renderOverlay()}
              <ContentInnerLightbox
                height={height}
                contentHeight={contentHeight}
                isNative={isNative}
                isMobile={isMobile}
                maxWidth={maxWidth}
              >
                <KeyboardAvoidingView
                  behavior="height"
                  keyboardVerticalOffset={INSET_VERTICAL}
                  style={styles.keyboardView}
                  {...viewTestProps}
                >
                  {renderContent()}
                </KeyboardAvoidingView>
              </ContentInnerLightbox>
            </AnimatedContentContainerLightbox>
          );
        }
        case "flexible": {
          return (
            <KeyboardAvoidingView
              behavior="height"
              keyboardVerticalOffset={-INSET_BOTTOM}
              style={styles.keyboardView}
            >
              <AnimatedContentContainerFlexible
                contentHeight={contentHeight || 0}
                height={height}
                style={{
                  opacity: contentOpacityAnimation,
                  transform: [
                    {
                      translateY: flexibleContentAnimation.interpolate({
                        inputRange: [0, 1],
                        outputRange: ["100%", "0%"]
                      })
                    }
                  ]
                }}
              >
                {renderOverlay()}
                <ContentInnerFlexible {...viewTestProps}>
                  {renderContent()}
                </ContentInnerFlexible>
              </AnimatedContentContainerFlexible>
            </KeyboardAvoidingView>
          );
        }
        case "full-width-page": {
          return (
            <KeyboardAvoidingView
              behavior="height"
              keyboardVerticalOffset={-INSET_BOTTOM}
              style={styles.keyboardView}
            >
              <AnimatedContentContainerFullWidth
                style={{
                  opacity: contentOpacityAnimation,
                  transform: [
                    {
                      translateX: fullWidthPageContentAnimation.interpolate({
                        inputRange: [0, 1],
                        outputRange: ["100%", "0%"]
                      })
                    }
                  ]
                }}
                {...viewTestProps}
              >
                {renderContent()}
              </AnimatedContentContainerFullWidth>
            </KeyboardAvoidingView>
          );
        }
        default:
      }

      return null;
    };

    return (
      <StyledModal
        visible={isModalOpen}
        onDismiss={() => {
          if (isModalOpen && !isClosing) {
            setIsClosing(true);
          }
        }}
        presentationStyle="overFullScreen"
        animationType="none"
        transparent
        // accessibility props
        accessibilityRole={getAccessibilityRole("dialog")}
        {...(!isNative
          ? {
              "aria-modal": true
            }
          : {})}
        {...viewTestProps}
      >
        <AnimatedOverlayContainer
          type={type}
          style={
            type !== "modal-custom-height"
              ? {
                  opacity: overlayOpacityAnimation
                }
              : undefined
          }
        />
        <SafeAreaView>
          <View
            style={{
              height,
              width
            }}
            {...modalViewTestProps}
          >
            {renderOverlay()}
            {renderContainer()}
          </View>
        </SafeAreaView>
      </StyledModal>
    );
  }
) as ModalCompoundComponent<typeof ModalPageContainer, typeof ModalPage>;

Modal.PageGroup = ModalPageContainer;
Modal.Page = ModalPage;

export default Modal;
export { ModalProps, ModalType };
