// @flow
import styled from "styled-components";

const ShareLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 330px;
  margin-top: 24px;

  @media only screen and (max-width: 767px) {
    justify-content: space-evenly;
    margin-bottom: 24px;
    width: 100%;
  }
`;

export default ShareLinksContainer;
