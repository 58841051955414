const { STORYBLOK_TOKENS } = require("../utils/fdr/storyblok");
const { APPTENTIVE_TOKENS } = require("../utils/fdr/apptentive");
const { AMPLITUDE_API_KEYS } = require("../utils/urp/amplitude");
const {
  AMPLITUDE_API_KEYS: AMPLITUDE_API_KEYS_FDR,
  AMPLITUDE_API_KEYS_XSELL: AMPLITUDE_API_KEYS_FDR_XSELL
} = require("../utils/fdr/amplitude");
const {
  AMPLITUDE_API_KEYS: AMPLITUDE_API_KEYS_MEP
} = require("../utils/mep/amplitude");
const {
  AMPLITUDE_API_KEYS: AMPLITUDE_API_KEYS_TVG4
} = require("../utils/tvg4/amplitude");

const defaultOption = {
  domain: {
    desktop: "www.tvg.com",
    mobile: "touch.tvg.com",
    talentPicks: "www.tvg.com/talent-picks/"
  },
  externalDomain: {
    support: "https://support.tvg.com",
    contactSupport:
      "https://support.tvg.com/tvg/s/articles/360005946853-Contact-Support",
    NCPGamblingChat: "http://www.ncpgambling.org/chat"
  },
  externalServices: {},
  service_host: "service.tvg.com",
  service: {
    graph: "/graph/v2/query",
    graphWS: "/graph/v2/subscriptions",
    wtx: "/wtx/v1",
    pay: "/pay/v1",
    capi: "/capi/v1",
    log: "/uel/v1/log-event",
    usa: "/usa/v2",
    uam: "/uam/v1",
    prf: "/prf/v1",
    geo: "/geo/v1",
    uwt: "/uwt/v1",
    wro: "/wro/v1",
    uas: "/uas/v1",
    slp: "/slp/v2",
    ufc: "/ufc/v1",
    rcn: "/rcn/v1",
    tpm: "/tpm/v1",
    fcp: "/fcp/v1/query",
    rwd: "/rwd/v1",
    pes: "/pes/v1",
    pro: "/pro/v1",
    rda: "/rda/v1/query",
    fdr: "/fdr/v2",
    wrc: "/wrc/v1",
    cus: "/cus/v1",
    pxp: "/pxp/v1/proxy",
    gas: "/gas/v1/query",
    gasUsers: "/gas/v1/users",
    gasWS: "/gas/v1/subscriptions",
    behg: "/behg/v1/query", // behg -> bets history graph
    behgWS: "/behg/v1/subscriptions", // behg -> bets history graph
    fem: "/fem/v1",
    crf: "/crf/v1",
    ach: "/ach/v1",
    ccp: "/ccp/v1",
    uc: "/uc/v1",
    mpak: "/mpak/v1",
    chk: "/chk/v1",
    pnm: "/pnm/v1",
    mzm: "/mzm/v1",
    fed: "/gatewayfederation/v1/query",
    fedWS: "/gatewayfederation/v1/subscription",
    seo: "/seo/v1"
  },
  serviceName: {
    graph: "service-javagraph",
    graphWS: "service-javagraph-subscriptions",
    wtx: "service-wtx",
    pay: "service-pay",
    capi: "service-capi",
    log: "service-uel",
    usa: "service-usa",
    uam: "service-uam",
    prf: "service-prf",
    geo: "service-geo",
    uwt: "service-uwt",
    wro: "service-wro",
    uas: "service-uas",
    slp: "service-slp",
    ufc: "service-ufc",
    rcn: "service-rcn",
    tpm: "service-tpm",
    fcp: "service-fcp",
    rwd: "service-rwd",
    pes: "service-pes",
    pro: "service-pro",
    rda: "service-rda",
    fdr: "service-fdr",
    wrc: "service-wrc",
    cus: "service-cus",
    pxp: "service-pxp",
    gas: "service-gas",
    gasUsers: "service-gas",
    gasWS: "service-gas-subscriptions",
    behg: "service-behg",
    behgWS: "service-behg",
    fem: "service-fem",
    crf: "service-crf",
    ach: "service-ach",
    ccp: "service-ccp",
    uc: "service-uc",
    mpak: "service-mpak",
    chk: "service-chk",
    pnm: "service-pnm",
    mzm: "service-mzm",
    seo: "service-seo"
  },
  google: {
    tvg4: {
      gaId: "UA-8156856-2",
      gtmId: "KTDSBG",
      gtmEnv: "482",
      gtmAuth: "EM7EyobnJbhcGWCe5IOyyw",
      siteVersion: "desktop",
      productVersion: "TVG4"
    },
    tvg5: {
      gaId: "UA-8156856-2",
      gtmId: "KTDSBG",
      gtmEnv: "482",
      gtmAuth: "EM7EyobnJbhcGWCe5IOyyw",
      siteVersion: "desktop",
      productVersion: "TVG5"
    },
    touch2: {
      gaId: "UA-8156856-2",
      gtmId: "NX7J29",
      gtmEnv: "482",
      gtmAuth: "EM7EyobnJbhcGWCe5IOyyw",
      siteVersion: "mobile_web",
      productVersion: "MEP"
    },
    touch3: {
      gaId: "UA-8156856-2",
      gtmId: "NX7J29",
      gtmEnv: "482",
      gtmAuth: "EM7EyobnJbhcGWCe5IOyyw",
      siteVersion: "mobile_web",
      productVersion: "MEP"
    },
    androidwrapper: {
      gaId: "UA-8156856-2",
      gtmId: "NX7J29",
      gtmEnv: "482",
      gtmAuth: "EM7EyobnJbhcGWCe5IOyyw",
      siteVersion: "android_native",
      productVersion: "android_gps"
    },
    tvgandroid: {
      gaId: "UA-8156856-2",
      gtmId: "NX7J29",
      gtmEnv: "482",
      gtmAuth: "EM7EyobnJbhcGWCe5IOyyw",
      siteVersion: "android_native",
      productVersion: "android_apk"
    },
    ios: {
      gaId: "UA-8156856-2",
      gtmId: "NX7J29",
      gtmEnv: "482",
      gtmAuth: "EM7EyobnJbhcGWCe5IOyyw",
      siteVersion: "ios_native"
    },
    ios2: {
      gaId: "UA-8156856-2",
      gtmId: "NX7J29",
      gtmEnv: "482",
      gtmAuth: "EM7EyobnJbhcGWCe5IOyyw",
      siteVersion: "ios_native"
    },
    fdrmobile: {
      gaId: "UA-8156856-2",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "mobile_web",
      productVersion: "MEP"
    },
    fdrios: {
      gaId: "UA-8156856-2",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "ios_native",
      productVersion: "MEP"
    },
    fdrandroid: {
      gaId: "UA-8156856-2",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "android_native",
      productVersion: "android_apk"
    },
    fdrandroidwrapper: {
      gaId: "UA-8156856-34",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "android_native",
      productVersion: "android_apk"
    },
    fdrandroidgps: {
      gaId: "UA-8156856-34",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "android_native",
      productVersion: "android_apk"
    },
    fdriosxsell: {
      gaId: "UA-8156856-2",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "x-sell",
      productVersion: "MEP"
    },
    fdrandroidxsell: {
      gaId: "UA-8156856-2",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "x-sell",
      productVersion: "android_apk"
    },
    iosnative: {
      gaId: "UA-8156856-2",
      gtmId: "NX7J29",
      gtmEnv: "482",
      gtmAuth: "EM7EyobnJbhcGWCe5IOyyw",
      siteVersion: "ios_native",
      productVersion: "ios_native"
    }
  },
  amplitude: {
    tvg5: {
      amplitudeAPI: AMPLITUDE_API_KEYS.QA,
      siteVersion: "desktop",
      sitePlatform: "desktop",
      product: "tvg_urp"
    },
    fdrmobile: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR.QA,
      siteVersion: "mobile_web",
      sitePlatform: "mobile_web",
      product: "fdr_standalone_lobby"
    },
    fdrios: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR.QA,
      siteVersion: "native",
      sitePlatform: "ios",
      product: "fdr_standalone_lobby"
    },
    fdrandroid: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR.QA,
      siteVersion: "native",
      sitePlatform: "android",
      product: "fdr_standalone_lobby",
      androidDistributionMethod: "self_dist"
    },
    fdrandroidwrapper: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR.QA,
      siteVersion: "native",
      sitePlatform: "android",
      product: "fdr_standalone_lobby",
      androidDistributionMethod: "google_playstore"
    },
    fdrandroidgps: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR.QA,
      siteVersion: "native",
      sitePlatform: "android",
      product: "fdr_standalone_lobby",
      androidDistributionMethod: "google_playstore"
    },
    fdriosxsell: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_XSELL.QA,
      siteVersion: "x-sell",
      sitePlatform: "ios",
      product: "fdr_xsell_lobby"
    },
    fdrandroidxsell: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_XSELL.QA,
      siteVersion: "x-sell",
      sitePlatform: "android",
      product: "fdr_xsell_lobby"
    },
    touch3: {
      amplitudeAPI: AMPLITUDE_API_KEYS_MEP.QA,
      siteVersion: "mobile_web",
      sitePlatform: "mobile_web",
      product: "tvg_mep"
    },
    androidwrapper: {
      amplitudeAPI: AMPLITUDE_API_KEYS_MEP.QA,
      siteVersion: "native",
      sitePlatform: "android",
      product: "tvg_mep",
      androidDistributionMethod: "self_dist"
    },
    tvgandroid: {
      amplitudeAPI: AMPLITUDE_API_KEYS_MEP.QA,
      siteVersion: "native",
      sitePlatform: "android",
      product: "tvg_mep",
      androidDistributionMethod: "self_dist"
    },
    tvg4: {
      amplitudeAPI: AMPLITUDE_API_KEYS_TVG4.QA,
      siteVersion: "desktop",
      sitePlatform: "desktop",
      product: "tvg_tvg4"
    }
  },
  clientId: "88a7876dc4c330f0dfa6c5c611818d62",
  brazeId: "a728acfe-5f28-42c0-9c08-174ecb4394d5",
  perimeterxId: "TK74ox9H",
  storyblokToken: STORYBLOK_TOKENS.QA,
  storyblokVersion: "draft",
  placesAPI: "AIzaSyCvQe2IeP1DnZT6v0PLK-n4bjK9RqcEarQ",
  paypalID:
    "AbOXWUz9wwf1L17esie_vpZ99gwz9hxQmobirFtIirrZbE3levMoS_lb7BWll5DnB2qB1m11Mmmge8JV",
  apptentiveID: "61f08786218b3765d7000024",
  apptentiveTvg5: "6204200f48b9d649d700005d",
  sonarQube: {
    serverUrl: "https://sonarqube.gcp-dev.tvg.com",
    token: "8836caf742bb1c13755da2d957ba5d1674adbc43",
    projectKey: "tvg-monorepo"
  },
  dataDogRum: {
    desktopApplicationId: "123b949b-108c-4306-bea0-9227242f5b17",
    desktopClientToken: "pub748e107dd1bd28256dad4b1425dda2e3",
    mobileApplicationId: "03715ae6-b7e2-4b30-ac7b-cf6a5bfdf763",
    mobileClientToken: "pub333a7706c3a954d17d9db1becba22673",
    site: "datadoghq.com"
  },
  siftAccountId: "60f88a09293fb116c8220753",
  siftApiKey: "9cc4454a4bfac8e3",
  siftBeaconKey: "d9c3d7901c",
  appTentiveIos: APPTENTIVE_TOKENS.IOS.PRODUCTION,
  appTentiveAndroid: APPTENTIVE_TOKENS.ANDROID.PRODUCTION,
  appTentiveWeb: APPTENTIVE_TOKENS.WEB.PRODUCTION
};

export default defaultOption;
