// @flow

import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import { get } from "lodash";
import promoService from "@tvg/api/pro";
import { handleOptinDataLayerUpdate } from "@tvg-mar/utils/gtmEventHandlers";

import type { User } from "@tvg-mar/promos-types/Promos";
import type { UnaryFn } from "@tvg-mar/promos-types/MethodTypes";

type OptinInfo = {
  promoId: string,
  state: string,
  error?: string
};

export const optinUser = (
  accountNumber: string,
  id: number,
  setIsUserOpted: UnaryFn<boolean, void>,
  setOptinError: UnaryFn<string, void>
): void => {
  promoService
    .postUserPromoOptin(accountNumber, id)
    .then(() => {
      setIsUserOpted(true);
      handleOptinDataLayerUpdate("Success", id);
    })
    .catch((error) => {
      const exception =
        get(error, "response.data.exception", "") !== ""
          ? get(error, "response.data.exception", "")
          : error;

      setOptinError(exception);
      handleOptinDataLayerUpdate("Failure", id);
    });
};

export const handleUserOptinClick = (
  user: User,
  promoId: number,
  setIsUserOpted: UnaryFn<boolean, void>,
  setOptinError: UnaryFn<string, void>
): void => {
  const { isLogged, accountNumber } = user;

  if (!isLogged && accountNumber === "") {
    if (tvgConf.product === "iosnative") {
      window.handleNativeMessages("OPEN_LOGIN_MODAL_WEBVIEW", {
        currentUrl: window.location.href,
        promoId
      });
    } else {
      mediator.base.dispatch({
        type: "OPEN_LOGIN",
        payload: {
          callback: (error, success) => {
            if (get(success, "status", "fail") === "success") {
              optinUser(
                get(success, "data.userDetails.accountNumber", ""),
                promoId,
                setIsUserOpted,
                setOptinError
              );
            }
          }
        }
      });
    }
  } else {
    optinUser(accountNumber, promoId, setIsUserOpted, setOptinError);
  }
};

export const handleReactNativeEvents = (
  optinInfo: OptinInfo,
  sentOptinEvents: boolean,
  promoId: number,
  setOptinError: UnaryFn<string, void>,
  setSentOptinEvents: UnaryFn<boolean, void>
): void => {
  if (!sentOptinEvents) {
    handleOptinDataLayerUpdate(optinInfo.state, promoId);

    if (optinInfo.state !== "Success") {
      setOptinError(get(optinInfo, "error", ""));
    }

    setSentOptinEvents(true);
  }
};
