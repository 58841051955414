// @flow
import React from "react";
import type { StepContent } from "@tvg-mar/promos-types/Component";

import InfoStep from "../../_molecules/InfoStep";
import ContentTitle from "../../_atoms/ContentTitle";
import { InfoContentWrapper, StepsContainer } from "./styled-components";

type Content = {
  steps: StepContent[],
  header: string,
  isModule: boolean
};

type Props = {
  content: Content,
  qaLabel: string
};

const InfoContent = ({ content, qaLabel }: Props) => (
  <InfoContentWrapper data-qa-label={qaLabel} isModule={content.isModule}>
    <ContentTitle
      content={{
        header: content.header,
        className: "infoStepsTitle",
        fontSize: "16px",
        lineHeight: "22px"
      }}
      qaLabel={`${qaLabel}Title`}
    />
    <StepsContainer
      data-qa-label={`${qaLabel}StepsContainer`}
      isModule={content.isModule}
    >
      {content.steps !== null &&
        content.steps.length > 0 &&
        content.steps.map((step) => (
          <InfoStep key={step._uid} {...step} qaLabel={`${qaLabel}InfoStep`} />
        ))}
    </StepsContainer>
  </InfoContentWrapper>
);

InfoContent.defaultProps = {
  content: {
    steps: [],
    header: "",
    isModule: false
  },
  qaLabel: "infoContent"
};

export default InfoContent;
