// @flow

type ColorItem = {
  name: string,
  value: string
};

export const transparent: ColorItem = {
  name: "Transparent",
  value: "rgba(255, 255, 255, 0)"
};

export const black: ColorItem = {
  name: "Black",
  value: "#000000"
};

export const blackGhost: ColorItem = {
  name: "Blackghost",
  value: "rgba(0, 0, 0, 0.3)"
};

export const blackGhostHover: ColorItem = {
  name: "BlackghostHover",
  value: "#333333"
};

export const blackGhostActive: ColorItem = {
  name: "BlackghostHover",
  value: "rgba(51, 51, 51, 0.5)"
};

export const blackOverlay: ColorItem = {
  name: "BlackOverlay",
  value: "rgba(0, 0, 0, 0.7)"
};

export const blue: ColorItem = {
  name: "Blue",
  value: "#27609b"
};

export const blueBright: ColorItem = {
  name: "Blue Bright",
  value: "#5c9de0"
};

export const blueBrighter: ColorItem = {
  name: "Blue Brighter",
  value: "#3E96EE"
};

export const blueBrightHover: ColorItem = {
  name: "Blue Bright Hover",
  value: "#68b1fb"
};

export const blueBrightActive: ColorItem = {
  name: "Blue Bright Active",
  value: "#558ec8"
};

export const blueDark: ColorItem = {
  name: "Blue Dark",
  value: "#194879"
};

export const blueDarker: ColorItem = {
  name: "Blue Darker",
  value: "#253e58"
};

export const blueCool: ColorItem = {
  name: "Blue Cool",
  value: "#528cc7"
};

export const blueDarkest: ColorItem = {
  name: "Blue Darkest",
  value: "#1c3247"
};

export const blueGhost: ColorItem = {
  name: "Blue Ghost",
  value: "#cce6ff"
};

export const blueGreyish: ColorItem = {
  name: "Blue Greyish",
  value: "#e6e9ef"
};

export const blueLight: ColorItem = {
  name: "Blue Light",
  value: "#93afcd"
};

export const blueLightGreyish: ColorItem = {
  name: "Blue Light Greyish",
  value: "#63778c"
};

export const blueLighter: ColorItem = {
  name: "Blue Lighter",
  value: "#cfdae2"
};

export const blueLighterGhost: ColorItem = {
  name: "Blue Lighter",
  value: "#fafbfc"
};

export const blueLightest: ColorItem = {
  name: "Blue Lightest",
  value: "#dfe7f0"
};

export const blueMidnight: ColorItem = {
  name: "Blue Midnight",
  value: "#0f1f30"
};

export const blueWhitish: ColorItem = {
  name: "Blue Whitish",
  value: "#f6f7fa"
};

export const darkBlueGrey: ColorItem = {
  name: "Dark Blue Grey",
  value: "#0f1f30"
};

export const green: ColorItem = {
  name: "Green",
  value: "#38ab50"
};

export const greenGhost: ColorItem = {
  name: "Green Ghost",
  value: "#bae8c4"
};

export const greenLighter: ColorItem = {
  name: "Green Lighter",
  value: "#cfedd5"
};

export const greenLightest: ColorItem = {
  name: "Green Lightest",
  value: "#eaf8ed"
};

export const greenHover: ColorItem = {
  name: "Green Hover",
  value: "#44c55f"
};

export const greenActive: ColorItem = {
  name: "Green Active",
  value: "#339348"
};

export const grey: ColorItem = {
  name: "Grey",
  value: "#666666"
};

export const greyDark: ColorItem = {
  name: "Grey Dark",
  value: "#333333"
};

export const greyMedium: ColorItem = {
  name: "Grey Medium",
  value: "#999999"
};

export const greyLight: ColorItem = {
  name: "Grey Light",
  value: "#cccccc"
};

export const greyLighter: ColorItem = {
  name: "Grey Lighter",
  value: "#dddddd"
};

export const greyLightest: ColorItem = {
  name: "Grey Lightest",
  value: "#f6f6f6"
};

export const red: ColorItem = {
  name: "Red",
  value: "#de3232"
};

export const redGhost: ColorItem = {
  name: "Red Ghost",
  value: "#fedfdf"
};

export const redHover: ColorItem = {
  name: "Red Hover",
  value: "#f73942"
};

export const redActive: ColorItem = {
  name: "Red Active",
  value: "#c42b23"
};

export const white: ColorItem = {
  name: "White",
  value: "#ffffff"
};

export const whiteActive: ColorItem = {
  name: "White Active",
  value: "#e7eaf0"
};

export const whiteGhost: ColorItem = {
  name: "White Ghost",
  value: "rgba(255, 255, 255, 0.2)"
};

export const whiteGhostless: ColorItem = {
  name: "White Ghostless",
  value: "rgba(255, 255, 255, 0.7)"
};

export const whiteGhostHover: ColorItem = {
  name: "White Ghost Hover",
  value: "rgba(255, 255, 255, 0.4)"
};

export const whiteGhostActive: ColorItem = {
  name: "White Ghost Active",
  value: "rgba(255, 255, 255, 0.1)"
};

export const whiteGhostActiveAlt: ColorItem = {
  name: "White Ghost Active",
  value: "rgba(255, 255, 255, 0.05)"
};

export const greyGhost: ColorItem = {
  name: "Grey Ghost Active",
  value: "rgba(51, 51, 51, 0.75)"
};

export const yellow: ColorItem = {
  name: "Yellow",
  value: "#ffb80c"
};

export const yellowGhost: ColorItem = {
  name: "Yellow Ghost",
  value: "#ffecbf"
};

export const yellowHover: ColorItem = {
  name: "Yellow Hover",
  value: "#ffc743"
};

export const yellowActive: ColorItem = {
  name: "Yellow Active",
  value: "#e7a60e"
};

export const yellowFaded: ColorItem = {
  name: "Yellow Faded",
  value: "#ffe39e"
};
