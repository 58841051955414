// @flow
import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const BodyContentContainer = styled.div`
  background: ${buildColor("blue", "000")};
  border-radius: 4px 4px 0 0;

  @media only screen and (hover: hover) and (pointer: fine) and (min-width: 1024px) {
    display: block;
  }

  @media only screen and (hover: none) and (pointer: coarse) and (min-width: 1024px) {
    width: 800px;
    margin: auto;
  }

  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: 799px) and (min-width: 768px) {
    width: 624px;
    margin: auto;
  }
`;

export const BodyContentWrapper = styled.div`
  background: ${buildColor("blue", "000")};
  max-width: 1024px;
  border-radius: 4px;

  @media only screen and (min-width: 1024px) {
    max-width: ${({ isModule }) => (isModule ? "663px" : null)};

    > div:first-child {
      border-radius: 4px 4px 0 0;
    }
  }

  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }

  div[data-qa-label="promo-optin-error"] {
    margin-bottom: 0;
  }
`;
