import styled from "styled-components/native";
import { View } from "react-native";

export const Selections = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.space["space-1"]};
`;

export const IconContainer = styled(View)`
  align-items: center;
  flex-direction: row;
  height: ${({ theme }) => theme.space["space-6"]};
  margin-right: ${({ theme }) => theme.space["space-1"]};
  width: ${({ theme }) => theme.space["space-6"]};
`;
